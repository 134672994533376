import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ICroppingData} from '../entities/cropping-data';
import {CroppingDataGqlService} from '../infrastructure/cropping-data-gql.service';
import {ImageCroppingRatio} from '../enums/image-cropping-ratio';
import {IPhotoCroppingGql} from '../entities/photo-cropping-gql';

@Injectable({
  providedIn: 'root',
})
export class CroppingFacadeService {
  private readonly croppingDataGQLService = inject(CroppingDataGqlService);
  readonly CROP_QUERY_PARAM_KEY = 'crop';

  getPhotoCroppingData$(photos: number[], ratio: ImageCroppingRatio): Observable<IPhotoCroppingGql[]> {
    return this.croppingDataGQLService.getImageCroppingData$(photos, ratio);
  }

  createCropParameter({x, y, width, height}: ICroppingData): string {
    return `${this.CROP_QUERY_PARAM_KEY}=${[x, y, width, height].join(',')}`;
  }
}
