import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SHARED_AUDIO_PLAYER_SERVICE} from './tokens/audio-player.tokens';
import {AudioPlayerService} from './services/audio-player.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: SHARED_AUDIO_PLAYER_SERVICE,
      useClass: AudioPlayerService,
    },
  ],
})
export class AudioPlayerModule {}
