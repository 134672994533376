import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {IKey, IValue, OptionDefinition, OptionView, SelectedOption} from '../../intefaces/filter-select-item';
import {FilterNames} from '@px/audio-domain';

@Component({
  selector: 'px-audio-filters',
  templateUrl: './audio-filters.component.html',
  styleUrls: ['./audio-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioFiltersComponent {
  @Input() options: OptionDefinition[] = [];
  @Input() selectedOptions: OptionView[] = [];
  @Output() optionSelected$ = new EventEmitter<SelectedOption>();
  @Output() selectGroup$ = new EventEmitter<OptionDefinition>();
  @Output() optionCleared$ = new EventEmitter<SelectedOption>();
  @Output() allOptionsCleared$ = new EventEmitter<void>();

  @ViewChild('audioFilters') audioFilters: ElementRef<HTMLElement> | undefined;

  FilterNames = FilterNames;
  private readonly audioFilterOffsetBottom = 20;
  private readonly defaultAudioFiltersMaxHeight = 550;

  get audioFiltersMaxHeight(): string {
    const offsetTop = this.audioFilters?.nativeElement?.getBoundingClientRect().top ?? 0;

    return offsetTop
      ? `calc(100vh - ${offsetTop}px - ${this.audioFilterOffsetBottom}px)`
      : `${this.defaultAudioFiltersMaxHeight}px`;
  }

  private readonly filterCollapse = new Map<string, boolean>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  toggleOpenItemState($event: MouseEvent, key: string): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    const isOpen = this.filterCollapse.get(key);

    this.filterCollapse.set(key, !isOpen);
    this.cdr.detectChanges();
  }

  isOpen(key: string): boolean {
    return !!this.filterCollapse.get(key);
  }

  resetCollapseStates(): void {
    this.filterCollapse.clear();
  }

  handleOptionValueCheckEvent(checked: boolean, optionKey: string, valueKey?: string): void {
    if (checked) {
      this.selectOption(optionKey, valueKey);
    } else {
      this.clearOption(optionKey, valueKey);
    }
  }

  selectOption(key: string, value?: string): void {
    const selectedOption: SelectedOption = {key, value};
    this.optionSelected$.emit(selectedOption);
  }

  clearAllOptions(): void {
    this.allOptionsCleared$.emit();
  }

  clearOption(key: string, value?: string): void {
    const clearedOption: SelectedOption = {key, value};
    this.optionCleared$.emit(clearedOption);
  }

  isOptionEnabled(optionKey: string): boolean {
    return this.selectedOptions.some(({key}) => key === optionKey);
  }

  isListEnabled(optionDefinition: OptionDefinition): boolean {
    const item = this.selectedOptions.find(item => item.key === optionDefinition.key);

    if (item && Array.isArray(item.value)) {
      const values = item.value.map(i => i.value);
      return !!optionDefinition.values?.every(i => values.includes(i.key));
    }

    return false;
  }

  isOptionValueEnabled(optionDefinition: OptionDefinition, optionValue: IKey): boolean {
    const option = this.selectedOptions.find(({key}) => key === optionDefinition.key);
    const values = (option?.value as IValue[]) ?? [];
    return values.some(({value}) => value === optionValue.key);
  }

  isList(item?: unknown): item is Array<unknown> {
    return Array.isArray(item);
  }

  isEnabledBool(value: string): boolean {
    const optionView = this.selectedOptions.find(({key}) => key === value);
    return (optionView?.value as boolean) ?? false;
  }

  changeListState($event: boolean, option: OptionDefinition): void {
    if ($event) {
      this.selectGroup$.emit(option);
      return;
    }

    this.clearOption(option.key);
    return;
  }

  expansionItemHandler($event: MouseEvent, item: string): void {
    const optionDefinition = this.options?.find(({key}) => key === item);
    const isOpen = this.filterCollapse.get(item);

    if (isOpen || !this.isList(optionDefinition?.values)) {
      return;
    }

    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.filterCollapse.set(item, !isOpen);
    this.cdr.detectChanges();
  }
}
