import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {UI_BUTTON_SIZE} from '@px/ui-components-px-button';
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'px-audio-player-control',
  templateUrl: './audio-player-control.component.html',
  styleUrls: ['./audio-player-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioPlayerControlComponent {
  private readonly DEBOUNCE_TIME_MS = 100;
  private isPlayingInstance = false;

  private playingState$ = new BehaviorSubject(this.isPlayingInstance);

  @Input() set isPlaying(value: boolean) {
    this.playingState$.next(value);
  }

  get isPlaying(): boolean {
    return this.isPlayingInstance;
  }

  @Input() isLoading = false;
  @Input() size?: keyof typeof UI_BUTTON_SIZE;

  @Output() play$ = new EventEmitter();
  @Output() pause$ = new EventEmitter();

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.playingState$.pipe(debounceTime(this.DEBOUNCE_TIME_MS)).subscribe(value => {
      this.isPlayingInstance = value;
      this.cdr.detectChanges();
    });
  }

  play(): void {
    this.play$.emit();
  }

  pause(): void {
    this.pause$.emit();
  }
}
