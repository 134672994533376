import {IFileUploader, IFileUploadProcess, PxFile} from '@px/shared-data-access-file-upload';
import {catchError, mergeMap, Observable, of, share, switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {IS3CredentialsProvider} from '../interfaces/s3-credentials-provider.interface';
import {IFileHostingUploader, IFileHostingUploadProcess} from '../interfaces/file-hosting-uploader.interface';
import {IS3Credentials} from '../interfaces/s3-credentials';
import {FileHostingUploadError} from '../file-hosting-upload-error';
import {HttpErrorResponse} from '@angular/common/http';
import {IS3HostingInfo} from '../interfaces/s3-hosting-info';
import {S3FormsPropertiesFactory} from '../interfaces/s3-form-properties';

/**
 * A class that uploads files to an S3 bucket.
 * T - The type of the S3 credentials.
 * U - The type of the parameters to use when uploading the file.
 */
export class S3FileHostingUploader<T extends IS3Credentials = IS3Credentials, U = null>
  implements IFileHostingUploader<IS3HostingInfo<T>, U, HttpErrorResponse>
{
  private credsCache: T | null = null;
  private policyExpiredRetry$: Observable<T> | null = null;
  private get normalizedHostUrl(): string {
    return this.s3FileHostUrl.endsWith('/') ? this.s3FileHostUrl.slice(0, -1) : this.s3FileHostUrl;
  }

  /**
   *
   * @param fileUploader - How to upload the file.
   * @param seCredentialsProvider - How to get the S3 credentials.
   * @param s3FileHostUrl - The URL of the S3 bucket.
   * @param formPropertiesFactory - How to create the form properties for the S3 upload.
   * @param useCache - use policy cache or not. Default is false
   */
  constructor(
    private readonly fileUploader: IFileUploader,
    private readonly seCredentialsProvider: IS3CredentialsProvider<T, U>,
    private readonly s3FileHostUrl: string,
    private readonly formPropertiesFactory: S3FormsPropertiesFactory<T, U>,
    private readonly useCache = false
  ) {}

  private isPolicyExpiredError(e: FileHostingUploadError): boolean {
    if (e.error instanceof HttpErrorResponse && e.error.status === 403) {
      return typeof e.error.error === 'string' && e.error.error.includes('Policy expired');
    }
    return false;
  }

  private getCredsAndCache(file: PxFile, params: U): Observable<T> {
    return this.seCredentialsProvider.getCredentials(file, params).pipe(tap(creds => (this.credsCache = creds)));
  }

  private getCreds(file: PxFile, params: U): Observable<T> {
    if (this.useCache && !!this.credsCache) {
      return of(this.credsCache);
    }

    if (this.policyExpiredRetry$) {
      return this.policyExpiredRetry$;
    }

    return this.getCredsAndCache(file, params);
  }

  private tryUpload(
    file: PxFile,
    params: U
  ): Observable<IFileHostingUploadProcess<IS3HostingInfo<T>, U, HttpErrorResponse>> {
    return this.getCreds(file, params).pipe(
      map(creds => ({creds, error: null})),
      catchError(error => {
        return of({error, creds: null});
      }),
      mergeMap(credsResult => {
        const creds = credsResult.creds;

        if (creds) {
          this.credsCache = creds;
          const formProperties = this.formPropertiesFactory(file, creds, params);

          return this.fileUploader.upload<null>(file, this.normalizedHostUrl, {formProperties}).pipe(
            map(({uploaded, progress, success, total, error, startTime, endTime}: IFileUploadProcess<null>) => {
              return {
                hostingParams: params,
                hostingInfo: {file, creds, formProperties, params},
                error: error || credsResult.error ? new FileHostingUploadError(error || credsResult.error) : null,
                uploaded,
                progress,
                success,
                total,
                startTime,
                endTime,
              };
            })
          );
        }

        return of({
          hostingParams: params,
          hostingInfo: null,
          error: new FileHostingUploadError(credsResult.error),
          uploaded: 0,
          progress: 1,
          success: false,
          total: 0,
          startTime: null,
          endTime: null,
        });
      })
    );
  }

  /**
   * Uploads a file to the S3 bucket.
   * @param file - The file to upload.
   * @param params - Parameters to use when uploading the file. Will be passed to getCredentials and formPropertiesFactory.
   * @returns An observable that emits the upload progress.
   */
  upload(file: PxFile, params: U): Observable<IFileHostingUploadProcess<IS3HostingInfo<T>, U, HttpErrorResponse>> {
    return this.tryUpload(file, params).pipe(
      switchMap(result => {
        if (result.error && this.isPolicyExpiredError(result.error)) {
          if (!this.policyExpiredRetry$) {
            this.credsCache = null;
            this.policyExpiredRetry$ = this.getCredsAndCache(file, params).pipe(
              tap(() => {
                this.policyExpiredRetry$ = null;
              }),
              share()
            );
          }

          return this.tryUpload(file, params);
        }
        return of(result);
      })
    );
  }
}
