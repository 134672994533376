import {inject, Injectable} from '@angular/core';
import {HotkeysService} from '@ngneat/hotkeys';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, Subject} from 'rxjs';
import {HotKeys} from '../enums/hot-keys.enum';
import {IHotKeyValues} from '../interfaces/hot-key-values.interface';
import {IHotKeysService} from './hotkeys-service.interface';

@UntilDestroy()
@Injectable()
export class HotKeysService implements IHotKeysService {
  private readonly hotKeysService = inject(HotkeysService);
  private readonly hotKeys$ = new Map<keyof typeof HotKeys, Subject<KeyboardEvent>>();

  hotKeysValues: IHotKeyValues[] = [
    {key: HotKeys.play, value: 'space'},
    {key: HotKeys.like, value: 'l'},
    {key: HotKeys.mute, value: 'm'},
    {key: HotKeys.arrowRight, value: 'ArrowRight'},
    {key: HotKeys.arrowLeft, value: 'ArrowLeft'},
    {key: HotKeys.shiftArrowUp, value: 'shift.ArrowUp'},
    {key: HotKeys.shiftArrowRight, value: 'shift.ArrowRight'},
    {key: HotKeys.shiftArrowDown, value: 'shift.ArrowDown'},
    {key: HotKeys.shiftArrowLeft, value: 'shift.ArrowLeft'},
    {key: HotKeys.favorite, value: 'f'},
    {key: HotKeys.hidePhoto, value: 'h'},
    {key: HotKeys.ctrlZ, value: 'meta.z'},
    {key: HotKeys.shiftCtrlZ, value: 'shift.meta.z'},
  ];

  constructor() {
    this.initHotKeys();
  }

  private initHotKeys(): void {
    for (const hotKeyValue of this.hotKeysValues) {
      this.hotKeys$.set(hotKeyValue.key, new Subject<KeyboardEvent>());

      this.hotKeysService
        .addShortcut({keys: hotKeyValue.value})
        .pipe(untilDestroyed(this))
        .subscribe(this.hotKeys$.get(hotKeyValue.key));
    }
  }

  arrowLeft(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.arrowLeft) as Observable<KeyboardEvent>;
  }

  arrowRight(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.arrowRight) as Observable<KeyboardEvent>;
  }

  like(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.like) as Observable<KeyboardEvent>;
  }

  mute(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.mute) as Observable<KeyboardEvent>;
  }

  play(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.play) as Observable<KeyboardEvent>;
  }

  shiftArrowDown(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.shiftArrowDown) as Observable<KeyboardEvent>;
  }

  shiftArrowLeft(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.shiftArrowLeft) as Observable<KeyboardEvent>;
  }

  shiftArrowRight(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.shiftArrowRight) as Observable<KeyboardEvent>;
  }

  shiftArrowUp(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.shiftArrowUp) as Observable<KeyboardEvent>;
  }

  favorite(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.favorite) as Observable<KeyboardEvent>;
  }

  hidePhoto(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.hidePhoto) as Observable<KeyboardEvent>;
  }

  undo(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.ctrlZ) as Observable<KeyboardEvent>;
  }

  redo(): Observable<KeyboardEvent> {
    return this.hotKeys$.get(HotKeys.shiftCtrlZ) as Observable<KeyboardEvent>;
  }
}
