import {inject, Injectable} from '@angular/core';
import {IAudioOptions} from '../entities/interfaces/audio-options';
import {AudioSortOptions} from '../entities/audio-sort-options';
import {AUDIO_FIELD_TO_ORDER_BY} from '../entities/maps/audios-feild-to-order-by';
import {AudioFilterOptions} from '../entities/audio-filter-opitons';
import {AudioFilterParams} from '../entities/audio-filter-params';
import {AudioFilterTypes} from '../entities/enums/audio-filter-types.enum';
import {AUDIO_CATEGORY_TO_ID, ID_TO_AUDIO_CATEGORY} from '../entities/maps/audio-categories-ids';
import {AUDIO_CATEGORIES_NAMES} from '../entities/maps/audio-categories-names';
import {AUDIO_ENERGY_TO_ID, ID_TO_AUDIO_ENERGY} from '../entities/maps/audio-energy-ids';
import {AUDIO_ENERGY_NAMES} from '../entities/maps/audio-energy-names';
import {AudioCategory} from '../entities/enums/audio-categoriy.enum';
import {AudioEnergy} from '../entities/enums/audio-energy.enum';
import {IAudioParams, ISortItem} from '../entities/interfaces/audio-query';
import {IAudiosQueryArguments} from '../entities/gql-schema-types/audios-query-arguments';
import validator from 'validator';
import {IAudiosOrderInput} from '../entities/gql-schema-types/audios-order-input';
import {AudiosOrderBy} from '../entities/enums/audios-order-by';
import {IOffsetPaginationInput} from '../entities/gql-schema-types/offset-pagination-input';
import {isNil} from 'ramda';
import {IAudioAdapter} from '../entities/interfaces/audio-adapter.interface';
import {Audio} from '../entities/audio';
import {IPagination} from '../entities/interfaces/pagination';
import {IAudioGQL} from '../entities/gql-schema-types/audio-gql';
import {IPageInfoGql} from '../entities/gql-schema-types/page-info-gql';
import {IAudio} from '../entities/interfaces/audio';
import {IAudioBeatmatchingTemplate} from '../entities/interfaces/audio-beatmatching-template.interface';
import dayjs from 'dayjs';
import {IAudioTemplateGQL} from '../entities/gql-schema-types/audio-template-gql';
import {plainToClass} from 'class-transformer';
import {AUDIO_GQL_ADAPTER_SETTINGS} from '../entities/tokens/audio-gql-adapter-settings.token';
import {AudioChange} from '../entities/audio-change';
import {IAudioUpdatableGQL} from '../entities/interfaces/audio-updatable-gql';
import {TRANSITION_TYPE_TO_ID} from '../entities/maps/audio-template-ids';
import {TEMPO_TYPE_NAMES} from '../entities/maps/tempo-type-names';
import {ID_TO_TEMPO_TYPE} from '../entities/maps/tempo-type-ids';
import {AUDIO_ENERGY_ORDER} from '../entities/maps/audio-energy-order';
import isNumeric = validator.isNumeric;

@Injectable()
export class AudioGQLAdapterService implements IAudioAdapter {
  private readonly SETTINGS = inject(AUDIO_GQL_ADAPTER_SETTINGS);

  private getCategoriesFilter(): AudioFilterParams {
    return {
      values: [...AUDIO_CATEGORY_TO_ID.entries()].map(([category, id]) => {
        return {
          id,
          label: AUDIO_CATEGORIES_NAMES.get(category) ?? '',
        };
      }),
      type: AudioFilterTypes.LIST,
    };
  }

  private getEnergyFilter(): AudioFilterParams {
    return {
      values: [...AUDIO_ENERGY_TO_ID.entries()]
        .sort(([a], [b]) => {
          const orderA = AUDIO_ENERGY_ORDER.get(a);
          const orderB = AUDIO_ENERGY_ORDER.get(b);

          if (isNil(orderA) || isNil(orderB)) {
            return 0;
          }

          return orderA - orderB;
        })
        .map(([energy, id]) => {
          return {
            id,
            label: AUDIO_ENERGY_NAMES.get(energy) ?? '',
          };
        }),
      type: AudioFilterTypes.LIST,
    };
  }

  private getFilterOptions(): AudioFilterOptions {
    return {
      category: this.getCategoriesFilter(),
      lyrics: {
        type: AudioFilterTypes.BOOLEAN,
      },
      energy: this.getEnergyFilter(),
      is_new: {
        type: AudioFilterTypes.BOOLEAN,
      },
      is_favorite: {
        type: AudioFilterTypes.BOOLEAN,
      },
    };
  }

  private getSortOptions(): AudioSortOptions {
    return [...AUDIO_FIELD_TO_ORDER_BY.keys()] as AudioSortOptions;
  }

  private getAudioCategoryByKey(key: string): AudioCategory | undefined {
    return isNumeric(key) ? ID_TO_AUDIO_CATEGORY.get(+key) : undefined;
  }

  private getAudioEnergyByKey(key: string): AudioEnergy | undefined {
    return isNumeric(key) ? ID_TO_AUDIO_ENERGY.get(+key) : undefined;
  }

  private isSearchingByBM(searchString: string): boolean {
    if (isNumeric(searchString)) {
      const n = +searchString;
      return this.isInsideBMTargetRange(n);
    } else {
      return false;
    }
  }

  private getTextSearch(searchString: string): string | undefined {
    return !this.isSearchingByBM(searchString) ? searchString : undefined;
  }

  private getOrderFromSort(sort: ISortItem): IAudiosOrderInput[] | undefined {
    const orderBy = AUDIO_FIELD_TO_ORDER_BY.get(sort.prop);
    const orderDirection = sort.dir;

    if (orderBy === AudiosOrderBy.TEMPO) {
      return [
        {
          orderBy: AudiosOrderBy.UP_TEMPO,
          orderDirection,
        },
        {
          orderBy: AudiosOrderBy.DOWN_TEMPO,
          orderDirection,
        },
      ];
    }

    return orderBy
      ? [
          {
            orderBy,
            orderDirection,
          },
        ]
      : undefined;
  }

  private getOrder(params: IAudioParams): IAudiosOrderInput[] | undefined {
    if (params.searchString && this.isSearchingByBM(params.searchString) && params.sort) {
      const order = params.sort && this.getOrderFromSort(params.sort);
      const orderAccum: IAudiosOrderInput[] = [
        {
          orderBy: AudiosOrderBy.TEMPO,
          centralValue: +params.searchString,
          orderDirection: params.sort.dir,
        },
      ];

      if (order) {
        orderAccum.push(...order.filter(o => ![AudiosOrderBy.UP_TEMPO, AudiosOrderBy.DOWN_TEMPO].includes(o.orderBy)));
      }

      return orderAccum;
    } else if (params.sort) {
      const order = this.getOrderFromSort(params.sort);
      return order ? order : undefined;
    }

    return undefined;
  }

  private getOffsetPagination(params: IAudioParams): IOffsetPaginationInput | undefined {
    if (!isNil(params.size) && !isNil(params.page)) {
      return {
        offset: params.page > 1 ? params.size * (params.page - 1) : 0,
        first: params.size,
      };
    }
    return undefined;
  }

  private getCategoryName(category: AudioCategory): string {
    const name = AUDIO_CATEGORIES_NAMES.get(category);
    if (!name) {
      throw new Error(`Cant find name for audio category ${category}`);
    }
    return name;
  }

  private getEnergyName(energy: AudioEnergy): string {
    const name = AUDIO_ENERGY_NAMES.get(energy);
    if (!name) {
      throw new Error(`Cant find name for audio energy ${energy}`);
    }
    return name;
  }

  private geFramesFromTime(time: number): number {
    return Math.round((time * 60) / 1000);
  }

  isInsideBMTargetRange(n: number): boolean {
    return n >= this.SETTINGS.beatmatchingSearchRange.min && n <= this.SETTINGS.beatmatchingSearchRange.max;
  }

  audioGQLToAudio(audioGQL: IAudioGQL): Audio {
    if (isNil(audioGQL.picId)) {
      throw new Error(`Wrong audio gql input for audio ${audioGQL.id}: picId is null`);
    }

    if (isNil(audioGQL.mp3AudioFileUrl)) {
      throw new Error(`Wrong audio gql input for audio ${audioGQL.id}: mp3AudioFileUrl is null`);
    }

    if (isNil(audioGQL.oggAudioFileUrl)) {
      throw new Error(`Wrong audio gql input for audio ${audioGQL.id}: oggAudioFileUrl is null`);
    }

    if (isNil(audioGQL.length)) {
      throw new Error(`Wrong audio gql input for audio ${audioGQL.id}: length is null`);
    }

    const plain: IAudio = {
      is_favorite: audioGQL.isFavorite ?? undefined,
      energy: audioGQL.energy && this.getEnergyName(audioGQL.energy),
      id: audioGQL.picId,
      gqlId: audioGQL.id,
      audio_mp3: audioGQL.mp3AudioFileUrl,
      lyrics: audioGQL.lyrics,
      song_title: audioGQL.songTitle ?? '',
      category: this.getCategoryName(audioGQL.category),
      audio_ogg: audioGQL.oggAudioFileUrl,
      artist: audioGQL.artist ?? '',
      category_id: audioGQL.categoryId,
      energy_id: audioGQL.energyId,
      expired: !!audioGQL.expiresAt && dayjs(audioGQL.expiresAt).isBefore(dayjs()),
      is_new: false,
      expires_at: audioGQL.expiresAt ? dayjs(audioGQL.expiresAt).unix() : null,
      genre: audioGQL.genre,
      instrumental: audioGQL.instrumental,
      length: this.geFramesFromTime(audioGQL.length),
      is_recently_used: null,
      original_file_name: audioGQL.originalAudioFileName ?? '',
      provider: audioGQL.provider,
      round_added: audioGQL.roundAdded,
      up_tempo: audioGQL.upTempo,
      down_tempo: audioGQL.downTempo,
      templates: [],
      waveform_data: audioGQL.waveform,
    };

    return plainToClass(Audio, plain);
  }

  getFilters(): IAudioOptions {
    return {
      filters: this.getFilterOptions(),
      sort_options: this.getSortOptions(),
    };
  }

  audioParamsToQueryArguments(params: IAudioParams): IAudiosQueryArguments {
    return {
      artistOrSongTitleContains: params.searchString ? this.getTextSearch(params.searchString) : undefined,
      categories: params.filter?.category
        ? (params.filter.category.map(c => this.getAudioCategoryByKey(c)).filter(c => !!c) as AudioCategory[])
        : undefined,
      energies: params.filter?.energy
        ? (params.filter.energy.map(e => this.getAudioEnergyByKey(e)).filter(e => !!e) as AudioEnergy[])
        : undefined,
      excludeByIds: params.exclude?.length ? params.exclude.map(e => e.toString()) : undefined,
      isNew: params.filter?.is_new,
      isFavorite: params.filter?.is_favorite,
      hasLyrics: !isNil(params.filter?.lyrics) ? params.filter?.lyrics : undefined,
      order: this.getOrder(params),
      pagination: this.getOffsetPagination(params),
    };
  }

  audiosGQLToAudios(audiosGQL: IAudioGQL[]): Audio[] {
    const audios: Audio[] = [];

    for (const audioGQL of audiosGQL) {
      try {
        audios.push(this.audioGQLToAudio(audioGQL));
      } catch (e) {
        console.error(e);
      }
    }

    return audios;
  }

  pageInfoToPagination(pageInfo: IPageInfoGql): IPagination {
    const previousPages = pageInfo.offset / pageInfo.take;

    if (!Number.isInteger(previousPages)) {
      throw new Error('Wrong pageInfo: "offset" should be divided by "take" without remainder');
    }

    if (pageInfo.totalCount > 0 && pageInfo.offset > pageInfo.totalCount) {
      throw new Error('Wrong pageInfo: pageInfo.offset > pageInfo.totalCount');
    }

    return {
      per_page: pageInfo.take,
      current_page: previousPages + 1,
      total_pages: Math.max(Math.ceil(pageInfo.totalCount / pageInfo.take), 1),
      total_count: pageInfo.totalCount,
    };
  }

  templateGQLToTemplate(templateGQL: IAudioTemplateGQL): IAudioBeatmatchingTemplate {
    if (!templateGQL.picId) {
      throw new Error(`Template ${templateGQL.id} doesn't have picId`);
    }
    const tempoType = ID_TO_TEMPO_TYPE.get(templateGQL.tempoType);
    if (!tempoType) {
      throw new Error(`Wrong tempo type id ${templateGQL.tempoType} for template ${templateGQL.id}`);
    }
    const tempo_type = TEMPO_TYPE_NAMES.get(tempoType);
    if (!tempo_type) {
      throw new Error(`Can't find tempo type ${tempoType} in TEMPO_TYPE_NAMES for template ${templateGQL.id}`);
    }
    const audioBMTemplate: IAudioBeatmatchingTemplate = {
      id: templateGQL.picId,
      gqlId: templateGQL.id,
      is_custom: templateGQL.isCustom,
      name: templateGQL.name,
      matching_images: templateGQL.matchingImages,
      tempo_type,
      tempo_id: templateGQL.tempoType,
      transitions: [],
      version: templateGQL.versionNumber,
      configs_path: null,
    };

    if (templateGQL.transitions) {
      audioBMTemplate.transitions = templateGQL.transitions.map(tgql => {
        const type = TRANSITION_TYPE_TO_ID.get(tgql.type);

        if (isNil(type)) {
          throw new Error(
            `Wrong transition type ${tgql.type} for template ${templateGQL.id}. Isn't exists in TRANSITION_TYPE_TO_ID`
          );
        }

        return {
          duration: tgql.duration,
          type,
          median: tgql.median,
        };
      });
    }

    return audioBMTemplate;
  }

  audioChangesToAudioUpdatable(audiosChanges: AudioChange[], uuids: string[]): IAudioUpdatableGQL {
    if (audiosChanges.length === 0 || uuids.length === 0) {
      throw new Error('Nothing to convert in audioChangesToAudioUpdatable.');
    }
    if (audiosChanges.length > 1 || uuids.length > 1) {
      throw new Error('Can change only one audio at a time.');
    }

    const changes = audiosChanges[0];
    const uuid = uuids[0];

    return {
      id: uuid,
      artist: changes.artist,
      isFavorite: changes.is_favorite,
      songTitle: changes.song_title,
    };
  }
}
