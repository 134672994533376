import {NgClass, NgIf, NgStyle, NgTemplateOutlet} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
import {MdsWrapBalancerComponent} from '@pui/cdk/wrap-balancer';
import {IHintComponent, UI_HINT_POSITION, UI_HINT_TYPE} from '@px/ui/components/shared/hint';
import {hintAnimation} from '../animations/hint-animation';

@Component({
  selector: 'mds-hint',
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, NgTemplateOutlet, MdsWrapBalancerComponent],
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [hintAnimation],
})
export class PuiHintComponent implements IHintComponent {
  protected readonly UI_HINT_TYPE = UI_HINT_TYPE;
  protected readonly UI_HINT_POSITION = UI_HINT_POSITION;

  @ViewChild(TemplateRef) templateRef?: TemplateRef<ElementRef>;

  @Output() close$ = new EventEmitter<void>();

  @Input() style?: {[key: string]: string | number};
  @Input() type: keyof typeof UI_HINT_TYPE = UI_HINT_TYPE.default;
  @Input() position: keyof typeof UI_HINT_POSITION = UI_HINT_POSITION.default;
  @Input({transform: numberAttribute}) width?: number; // change to enum when we unify the width
  @Input({transform: booleanAttribute}) mdsHintWrapBalancer = false;
  @Input() key?: string;

  transformOrigin = '';
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  triggerElementRef?: ElementRef;

  get pointerOffset(): number {
    return this.triggerElementRef?.nativeElement.offsetWidth / 2;
  }
}
