export enum AudioSelectionSource {
  TOP_SONGS_TAB_ROW = 'Top songs tab (row)',
  TOP_SONGS_TAB_PLAYER = 'Top songs tab (player)',
  MY_UPLOADS_TAB_ROW = 'My Uploads (row)',
  MY_UPLOADS_TAB_PLAYER = 'My Uploads (player)',
  MY_UPLOADS_SINGLE_SONG_DROP = 'My Uploads (single song)',
  MY_FAVORITES_TAB_ROW = 'My favorites (row)',
  MY_FAVORITES_TAB_PLAYER = 'My favorites (player)',
  SEARCH_TAB_ROW = 'Search (row)',
  SEARCH_TAB_PLAYER = 'Search (player)',
}
