import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {AudioPlayerService, SHARED_AUDIO_PLAYER_SERVICE} from '@px/shared/audio-player';
import {AudioPlayerControllerService} from '../../services/audio-player-controller/audio-player-controller.service';
import {IHotKeysService, SHARED_HOTKEYS_SERVICE} from '@px/shared/hotkeys';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AudioTableColumns} from '../../enums/audio-table-columns';
import {AudioDropzoneState} from '../audio-dropzone/audio-dropzone-state';
import {AudioTableControllerService} from '../../services/audio-table-controller/audio-table-controller.service';
import {AudiosPageFetcher} from '../../intefaces/audios-page-fetcher';
import {AudioTableColumnsOverrides} from '../../enums/audio-table-columns-overrides';
import {Observable, Subscription} from 'rxjs';
import {IAudioChangeEvent} from '../../intefaces/audio-change-event';
import {AudioChangeType} from '../../enums/audio-change-type';
import {AudioSelectionSource} from '../../enums/audio-selection-source';
import {IAudioSelection} from '../../intefaces/audio-selection';
import {Audio, AUDIO_FACADE, IAudioFacadeService} from '@px/audio-domain';

@UntilDestroy()
@Component({
  selector: 'px-audio-uploads',
  templateUrl: './audio-uploads.component.html',
  styleUrls: ['./audio-uploads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: SHARED_AUDIO_PLAYER_SERVICE, useClass: AudioPlayerService},
    AudioPlayerControllerService,
    AudioTableControllerService,
  ],
})
export class AudioUploadsComponent implements OnInit {
  private audioChangesEventSub?: Subscription;
  readonly AudioSelectionSource = AudioSelectionSource;

  @Input() set isPlayerActive(value: boolean) {
    this.audioPlayerController.isPlayerActive = value;
  }
  @Input() disabledAudiosIds: number[] = [];
  @Input() isDropzoneOpened = false;
  @Input() set audioChangesEvent$(obs$: Observable<IAudioChangeEvent[]> | undefined) {
    this.updateAudioChangedEventsSub(obs$);
  }
  @Input() recentSegmentsCount?: number;
  @Input({transform: booleanAttribute}) isBMTargetsColumnShown?: boolean;

  @Output() selectAudio$ = new EventEmitter<IAudioSelection>();
  @Output() audioChanged$ = new EventEmitter<Audio>();
  @Output() audioDeleted$ = new EventEmitter<Audio>();
  @Output() audioFavoriteChanged$ = new EventEmitter<Audio>();

  readonly tableColumns: AudioTableColumns[] = [
    AudioTableColumns.SONG_TITLE,
    AudioTableColumns.LENGTH,
    AudioTableColumns.PLAYER_CONTROLS,
    AudioTableColumns.WAVE_FORM,
    AudioTableColumns.IS_FAVORITE,
    AudioTableColumns.STATUS,
  ];

  readonly tableColumnsOverrides: AudioTableColumnsOverrides = {
    [AudioTableColumns.SONG_TITLE]: {width: 543},
    [AudioTableColumns.LENGTH]: {width: 298},
  };

  constructor(
    @Inject(AUDIO_FACADE) private readonly audioService: IAudioFacadeService,
    @Inject(SHARED_HOTKEYS_SERVICE) private readonly hotKeysService: IHotKeysService,
    private readonly cdr: ChangeDetectorRef,
    readonly audioPlayerController: AudioPlayerControllerService,
    readonly audioTableController: AudioTableControllerService
  ) {
    this.hotKeysService
      .like()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.audioPlayerController.playingAudio) {
          this.audioTableController.toggleFavorite(this.audioPlayerController.playingAudio);
        }
      });
  }
  private updateAudioChangedEventsSub(obs$: Observable<IAudioChangeEvent[]> | undefined): void {
    if (this.audioChangesEventSub) {
      this.audioChangesEventSub.unsubscribe();
    }
    if (obs$) {
      this.audioChangesEventSub = obs$.pipe(untilDestroyed(this)).subscribe(e => this.updateAudiosFromEvents(e));
    }
  }

  private updateAudiosFromEvents(events: IAudioChangeEvent[]): void {
    const del = events.filter(e => e.type === AudioChangeType.DELETE).map(e => e.audio);
    const change = events
      .filter(e => e.type === AudioChangeType.CHANGE || e.type === AudioChangeType.FAVORITE_CHANGE)
      .map(e => e.audio);
    const add = events.filter(e => e.type === AudioChangeType.ADD).map(e => e.audio);
    this.audioTableController.updateAudiosState({add, change, del}, false);

    const delInPlayer = del.find(d => d.id === this.audioPlayerController.playingAudio?.id);
    if (delInPlayer) {
      this.audioPlayerController.dispose();
    }
    const changeInPlayer = change.find(d => d.id === this.audioPlayerController.playingAudio?.id);
    if (changeInPlayer) {
      this.audioPlayerController.updateIfPlayingAudio(changeInPlayer);
    }
  }

  private fetchAudios: AudiosPageFetcher = sort =>
    this.audioService.find({
      filter: {
        category: ['0'],
      },
      sort: sort,
      recent_segments_count: this.recentSegmentsCount,
      page: 1,
      size: 200,
      queryCategory: 'MY_UPLOADS',
    });

  dropzoneState(state: AudioDropzoneState): void {
    if (state === 'files') {
      this.audioPlayerController.pause();
    }
  }

  onAudiosUpload(audios: Audio[]): void {
    this.audioTableController.updateAudiosState({add: audios});

    if (audios.length === 1) {
      this.selectAudio(audios[0], AudioSelectionSource.MY_UPLOADS_SINGLE_SONG_DROP);
    }
  }

  selectAudio(audio: Audio, source: AudioSelectionSource): void {
    this.selectAudio$.next({audio, source});
  }

  saveFavorite({is_favorite, id}: Audio): void {
    if (is_favorite) {
      this.audioService.markAsFavorite(id).subscribe();
    } else {
      this.audioService.removeFromFavorites(id).subscribe();
    }
  }

  ngOnInit(): void {
    if (this.isBMTargetsColumnShown) {
      this.tableColumns.push(AudioTableColumns.BM_TARGET);

      delete this.tableColumnsOverrides[AudioTableColumns.LENGTH];
      this.tableColumnsOverrides[AudioTableColumns.BM_TARGET] = {width: 223};
    }

    this.audioTableController.registerAudioPageFetcher(this.fetchAudios);
    this.audioTableController.audioDeleted$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.audioService.delete(audio.id).subscribe();
      if (audio.id === this.audioPlayerController.playingAudio?.id) {
        this.audioPlayerController.dispose();
      }
      this.audioDeleted$.emit(audio);
    });
    this.audioTableController.audioChanged$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.audioService.edit([audio]).subscribe();
      this.audioTableController.isExternalSorting = false;
      this.audioPlayerController.updateIfPlayingAudio(audio);
      this.audioChanged$.emit(audio);
    });
    this.audioTableController.favoriteChanged$.subscribe(audio => {
      this.saveFavorite(audio);
      this.audioPlayerController.updateIfPlayingAudio(audio);
      this.audioFavoriteChanged$.emit(audio);
    });
    this.audioTableController.stateChanged$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
      this.audioPlayerController.add(this.audioTableController.audios);
    });

    this.audioPlayerController.stateChanged$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.audioPlayerController.favoriteChanged$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.saveFavorite(audio);
      this.audioTableController.updateAudiosState({change: [audio]});
      this.audioFavoriteChanged$.emit(audio);
    });

    this.audioTableController.fetchAudios();
  }
}
