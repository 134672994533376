import {AudioCategoryId} from '../enums/audio-categoriy.enum';

export const CATEGORIES_RATING: Record<AudioCategoryId, number> = {
  [AudioCategoryId.MY_UPLOADS]: 1,
  [AudioCategoryId.LOVE_SONGS]: 2,
  [AudioCategoryId.HAPPY]: 3,
  [AudioCategoryId.EMOTIONAL]: 4,
  [AudioCategoryId.CALM_MELODIES]: 5,
  [AudioCategoryId.PARTY_TIME]: 6,
  [AudioCategoryId.BEATS]: 7,
  [AudioCategoryId.EPIC_BEAT_MATCHING]: 8,
};
