import {inject, Injectable} from '@angular/core';
import {CroppingFacadeService, ImageCroppingRatio} from '@px/cropping/domain';
import {Photo} from '../models/slideshow.model';
import {UpdatePhotos, UpdateSegment, UpdateSlideshow} from '../store/slideshow/slideshow.actions';
import {AuthSelectors} from '../auth/store/auth.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app/app.state';
import {SlideshowState} from '@px/shared/api';
import {combineLatest, from, mergeMap, Observable, of} from 'rxjs';
import {map, take, withLatestFrom} from 'rxjs/operators';
import {AspectRatio} from '@px/util/aspect-ratio';
import {IPhotoFileItem} from '@px/legacy/feature-photo-uploader';
import {ImageFileService} from '@px/shared-data-access-file-upload';
import {SlideshowSelectors} from '../store/slideshow/slideshow.selectors';
import {PreferencesSelectors} from '../store/preferences/preferences.selectors';
import {SlideshowUtilsService} from './slideshow-utils.service';
import {PSSPlatformEnvironment} from '../platform-environment';
import {PSSPlatformFeatures} from '../core/interfaces/platform-config';
import {CropActionType, PssTrackingService} from '@px/pss/feature-tracking';
import {AutoCroppingPreferences} from '../models/preferences.model';

const RATIO_MAP: Record<AspectRatio, ImageCroppingRatio> = {
  [AspectRatio.ASPECT_9_16]: ImageCroppingRatio.RATIO_9_16,
  [AspectRatio.ASPECT_16_9]: ImageCroppingRatio.RATIO_16_9,
};

@UntilDestroy()
@Injectable()
export class CroppingService {
  private readonly store$ = inject(Store<AppState>);
  private readonly croppingFacade = inject(CroppingFacadeService);
  private readonly imageFileService = inject(ImageFileService);
  private readonly slideshowUtilsService = inject(SlideshowUtilsService);
  private readonly platform = inject(PSSPlatformEnvironment);
  private readonly trackingService = inject(PssTrackingService);

  private updateSlideshowStateToChangedIfNeeded(): void {
    this.store$
      .select(SlideshowSelectors.getActualSlideshowState)
      .pipe(take(1))
      .subscribe(slideshowState => {
        if (slideshowState.common_data.state === SlideshowState.PUBLISHED) {
          this.store$.dispatch(
            new UpdateSlideshow({common_data: {...slideshowState.common_data, state: SlideshowState.CHANGED}})
          );
        }
      });
  }

  isCroppingEnabled(aspectRatio: AspectRatio, autoCroppingPreferences?: AutoCroppingPreferences): boolean {
    if (aspectRatio === AspectRatio.ASPECT_16_9) {
      return this.platform.hasFeature(PSSPlatformFeatures.AUTO_CROPPING_HORIZONTAL);
    }
    return autoCroppingPreferences?.horizontal || autoCroppingPreferences?.vertical;
  }

  getCroppedForPhotoFileItem$(photoFileItem: IPhotoFileItem): Observable<boolean> {
    return combineLatest([
      this.store$.select(PreferencesSelectors.getActualPreferencesState),
      this.store$.select(SlideshowSelectors.getActualSlideshowState),
    ]).pipe(
      take(1),
      mergeMap(([preferences, slideshowState]) => {
        if (!this.isCroppingEnabled(slideshowState.aspect_ratio, preferences.meta_data?.autoCropping)) {
          return of(false);
        }

        const cropToAspect = this.slideshowUtilsService.aspectRatioToNumber(slideshowState.aspect_ratio);

        return from(this.imageFileService.getAspect(photoFileItem.locals.file)).pipe(
          map(fileAspect => {
            return (
              fileAspect !== cropToAspect &&
              (fileAspect === 1
                ? preferences.meta_data?.autoCropping?.horizontal && preferences.meta_data?.autoCropping?.vertical
                : (preferences.meta_data?.autoCropping?.vertical && fileAspect < 1) ||
                  (preferences.meta_data?.autoCropping?.horizontal && fileAspect > 1))
            );
          })
        );
      })
    );
  }

  applyCropToPhotos(photos: Photo[], segmentId: string, ratio: AspectRatio): void {
    const photosChanges = photos.map(p => ({
      id: p.id,
      changes: {
        locals: {
          ...p.locals,
          creatingThumbnail: true,
          isCropping: true,
        },
      },
    }));

    const segmentChanges = {
      id: segmentId,
      changes: {
        selectedPhotos: {},
      },
    };

    this.store$.dispatch(new UpdatePhotos(photosChanges));
    this.store$.dispatch(new UpdateSegment(segmentChanges, false));

    this.trackingService.cropAction(CropActionType.CROP);

    this.croppingFacade
      .getPhotoCroppingData$(
        photos
          .map(p => {
            if (typeof p.id === 'number') {
              return p.id;
            }

            if (typeof p.locals.mirror_id === 'number') {
              return p.locals.mirror_id;
            }
            return null;
          })
          .filter(p => p !== null),
        RATIO_MAP[ratio]
      )
      .pipe(
        withLatestFrom(this.store$.select(AuthSelectors.getImagesUrls), this.store$.select(AuthSelectors.getUser)),
        untilDestroyed(this)
      )
      .subscribe(([croppedPhotos, imagesUrlsState, user]) => {
        const photosChanges = photos.map(p => {
          const {croppingData} = croppedPhotos.find(cp => {
            if (typeof p.id === 'number') {
              return p.id === cp.picId;
            }

            if (typeof p.locals.mirror_id === 'number') {
              return p.locals.mirror_id === cp.picId;
            }

            return false;
          });

          const thumbnail = this.slideshowUtilsService.createThumbnailUrl(
            p,
            imagesUrlsState,
            user.user_id.toString(),
            croppingData
          );

          return {
            id: p.id as number,
            changes: {
              cropped: true,
              cropping_data: {
                [ratio]: croppingData,
              },
              thumbnail,
              locals: {
                ...p.locals,
                creatingThumbnail: false,
                isCropping: false,
              },
            },
          };
        });

        this.store$.dispatch(new UpdatePhotos(photosChanges));

        this.updateSlideshowStateToChangedIfNeeded();
      });
  }

  undoCropToPhotos(photos: Photo[], segmentId: string): void {
    this.trackingService.cropAction(CropActionType.UNDO);

    combineLatest([this.store$.select(AuthSelectors.getImagesUrls), this.store$.select(AuthSelectors.getUser)])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([imagesUrlsState, user]) => {
        const photosChanges = photos.map(p => {
          const thumbnail = this.slideshowUtilsService.createThumbnailUrl(p, imagesUrlsState, user.user_id.toString());

          return {
            id: p.id as number,
            changes: {
              cropped: false,
              cropping_data: {},
              thumbnail,
            },
          };
        });

        const segmentChanges = {
          id: segmentId,
          changes: {
            selectedPhotos: {},
          },
        };

        this.store$.dispatch(new UpdatePhotos(photosChanges));
        this.store$.dispatch(new UpdateSegment(segmentChanges, false));

        this.updateSlideshowStateToChangedIfNeeded();
      });
  }
}
