<div class="audio-favorites">
  <px-audio-browser-view [isPlayerShown]="!!audioPlayerController.playingAudio">
    <px-audio-table
      [activeAudioId]="audioPlayerController.playingAudio?.id"
      [disabledAudiosIds]="disabledAudiosIds"
      [defaultSort]="audioTableController.sort"
      [isLoading]="audioTableController.isLoading"
      [audios]="audioTableController.audios"
      [activeAudioPlayProgress]="audioPlayerController.playingProgress"
      [isActiveAudioLoading]="audioPlayerController.isAudioLoading"
      [isActiveAudioPlaying]="audioPlayerController.isAudioPlaying"
      [hasExternalSorting]="audioTableController.isExternalSorting"
      (playAudio$)="audioPlayerController.playAudio($event)"
      (pause$)="audioPlayerController.pause()"
      (sort$)="audioTableController.changeSort($event)"
      (seekActiveAudio$)="audioPlayerController.seek($event)"
      (toggleFavoriteAudio$)="audioTableController.toggleFavorite($event)"
      (select$)="selectAudio($event, AudioSelectionSource.MY_FAVORITES_TAB_ROW)"
      (audioChanged$)="audioTableController.changeAudio($event)"
      (audioDeleted$)="audioTableController.deleteAudio($event)"
    >
      <div class="audio-favorites__empty">
        <px-audio-empty />
      </div>
    </px-audio-table>

    <px-audio-player
      audio-player
      *ngIf="audioPlayerController.playingAudio"
      (seekAudio$)="audioPlayerController.seek($event)"
      (playAudio$)="audioPlayerController.playAudio(audioPlayerController.playingAudio)"
      (pauseAudio$)="audioPlayerController.pause()"
      (nextAudio$)="audioPlayerController.playNext()"
      (prevAudio$)="audioPlayerController.playPrev()"
      (selectAudio$)="selectAudio(audioPlayerController.playingAudio, AudioSelectionSource.MY_FAVORITES_TAB_PLAYER)"
      (toggleFavoriteAudio$)="audioPlayerController.toggleFavorite()"
      [audio]="audioPlayerController.playingAudio"
      [titleTruncateLength]="35"
      [isPlaying]="audioPlayerController.isAudioPlaying"
      [isLoading]="audioPlayerController.isAudioLoading"
      [progress]="audioPlayerController.playingProgress"
      [playPositionInfo]="audioPlayerController.playPositionInfo"
    />
  </px-audio-browser-view>
</div>
