<div class="top-songs-category" *ngIf="id">
  <div class="top-songs-category__content">
    <px-audio-category-thumbnail
      [imgUrl]="coverUrl"
      [title]="id | audioCategory"
      [subtitle]="categorySubtitle"
      (click)="onThumbnailClick()"
    />
    <div class="top-songs-category__track-list" *ngIf="audios" (scroll)="checkAndEmitScrollEnd($event)">
      <px-audio-list-item
        *ngFor="let audio of audios; let i = index; trackBy: audioKey"
        [indexNumber]="i + 1"
        [isFaded]="isAudioFaded(audio.id)"
        [audio]="audio"
        [isActive]="audio.id === playingAudioId"
        [isPlaying]="audio.id === playingAudioId && !!isAudioPlaying"
        [isLoading]="audio.id === playingAudioId && !!isAudioLoading"
        (play$)="playAudio(audio)"
        (pause$)="pause()"
        (favoriteChange$)="onFavoriteChange(audio)"
        (click)="onAudioItemClick(audio)"
        (select$)="select(audio)"
      />
    </div>
  </div>
</div>
