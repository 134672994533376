export enum ConfirmDialogReturnValues {
  CONFIRMED = -1,
  ROUTE_UPDATE = 'route update',
  CANCEL = 'cancel',
}

export interface IConfirmDialogData {
  event?: unknown;
  content?: string;
  title?: string;
  helpText?: string;
  beforeHide?: () => void;
  hideCancelBtn?: boolean;
  confirmBtn?: string;
  confirmBtnRes?: string;
  confirmA?: string;
  upgradeUrl?: string;
  cancelBtn?: string;
  actionBtnList?: IAdditionalActionBtn[];
  confirmationActionObservable?: boolean;
}

export interface IAdditionalActionBtn {
  text: string;
  res?: string;
}
