import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileDragSelectorDirective} from './file-drag-selector.directive';
import {FeatureFileUploadModule} from '@px/shared-data-access-file-upload';

@NgModule({
  imports: [CommonModule, FeatureFileUploadModule],
  declarations: [FileDragSelectorDirective],
  exports: [FileDragSelectorDirective],
})
export class FileDragSelectorModule {}
