import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluckPipe} from './pipes/pluck.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {ShortDateFormatPipe} from './pipes/short-date-format.pipe';
import {FullDateFormatPipe} from './pipes/full-date-format.pipe';
import {TruncatedPipe} from './pipes/truncated.pipe';
import {TruncateCenterPipe} from './pipes/truncate-center.pipe';
import {SanitizeUrlPipe} from './pipes/sanitize-url.pipe';
import {DigitsOnlyDirective} from './directives/digits-only.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PluckPipe,
    TruncatePipe,
    TruncatedPipe,
    ShortDateFormatPipe,
    FullDateFormatPipe,
    TruncateCenterPipe,
    SanitizeUrlPipe,
    DigitsOnlyDirective,
  ],
  exports: [
    PluckPipe,
    TruncatePipe,
    TruncatedPipe,
    ShortDateFormatPipe,
    FullDateFormatPipe,
    TruncateCenterPipe,
    SanitizeUrlPipe,
    DigitsOnlyDirective,
  ],
  providers: [ShortDateFormatPipe],
})
export class CdkDataTransformModule {}
