import {Directive, InjectionToken, TemplateRef} from '@angular/core';

export const MDS_TAB_TITLE = new InjectionToken<MdsTabTitleDirective>('MdsTabTitleDirective');

@Directive({
  selector: '[mdsTabTitle]',
  providers: [{provide: MDS_TAB_TITLE, useExisting: MdsTabTitleDirective}],
})
export class MdsTabTitleDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
