import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | null, length: number, end = '...'): string {
    if (value && value.length > length) {
      return value.substring(0, length - end.length).trim() + end;
    } else {
      return value ?? '';
    }
  }
}
