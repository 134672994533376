import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {IFilterOverrides, IKey, OptionDefinition, OptionView} from '../../intefaces/filter-select-item';
import {AudioPlayerControllerService} from '../../services/audio-player-controller/audio-player-controller.service';
import {AudioPlayerService, SHARED_AUDIO_PLAYER_SERVICE} from '@px/shared/audio-player';
import {filter, map, pluck, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {assoc, compose, dissoc, equals, filter as filterFn, isEmpty, not, uniq} from 'ramda';
import {IHotKeysService, SHARED_HOTKEYS_SERVICE} from '@px/shared/hotkeys';
import {Observable, Subscription} from 'rxjs';
import {AudioTableControllerService} from '../../services/audio-table-controller/audio-table-controller.service';
import {AudiosPageFetcher} from '../../intefaces/audios-page-fetcher';
import {IAudioChangeEvent} from '../../intefaces/audio-change-event';
import {AudioChangeType} from '../../enums/audio-change-type';
import {AudioSelectionSource} from '../../enums/audio-selection-source';
import {IAudioSelection} from '../../intefaces/audio-selection';
import {
  Audio,
  AUDIO_FACADE,
  AudioCategoryId,
  AudioFilterTypes,
  AudioGqlFacadeService,
  AudioSortKeys,
  CATEGORIES_RATING,
  FilterNames,
  IAudioFacadeService,
  IAudioFilterInfo,
  IAudioOptions,
  IAudioParams,
  IFilter,
  ISortItem,
  OrderDirection,
  RandomSortProp,
} from '@px/audio-domain';
import {MDS_DROPDOWN_POSITION, MDS_DROPDOWN_TYPE} from '@pui/components/dropdown';

@UntilDestroy()
@Component({
  selector: 'px-audio-search',
  templateUrl: './audio-search.component.html',
  styleUrls: ['./audio-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: SHARED_AUDIO_PLAYER_SERVICE, useClass: AudioPlayerService},
    AudioPlayerControllerService,
    AudioTableControllerService,
  ],
})
export class AudioSearchComponent implements OnInit, OnChanges {
  private readonly audioGqlFacadeService = inject(AudioGqlFacadeService);
  private readonly FILTERS_OVERRIDES: Partial<Record<string, IFilterOverrides>> = {
    lyrics: {
      label: 'Vocals',
    },
  };

  private filterInternal: IFilter = {};
  private audioChangesEventSub?: Subscription;
  readonly AudioSelectionSource = AudioSelectionSource;
  MDS_DROPDOWN_POSITION = MDS_DROPDOWN_POSITION;
  MDS_DROPDOWN_TYPE = MDS_DROPDOWN_TYPE;

  @Input() set isPlayerActive(value: boolean) {
    this.audioPlayerController.isPlayerActive = value;
  }
  @Input() orderedAvailableFilters: string[] = [];
  @Input() filterParams: Record<string, {availableOptionIds?: number[]}> = {};
  @Input() initialSortProp?: AudioSortKeys;
  @Input() initialSearchString?: string;
  @Input() set audiosPerPage(value: number) {
    this.audioTableController.audiosPerPage = value;
  }

  @Input() showFilterHint = false;
  @Input() disabledAudiosIds: number[] = [];
  @Input() isSearchBarFocused?: boolean;

  @Input()
  set filter(value: IFilter | null) {
    if (value) {
      this.filterInternal = value;
      this.updateSelectedOptions();
      this.filterChanged$.emit(this.filter);
    }
  }

  get filter(): IFilter {
    return this.filterInternal;
  }
  @Input() set audioChangesEvent$(obs$: Observable<IAudioChangeEvent[]> | undefined) {
    this.updateAudioChangedEventsSub(obs$);
  }
  @Input() recentSegmentsCount?: number;

  @Output() selectAudio$ = new EventEmitter<IAudioSelection>();
  @Output() audioChanged$ = new EventEmitter<Audio>();
  @Output() audioDeleted$ = new EventEmitter<Audio>();
  @Output() audioFavoriteChanged$ = new EventEmitter<Audio>();
  @Output() sortChanged$ = new EventEmitter<ISortItem>();
  @Output() filterChanged$ = new EventEmitter<IFilter>();

  searchQuery = '';
  selectedOptions: OptionView[] = [];
  optionsDefinitions: OptionDefinition[] = [];

  constructor(
    @Inject(AUDIO_FACADE) private readonly audioService: IAudioFacadeService,
    @Inject(SHARED_HOTKEYS_SERVICE) private readonly hotKeysService: IHotKeysService,
    private readonly cdr: ChangeDetectorRef,
    readonly audioPlayerController: AudioPlayerControllerService,
    readonly audioTableController: AudioTableControllerService
  ) {
    this.hotKeysService
      .like()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.audioPlayerController.playingAudio) {
          this.audioTableController.toggleFavorite(this.audioPlayerController.playingAudio);
        }
      });
  }

  private updateAudioChangedEventsSub(obs$: Observable<IAudioChangeEvent[]> | undefined): void {
    if (this.audioChangesEventSub) {
      this.audioChangesEventSub.unsubscribe();
    }
    if (obs$) {
      this.audioChangesEventSub = obs$.pipe(untilDestroyed(this)).subscribe(e => this.updateAudiosFromEvents(e));
    }
  }

  private updateAudiosFromEvents(events: IAudioChangeEvent[]): void {
    const del = events
      .filter(
        e =>
          e.type === AudioChangeType.DELETE ||
          (e.type === AudioChangeType.FAVORITE_CHANGE && !e.audio.is_favorite && this.filter.is_favorite)
      )
      .map(e => e.audio);
    const change = events
      .filter(
        e =>
          e.type === AudioChangeType.CHANGE || (e.type === AudioChangeType.FAVORITE_CHANGE && !this.filter.is_favorite)
      )
      .map(e => e.audio);

    this.audioTableController.updateAudiosState({change, del});
    const delInPlayer = del.find(d => d.id === this.audioPlayerController.playingAudio?.id);
    if (delInPlayer) {
      this.audioPlayerController.dispose();
    }
    const changeInPlayer = change.find(d => d.id === this.audioPlayerController.playingAudio?.id);
    if (changeInPlayer) {
      this.audioPlayerController.updateIfPlayingAudio(changeInPlayer);
    }
  }

  private updateSelectedOptions(): void {
    this.selectedOptions = this.optionsDefinitions
      .filter(o => !!this.filter[o.key])
      .map(option => {
        if (option.type === AudioFilterTypes.LIST) {
          const value = this.filter?.[option.key] as string[];

          const labels = (option.values ?? []).reduce(
            (acc: Record<string, string>, value: IKey): Record<string, string> => {
              acc[value.key] = value.label;
              return acc;
            },
            {}
          );

          return {
            key: option.key,
            label: option.label,
            value: value.map(key => ({label: labels[key], value: key})),
          };
        }

        return {key: option.key, label: option.label, value: this.filter?.[option.key] as boolean};
      });
  }

  private fetchFiltersOptions(): void {
    this.audioService
      .getFilters()
      .pipe(
        map(({filters}: IAudioOptions) =>
          Object.entries(filters).map(
            ([key, value]): OptionDefinition => ({
              key,
              label: this.FILTERS_OVERRIDES[key]?.label ?? key,
              type: value.type,
              values:
                value.type === AudioFilterTypes.LIST
                  ? value.values.map(({id, label}: IAudioFilterInfo) => ({label, key: id.toString()}))
                  : undefined,
            })
          )
        ),
        tap(filtersOptions => {
          this.optionsDefinitions = this.orderAndFilterOptions(filtersOptions);
          this.updateSelectedOptions();
        }),
        untilDestroyed(this)
      )
      .subscribe(() => this.cdr.detectChanges());
  }

  private orderAndFilterOptions(options: OptionDefinition[]): OptionDefinition[] {
    return this.orderedAvailableFilters
      .map(filterKey => {
        const item = options.find((item: OptionDefinition) => item.key === filterKey);
        if (!item) {
          return null;
        }
        if (item.key === FilterNames.CATEGORY) {
          item.values = item.values?.sort(
            (a, b) =>
              CATEGORIES_RATING[a.key as unknown as AudioCategoryId] -
              CATEGORIES_RATING[b.key as unknown as AudioCategoryId]
          );
        }

        item.label = this.FILTERS_OVERRIDES[item.key]?.label ?? item.label;
        item.values = item.values?.filter(value => {
          const ids = this.filterParams?.[item.key]?.availableOptionIds;
          return !ids || ids?.includes(Number(value.key));
        });

        return item;
      })
      .filter(item => !!item) as OptionDefinition[];
  }

  fetchAudiosPage: AudiosPageFetcher = (sort, page, size) => {
    const params: IAudioParams = {
      filter: this.filter,
      searchString: this.searchQuery || this.initialSearchString,
      sort,
      page: page,
      size: size,
      recent_segments_count: this.recentSegmentsCount,
      queryCategory: 'SEARCH',
    };

    if (params.sort && RandomSortProp.stringIsRandomSortProp(params.sort.prop)) {
      params.exclude = page === 1 ? [] : this.audioTableController.audios.map(a => a.id);
    }

    return this.audioService.find(params);
  };

  onChangeSearchQuery(query: string): void {
    this.searchQuery = query;
    this.audioTableController.fetchAudios();
  }

  removeFilterOption(key: string, value?: string): void {
    if (typeof value === 'string') {
      const newFilter = uniq(filterFn(compose(not, equals(value)), (this.filter[key] ?? []) as string[]));

      if (isEmpty(newFilter)) {
        this.filter = dissoc(key, this.filter);
      } else {
        this.filter = assoc(key, newFilter)(this.filter);
      }
    } else {
      this.filter = dissoc(key, this.filter);
    }
    this.audioTableController.fetchAudios();
  }

  addFilterOption(key: string, value?: string): void {
    if (typeof value === 'string') {
      this.filter = {
        ...this.filter,
        [key]: uniq([...((this.filter[key] as string[]) ?? []), value]),
      };
    } else {
      this.filter = {
        ...this.filter,
        [key]: true,
      };
    }
    this.audioTableController.fetchAudios();
  }

  clearFilters(): void {
    this.filter = {};
    this.audioTableController.fetchAudios();
  }

  addFilterGroup(optionDefinition: OptionDefinition): void {
    if (optionDefinition.values) {
      this.filter = {
        ...this.filter,
        [optionDefinition.key]: optionDefinition.values.map(v => v.key),
      };
    }

    this.audioTableController.fetchAudios();
  }

  selectAudio(audio: Audio, source: AudioSelectionSource): void {
    this.selectAudio$.next({audio, source});
  }

  clearAll(): void {
    this.filter = {};
    this.searchQuery = '';
    this.audioTableController.fetchAudios();
  }

  saveFavorite({is_favorite, id}: Audio): void {
    if (is_favorite) {
      this.audioService.markAsFavorite(id).subscribe();
    } else {
      this.audioService.removeFromFavorites(id).subscribe();
    }
  }

  shuffleSort(): void {
    this.audioGqlFacadeService.refreshSearchAudiosCache();
    this.audioTableController.changeSort({
      prop: RandomSortProp.VALUE,
      dir: OrderDirection.ASC,
    });
  }

  isRandomSortProp(string: string): boolean {
    return RandomSortProp.stringIsRandomSortProp(string);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && !changes['filter'].firstChange) {
      this.audioTableController.fetchAudios();
    }
  }

  ngOnInit(): void {
    if (this.initialSortProp) {
      this.audioTableController.sort = {
        prop: this.initialSortProp,
        dir: OrderDirection.ASC,
      };
    }

    this.audioTableController.stateChanged$
      .pipe(
        untilDestroyed(this),
        pluck('sort'),
        filter(v => !!v)
      )
      .subscribe(v => this.sortChanged$.emit(v));

    this.audioTableController.registerAudioPageFetcher(this.fetchAudiosPage);
    this.audioTableController.audioDeleted$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.audioService.delete(audio.id).pipe(untilDestroyed(this)).subscribe();
      if (audio.id === this.audioPlayerController.playingAudio?.id) {
        this.audioPlayerController.dispose();
      }
      this.audioDeleted$.emit(audio);
    });
    this.audioTableController.audioChanged$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.audioService.edit([audio]).pipe(untilDestroyed(this)).subscribe();
      this.audioPlayerController.updateIfPlayingAudio(audio);
      this.audioChanged$.emit(audio);
    });
    this.audioTableController.favoriteChanged$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.saveFavorite(audio);
      this.audioPlayerController.updateIfPlayingAudio(audio);
      this.audioFavoriteChanged$.emit(audio);
    });
    this.audioTableController.stateChanged$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
      this.audioPlayerController.add(this.audioTableController.audios);
    });

    this.audioPlayerController.stateChanged$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.audioPlayerController.favoriteChanged$.pipe(untilDestroyed(this)).subscribe(audio => {
      this.saveFavorite(audio);
      this.audioTableController.updateAudiosState({change: [audio]});
      this.audioFavoriteChanged$.emit(audio);
    });

    this.fetchFiltersOptions();
    this.audioTableController.fetchAudios();
  }
}
