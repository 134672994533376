import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  ColumnChangesService,
  ColumnMode,
  DatatableComponent,
  DimensionsHelper,
  ScrollbarHelper,
  SelectionType,
  SortType,
} from '@swimlane/ngx-datatable';
import {IColumn, ITemplateContext} from '../../interfaces/column.interface';
import {ISortEvent} from '../../interfaces/sort-event.interface';

@Component({
  selector: 'mds-datatable',
  templateUrl: './datatable.component.html',
  providers: [ScrollbarHelper, DimensionsHelper, ColumnChangesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsDataTableComponent<T, D> implements AfterViewInit, AfterViewChecked {
  private elementRef = inject(ElementRef);
  private cdr = inject(ChangeDetectorRef);

  @ViewChild(DatatableComponent) dataTableComponent?: DatatableComponent;

  @Output() nextPage$ = new EventEmitter<void>();
  @Output() sort$ = new EventEmitter<ISortEvent<D>>();
  @Output() select$ = new EventEmitter<T[]>();
  @Output() rowMouseenter$ = new EventEmitter<{row: T}>();

  columns: IColumn<T>[] = [];
  columnStore = new Map<string, Partial<IColumn<T>>>();
  SortType = SortType;
  dataRows: T[] = [];
  emptyTemplate: TemplateRef<void> | null = null;

  @Input() hasExternalSorting = true;
  @Input() isLoading = false;
  @Input() headerHeight = 48;
  @Input() rowHeight = 72;
  @Input() totalCount = 0; //TODO data.length ?
  @Input() data: T[] = [];
  @Input() sortState: {prop: string; dir: string}[] = [];
  @Input() selected?: T;
  @Input() selectionType?: SelectionType;
  @Input() getRowClass?: (item: T) => Record<string, boolean>;
  @Input() columnMode: keyof typeof ColumnMode = ColumnMode.standard;
  @Input() hasVirtualization = true;

  @Input() set needToResetScroll(value: boolean | undefined) {
    if (this.dataTableComponent && value) {
      this.dataTableComponent.offset = 0;
      this.dataTableComponent.bodyComponent.updateOffsetY(0);
    }
  }

  trackBy: TrackByFunction<IColumn<T>> = (idx: number, column: IColumn<T>) => column.prop;

  scroll(offsetY?: number): void {
    if (!offsetY) {
      return;
    }

    const {height} = this.elementRef.nativeElement.getBoundingClientRect();

    if (!this.isLoading && offsetY + (height + this.headerHeight) >= this.totalCount * this.rowHeight) {
      this.nextPage$.emit();
    }
  }

  sort($event: ISortEvent<D>): void {
    this.sort$.emit($event);
  }

  select($event: {selected: T[]}): void {
    this.select$.emit($event.selected);
  }

  initStore(id: string): void {
    this.columnStore.set(id, {});
  }

  setProp(id: string, key: keyof IColumn<T>, value: IColumn<T>[keyof IColumn<T>]): void {
    const dict = this.columnStore.get(id);

    if (!dict) {
      return;
    }

    dict[key] = value as never;

    this.cdr.markForCheck();
  }

  onActivate($event: {type: 'mouseenter' | 'mouseleave'; row: T}): void {
    if ($event.type === 'mouseenter') {
      this.rowMouseenter$.emit({row: $event.row});
    }
  }

  ngAfterViewInit(): void {
    this.columns = Array.from(this.columnStore.values())
      .map(
        (item): IColumn<T> => ({
          sortable: item.sortable ?? true,
          cellClass: item.cellClass ?? '',
          name: item.name ?? '',
          resizeable: item.resizeable ?? true,
          width: item.width ?? 0,
          prop: item.prop ?? '',
          headerClass: item.headerClass ?? '',
          order: item.order ?? 0,
          template: item.template as TemplateRef<ITemplateContext<T>>,
          canAutoResize: item.canAutoResize ?? true,
          frozenRight: item.frozenRight ?? false,
          frozenLeft: item.frozenLeft ?? false,
          headerTemplate: item.headerTemplate ?? null,
        })
      )
      .sort((a, b) => a.order - b.order);

    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.dataRows = [...this.data];
    this.cdr.detectChanges();
  }
}
