import {Pipe, PipeTransform} from '@angular/core';
import {Dayjs} from 'dayjs';

@Pipe({
  name: 'shortDateFormat',
})
export class ShortDateFormatPipe implements PipeTransform {
  private readonly DATE_STRING_FORMAT = 'MM/DD/YYYY';

  transform(value: Dayjs): string {
    return value.format(this.DATE_STRING_FORMAT);
  }
}
