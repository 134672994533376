import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {CdkPortalOutlet} from '@angular/cdk/portal';
import {Subject, takeUntil} from 'rxjs';
import {MdsTabComponent} from '../components/tab/tab.component';

@Directive({
  selector: '[mdsTabOutlet]',
})
export class MdsTabOutletDirective extends CdkPortalOutlet implements OnInit, OnDestroy {
  readonly _destroy$: Subject<void> = new Subject<void>();

  @Input() tab?: MdsTabComponent;

  override ngOnInit(): void {
    super.ngOnInit();

    if ((this.tab?.shouldAttach || this.tab?.active) && !this.hasAttached()) {
      this.attach(this.tab.content);
    } else {
      this.tab?.activeStateChange$.pipe(takeUntil(this._destroy$)).subscribe(isActive => {
        if (isActive && !this.hasAttached()) {
          this.attach(this.tab?.content);
        }
      });
    }
  }

  override ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    super.ngOnDestroy();
  }
}
