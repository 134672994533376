import {Inject, Injectable} from '@angular/core';
import {audioUploadingPhrases} from '../types/audio-uploading-progress-bar.models';
import {ISessionStorageService, SESSION_STORAGE} from '@px/shared/data-access/session-storage';

@Injectable()
export class AudioUploadingProgressPhrasesService {
  private key = 'audioUploadingPhrases';

  constructor(@Inject(SESSION_STORAGE) private readonly sessionStorageService: ISessionStorageService) {}

  initialize(): void {
    const phrases = this.sessionStorageService.getItem<string[]>(this.key) || [];

    if (phrases.length === 0) {
      this.sessionStorageService.setItem(this.key, audioUploadingPhrases);
    }
  }

  getRandomPhraseAndRemove(): string {
    let phrases = this.sessionStorageService.getItem<string[]>(this.key) || [];

    if (phrases.length === 0) {
      phrases = [...audioUploadingPhrases];
    }

    const randomIndex = Math.floor(Math.random() * phrases.length);
    const randomPhrase = phrases.splice(randomIndex, 1)[0];

    this.sessionStorageService.setItem(this.key, phrases);

    return randomPhrase;
  }
}
