import {inject, Injectable} from '@angular/core';
import {
  AssetBackendCreationError,
  AssetBackendCreationResult,
  IAssetBackendCreationProvider,
} from '@px/shared/asset-creation';
import {IAudioGQL} from '../entities/gql-schema-types/audio-gql';
import {catchError, Observable, of} from 'rxjs';
import {AudioGqlDataService} from './audio-gql-data.service';
import {IS3HostingInfo} from '@px/shared/data-access/file-hosting';

@Injectable()
export class AudioAssetBackendCreationService implements IAssetBackendCreationProvider<IAudioGQL, IS3HostingInfo> {
  private readonly audioGQLDataService = inject(AudioGqlDataService);

  create(hostingInfo: IS3HostingInfo): Observable<AssetBackendCreationResult<IAudioGQL>> {
    return this.audioGQLDataService
      .createAudio(hostingInfo.creds.s3Key, hostingInfo.creds.policy, hostingInfo.creds.signature)
      .pipe(catchError(err => of(new AssetBackendCreationError(err))));
  }
}
