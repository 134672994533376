import {inject, Injectable} from '@angular/core';
import {
  Audio,
  AudioBeatmatchingTemplate,
  AudioCategoryId,
  AudioChange,
  AudioGqlFacadeService,
  IAudioFacadeService,
  IAudioOptions,
  IAudioParams,
  IPagination,
} from '@px/audio-domain';
import {Observable} from 'rxjs';
import {AudioFile, IStreamUploadResult} from '@px/shared-data-access-file-upload';
import {PSSPlatformEnvironment} from '../platform-environment';
import {map} from 'rxjs/operators';

@Injectable()
export class PssAudioFacadeService implements IAudioFacadeService {
  private readonly platform = inject(PSSPlatformEnvironment);
  private audioFacadeService: IAudioFacadeService = inject(AudioGqlFacadeService);

  delete(id: number): Observable<void> {
    return this.audioFacadeService.delete(id);
  }

  edit(audios: AudioChange[]): Observable<void> {
    return this.audioFacadeService.edit(audios);
  }

  find(params: IAudioParams): Observable<[Audio[], IPagination]> {
    return this.audioFacadeService.find(params).pipe(
      map(([audios, pagination]) => {
        const newAudios = audios.map(audio => audio);
        return [newAudios, pagination];
      })
    );
  }

  getFilters(): Observable<IAudioOptions> {
    return this.audioFacadeService.getFilters();
  }

  getTemplatesByAudioId(id: number): Observable<AudioBeatmatchingTemplate[]> {
    return this.audioFacadeService.getTemplatesByAudioId(id);
  }

  isInsideBMTargetRange(n: number): boolean {
    return this.audioFacadeService.isInsideBMTargetRange(n);
  }

  markAsFavorite(id: number): Observable<void> {
    return this.audioFacadeService.markAsFavorite(id);
  }

  removeFromFavorites(id: number): Observable<void> {
    return this.audioFacadeService.removeFromFavorites(id);
  }

  upload(pxFiles: AudioFile[]): Observable<IStreamUploadResult<Audio>> {
    return this.audioFacadeService.upload(pxFiles);
  }
}
