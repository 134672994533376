import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {LoadingIndicatorSize} from '../../enums/loading-indicator-size';
import {NgClass} from '@angular/common';

@Component({
  selector: 'mds-loader-dots',
  standalone: true,
  imports: [NgClass],
  templateUrl: './loader-dots.component.html',
  styleUrls: ['./loader-dots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsLoaderDotsComponent {
  @Input() size: keyof typeof LoadingIndicatorSize = 'md';

  get sizeClass(): string {
    return `c-dot-loader--size-${this.size}`;
  }
}
