import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {AudioLyricsComponent} from '../audio-lyrics/audio-lyrics.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Audio} from '@px/audio-domain';

@Component({
  selector: 'px-audio-list-item',
  templateUrl: './audio-list-item.component.html',
  styleUrls: ['./audio-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioListItemComponent implements AfterViewChecked {
  private needCD = false;
  private audioLyricsMatDialogRef?: MatDialogRef<AudioLyricsComponent>;

  @Input() indexNumber = 1;
  @Input() isActive = false;
  @Input() isPlaying = false;
  @Input() isLoading = false;
  @Input() isFaded = false;
  @Input() audio?: Audio;

  @Output() play$ = new EventEmitter<void>();
  @Output() pause$ = new EventEmitter<void>();
  @Output() favoriteChange$ = new EventEmitter<void>();
  @Output() select$ = new EventEmitter<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly matDialog: MatDialog
  ) {}

  isTextTrimmed(element: HTMLElement, text: string | undefined): boolean | null {
    if (!text) {
      return null;
    }

    const elemText = element.innerText;

    if (elemText === text) {
      return element.offsetWidth < element.scrollWidth;
    }

    this.needCD = true;
    return null;
  }

  play(): void {
    this.play$.emit();
  }

  pause(): void {
    this.pause$.emit();
  }

  openAudioLyric(): void {
    this.audioLyricsMatDialogRef = this.matDialog.open<AudioLyricsComponent, Audio>(AudioLyricsComponent, {
      data: this.audio,
      panelClass: ['px-dialog', 'px-custom-width', 'px-audio-lyric-dialog'],
    });
  }

  onFavoriteClick(): void {
    this.favoriteChange$.emit();
  }

  ngAfterViewChecked(): void {
    if (this.needCD) {
      this.cdr.detectChanges();
      this.needCD = false;
    }
  }

  select(): void {
    this.select$.next();
  }
}
