<div [class]="'checkbox ' + hostClass" [ngClass]="{'checkbox--disabled': disabled, 'checkbox--readonly': readOnly}">
  <label class="checkbox__inner" [ngClass]="{'checkbox__inner--inline': inlined}">
    <input class="checkbox__input" type="checkbox" (ngModelChange)="change($event)" [ngModel]="checked" />
    <span class="checkbox__checkbox" [ngClass]="'checkbox__checkbox--' + size">
      <ng-template #baseTemplate>
        <span class="checkbox__empty checkbox__icon">
          <mds-icon icon="pxCheckboxOutlineBlank" />
        </span>
        <span class="checkbox__checked checkbox__icon">
          <mds-icon icon="pxCheckbox" />
        </span>
      </ng-template>
      <ng-container *ngIf="isIntermediate && !checked; else baseTemplate">
        <span class="checkbox__indeterminate checkbox__icon">
          <mds-icon icon="pxCheckboxIndeterminate" />
        </span>
      </ng-container>
    </span>
    <span class="checkbox__label">
      <ng-content></ng-content>
    </span>
  </label>
</div>
