import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'px-audio-title',
  templateUrl: './audio-title.component.html',
  styleUrls: ['./audio-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioTitleComponent {
  readonly soundTruncateLength = 35;

  @Input() title = '';
  @Input() artist = '';

  @Input() expiredInfo?: string;
}
