<div
  class="chip chip--d chip--outline"
  [ngClass]="{
    'chip--secondary': type === 'base',
    'chip--neutral': type === 'neutral',
    'chip--sm': size === 'sm'
  }"
>
  <ng-template #iconContentTpl>
    <ng-content select="mds-icon"></ng-content>
  </ng-template>
  <ng-container *ngIf="hasIcon">
    <div class="chip__action chip__action--left">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </div>
  </ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="removable">
    <div
      (click)="remove()"
      (keydown.enter)="remove()"
      role="button"
      tabIndex="0"
      class="chip__action chip__action--right"
    >
      <mds-icon icon="pxCancel" />
    </div>
  </ng-container>
</div>
