import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IBillingProductClientFeature} from '@ps/pricing-domain';
import {utilTrackByIndex} from '@pui/cdk/track-by';

@Component({
  selector: 'px-pricing-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCardComponent {
  @Input() highlighted = false;
  @Input() title?: string;
  @Input() priceMonthly?: number;
  @Input() priceYearly?: number;
  @Input() features?: IBillingProductClientFeature[];
  @Input() subscribed?: boolean;
  @Input() promoTitle: string | null = null;
  @Input() currencyCode = 'USD';
  @Input() actionDisabled = false;
  @Input() actionPerformed = false;
  @Input() actionLabel?: string;
  @Input() newPrice?: boolean;
  @Input() newPriceMonthly?: number;
  @Input() newPriceYearly?: number;

  @Output() buy$ = new EventEmitter<void>();

  trackBy = utilTrackByIndex;

  buy(): void {
    this.buy$.emit();
  }
}
