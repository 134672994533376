export enum UI_HINT_TYPE {
  default = 'default',
}

export enum UI_HINT_POSITION {
  default = 'default',
  top = 'top',
  topLeft = 'topLeft',
  topRight = 'topRight',
  right = 'right',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
  bottom = 'bottom',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  left = 'left',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
}

export const UI_HINT_POSITION_OFFSET_X = 20;

export const UI_HINT_POSITION_OFFSET_Y = 10;

export const UI_HINT_STORAGE_PREFIX = 'px-hint:';
