import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'px-audio-browser-view',
  templateUrl: './audio-browser-view.component.html',
  styleUrls: ['./audio-browser-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioBrowserViewComponent {
  @Input() isPlayerShown = false;
  @Input() sidesMarginIncreased = false;
}
