import {Inject, Injectable} from '@angular/core';
import {Dictionary, Update} from '@ngrx/entity';
import {AUDIO_FACADE, IAudio, IAudioFacadeService} from '@px/audio-domain';
import {CroppingFacadeService} from '@px/cropping/domain';
import {ICroppingData, ImageQuality, ISegment, ISlideshow} from '@px/shared/api';
import {AspectRatio} from '@px/util/aspect-ratio';
import difference from 'lodash/difference';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import unset from 'lodash/unset';
import {omit, uniq, whereEq} from 'ramda';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeepPartial} from 'ts-essentials';
import {getDevicePixelRatio} from '../auth/functions/auth-utils';
import {ExtractField} from '../core/interfaces/extract-field';
import {SlideshowChangesApi} from '../core/interfaces/slideshow-changes-api';
import {PartialSlideshowSaveResponse} from '../core/interfaces/slideshow-save-response';
import {ImageSortType} from '../models/enums/photos-sort.enum';
import {IPSSAudio} from '../models/pss-audio.model';
import {IPSSTemplate} from '../models/pss-template';
import {RedistributeChanges} from '../models/redistribute-changes';
import {
  CommonData,
  FeatureImage,
  Photo,
  PhotoChanges,
  PhotoState,
  Segment,
  SegmentChanges,
  SelectedPhotos,
} from '../models/slideshow.model';
import {ImagesUrls} from '../models/user.model';
import {PSSPlatformEnvironment} from '../platform-environment';
import {THUMBNAIL_IMAGE_SIZE_SEARCH_PARAMS} from '../shared/consts/thumbnail-image-size-search-params';
import {MinScreenIntermidiateSize} from '../shared/enums/min-screen-intermidiate-size';
import {ThumbnailImageSize} from '../shared/enums/thumbnail-image-size';
import {toApiSlideshowDate} from '../store/slideshow/slideshow-utils';
import {photoAdapter, segmentAdapter} from '../store/slideshow/slideshow.adapters';
import {SlideShowState} from '../store/slideshow/slideshow.state';
import {NaturalSortService} from './natural-sort.service';

@Injectable({
  providedIn: 'root',
})
export class SlideshowUtilsService {
  readonly segmentIds = new Map<ExtractField<Segment, 'id'>, ExtractField<Segment, 'id'> | null>();
  readonly photoIds = new Map<ExtractField<Photo, 'id'>, ExtractField<Photo, 'id'> | null>();

  constructor(
    private readonly naturalSortService: NaturalSortService,
    private readonly platform: PSSPlatformEnvironment,
    private readonly croppingFacade: CroppingFacadeService,
    @Inject(AUDIO_FACADE) private readonly audioFacade: IAudioFacadeService
  ) {}

  getPhotoId(id: ExtractField<Photo, 'id'>): ExtractField<Photo, 'id'> {
    return this.photoIds.get(id) ?? id;
  }

  getUpdatedPhotoList(chagnes: Update<Photo>[], state: PhotoState): PhotoState {
    return photoAdapter.updateMany(chagnes, state);
  }

  aspectRatioToNumber(a: AspectRatio): number {
    const [width, height] = a.split(':');
    return Number(width) / Number(height);
  }

  getSegmentId(id: ExtractField<Segment, 'id'>): ExtractField<Segment, 'id'> {
    return this.segmentIds.get(id) ?? id;
  }

  mergeChangesAfterSave(
    changes: SlideshowChangesApi | null,
    response: PartialSlideshowSaveResponse | null,
    state?: SlideShowState
  ): SlideShowState {
    if (state?.id == null) {
      return state;
    }

    const newStateAfterSave: SlideShowState = {...state};

    if (response?.segments) {
      let photoChanges: Update<Photo>[] = [];

      let selectedSegmentId = state.segmentList.selectedSegmentId;

      const newSegmentsState: Update<Segment>[] = response.segments.map(segmentFromServer => {
        this.segmentIds.set(segmentFromServer.uuid, segmentFromServer.id);

        if (state.segmentList.selectedSegmentId === segmentFromServer.uuid) {
          selectedSegmentId = segmentFromServer.id;
        }

        photoChanges = photoChanges.concat(
          segmentFromServer.copied_photos?.reduce((photoChangesList: Update<Photo>[], photoFromServer) => {
            if (photoFromServer.uuid in state.photoList.entities) {
              photoChangesList.push({
                id: photoFromServer.uuid,
                changes: {
                  id: photoFromServer.id,
                  locals: {
                    ...state.photoList.entities[photoFromServer.uuid].locals,
                    clientOnly: false,
                    mirror_id: undefined,
                    mirror_uuid: undefined,
                  },
                },
              });
            }
            return photoChangesList;
          }, []) ?? []
        );

        const id = (segmentFromServer.uuid || segmentFromServer.id) as number;

        const {
          segmentList: {
            entities: {[id]: segment},
          },
        } = state;

        const photosIds = uniq([
          ...(segment?.photosIds.map(
            oldId => (segmentFromServer?.copied_photos ?? []).find(photo => photo.uuid === oldId)?.id ?? oldId
          ) ?? []),
        ]);

        const manualOrderedPhotosIds = uniq([
          ...(segment?.manualOrderedPhotosIds.map(
            oldId => (segmentFromServer?.copied_photos ?? []).find(photo => photo.uuid === oldId)?.id ?? oldId
          ) ?? []),
        ]);

        return {
          id,
          changes: {
            id: segmentFromServer.id as number,
            gqlId: segmentFromServer.gqlId,
            ...(segmentFromServer.template ? {template: {...segmentFromServer.template}} : {}),
            ...(segmentFromServer.copied_photos
              ? {
                  photosIds,
                  manualOrderedPhotosIds,
                }
              : {}),
            ...(segmentFromServer.copied_photos
              ? {
                  selectedPhotos: Object.entries(
                    state.segmentList.entities[segmentFromServer.id]?.selectedPhotos ?? {}
                  ).reduce(
                    (acc: SelectedPhotos, [oldId, data]) => ({
                      ...acc,
                      [segmentFromServer.copied_photos.find(photo => photo.uuid === oldId)?.id ?? oldId]: data,
                    }),
                    {}
                  ),
                }
              : {}),
          },
        };
      });

      if (photoChanges) {
        newStateAfterSave.photoList = photoAdapter.updateMany(photoChanges, {...state.photoList});
      }

      newStateAfterSave.segmentList = segmentAdapter.updateMany(newSegmentsState, {
        ...state.segmentList,
        selectedSegmentId,
      });
    }

    if (changes) {
      newStateAfterSave.locals = {
        ...state.locals,
        deleted_segments: [],
        deleted_photos: difference(state.locals?.deleted_photos ?? [], changes.deleted_photos ?? []),
      };

      if (changes.deleted_segments) {
        newStateAfterSave.locals = {
          ...newStateAfterSave.locals,
          deleted_segments: difference(state.locals?.deleted_segments ?? [], changes.deleted_segments).filter(
            item => typeof item === 'number'
          ),
        };
      }

      if (changes.deleted_photos) {
        newStateAfterSave.locals = {
          ...newStateAfterSave.locals,
          deleted_photos: difference(state.locals?.deleted_photos ?? [], changes.deleted_photos),
        };
      }
    }

    return newStateAfterSave;
  }

  getSlideshowChanges(
    actualSlideshow: Partial<SlideShowState>,
    savedSlideshow: Partial<SlideShowState>,
    forPublish = false
  ): SlideshowChangesApi {
    const extraProperties: (keyof SlideShowState)[] = [
      'locals',
      'segmentList',
      'photoList',
      'urls',
      'build_video_estimated_time',
      'build_video_4k_estimated_time',
      'video_4k',
      'video_1080p',
    ];

    const diff: Partial<SlideShowState> = Object.entries(actualSlideshow).reduce(
      (previous, [key, value]: [keyof SlideShowState, SlideShowState[keyof SlideShowState]]) => {
        if (value === savedSlideshow[key] || extraProperties.includes(key)) {
          return previous;
        }

        return {
          ...previous,
          [key]: value,
        };
      },
      {}
    );

    if (diff.common_data) {
      diff.common_data = this.getCommonDataChanges(
        actualSlideshow.common_data,
        savedSlideshow.common_data ?? {},
        forPublish
      );

      if (diff.common_data.is_download_pin) {
        if (!diff.common_data.is_download_pin) {
          diff.common_data.download_pin = null;
        }

        delete diff.common_data.is_download_pin;
      }

      if (!Object.keys(diff.common_data).length) {
        delete diff.common_data;
      }
    }

    if (diff.meta_data && isEqual(actualSlideshow.meta_data, savedSlideshow.meta_data)) {
      delete diff.meta_data;
    }

    const changes: SlideshowChangesApi = {};

    if (Object.keys(diff).length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      changes.slide_show = toApiSlideshowDate(diff);
    }

    if (actualSlideshow.segmentList) {
      const segmentsDiff = this.getSegmentsChanges(actualSlideshow, savedSlideshow);

      if (segmentsDiff.length) {
        changes.segments = segmentsDiff;
      }
    }

    if (actualSlideshow.locals) {
      if (actualSlideshow.locals.deleted_segments.length > 0) {
        changes.deleted_segments = actualSlideshow.locals.deleted_segments.filter(item => typeof item === 'number');
        changes.deleted_segments_gql = actualSlideshow.locals.deleted_segments.map(
          item => savedSlideshow.segmentList.entities[item]?.gqlId
        );
      }

      if (actualSlideshow.locals.deleted_photos.length > 0) {
        changes.deleted_photos = actualSlideshow.locals.deleted_photos.filter(item => typeof item === 'number');
      }
    }

    return changes;
  }

  getCommonDataChanges(
    common_data_actual: Partial<CommonData>,
    common_data_saved: Partial<CommonData>,
    forPublish = false
  ): Record<string, unknown> {
    function changes(
      object,
      base
    ): Omit<Partial<CommonData>, 'featured_image'> & {
      featured_image: ExtractField<FeatureImage, 'id'> | FeatureImage;
      featured_image_uuid?: string;
    } {
      return transform(object, (result, value, key: string) => {
        if (!isEqual(value, base[key])) {
          result[key] =
            isObject(value) && isObject(base[key]) && !(value instanceof Date) && !isArray(value)
              ? changes(value, base[key])
              : value;
        }
      });
    }

    const commonDataChanges = changes(common_data_actual, common_data_saved);

    // common_data.state should not be sent to the server
    if (!forPublish) {
      unset(commonDataChanges, 'state');
    }

    // featured_image should be sent to backend as featured image id and not as featured_image object
    if (has(commonDataChanges, 'featured_image') && typeof commonDataChanges.featured_image === 'object') {
      commonDataChanges.featured_image = commonDataChanges.featured_image.id;

      if (common_data_actual.featured_image.mediaId) {
        commonDataChanges.featured_image_uuid = common_data_actual.featured_image.mediaId;
      }
    }

    // focal_point should be sent to backend with both x and y values
    if (has(commonDataChanges, 'focal_point')) {
      commonDataChanges.focal_point = common_data_actual.focal_point;
    }

    // ToDo refactor this on BE side
    if (has(commonDataChanges, 'call_to_action')) {
      commonDataChanges.call_to_action = common_data_actual.call_to_action;
    }

    return commonDataChanges;
  }

  getSegmentsChanges(
    actualSlideshow: Partial<SlideShowState>,
    savedSlideshow: Partial<SlideShowState>
  ): SegmentChanges[] {
    const actualSegmentList = actualSlideshow.segmentList;
    const savedSegmentList = savedSlideshow.segmentList;
    const actualPhotos = actualSlideshow.photoList.entities;
    const savedPhotos = savedSlideshow.photoList.entities;
    const aspectRatio = actualSlideshow.aspect_ratio;

    const actualSegments = actualSegmentList.entities;
    const savedSegments = savedSegmentList.entities;

    return Object.keys(actualSegments).reduce((segments, segmentId: ExtractField<Segment, 'id'>): SegmentChanges[] => {
      const actualSegment: Segment = {...actualSegments[segmentId]};
      const savedSegment: Segment = savedSegments[segmentId] ? {...savedSegments[segmentId]} : null;

      const segmentChanges = Object.entries(actualSegment).reduce(
        (changes, [key, value]: [keyof Segment, Segment[keyof Segment]]): SegmentChanges => {
          const extraKeys: (keyof Segment)[] = ['locals', 'selectedPhotos', 'manualOrderedPhotosIds'];

          // TODO: instead  of comparing typeof id use actualSegment.id !== actualSegment.uuid when PIC is dead
          if (extraKeys.includes(key) || (key === 'uuid' && typeof actualSegment.id === 'number')) {
            return changes;
          }

          switch (key) {
            case 'id':
              return Number.isNaN(Number(value)) // TODO: same as above
                ? {
                    ...changes,
                    uuid: value as string,
                  }
                : changes;
            case 'audio':
              if (savedSegment?.audio?.id !== (value as IPSSAudio)?.id) {
                return {
                  ...changes,
                  audio: (value as IPSSAudio).id,
                  audio_gql_id: (value as IPSSAudio).gqlId,
                } as SegmentChanges;
              }
              break;
            case 'beat_matching_template':
              if (savedSegment?.beat_matching_template?.id !== (value as IPSSTemplate)?.id) {
                return {
                  ...changes,
                  beat_matching_template: {
                    id: (value as IPSSTemplate)?.id,
                    gqlId: (value as IPSSTemplate)?.gqlId,
                    tempo_type: (value as IPSSTemplate)?.tempo_type,
                  },
                };
              }
              break;
            case 'template': {
              if (!savedSegment?.[key] && value) {
                return {
                  ...changes,
                  template: {
                    id: (value as IPSSTemplate).id,
                    gqlId: (value as IPSSTemplate).gqlId,
                    tempo_type: (value as IPSSTemplate).tempo_type,
                    transitions: (value as IPSSTemplate).transitions,
                  } as Partial<IPSSTemplate>,
                };
              } else if (value) {
                const templateChanges = (value as IPSSTemplate).transitions.reduce(
                  (templateUpdate: DeepPartial<IPSSTemplate>, transition) => {
                    const savedTransition = savedSegment?.template.transitions?.[0];

                    if (
                      savedTransition &&
                      !isEqual(omit(['id', 'gqlId'], transition), omit(['id', 'gqlId'], savedTransition))
                    ) {
                      if (!('transitions' in templateUpdate)) {
                        templateUpdate['transitions'] = [];
                      }
                      if ((value as IPSSTemplate).id) {
                        templateUpdate.id = (value as IPSSTemplate).id;
                      }
                      if ((value as IPSSTemplate).gqlId) {
                        templateUpdate.gqlId = (value as IPSSTemplate).gqlId;
                      }
                      if ((value as IPSSTemplate).tempo_type) {
                        templateUpdate.tempo_type = (value as IPSSTemplate).tempo_type;
                      }

                      templateUpdate.transitions.push(transition);
                    }

                    return templateUpdate;
                  },
                  {}
                );

                if (Object.keys(templateChanges).length) {
                  return {
                    ...changes,
                    template: templateChanges,
                  };
                }
              }
              break;
            }
            case 'photosIds': {
              const photosChanges: PhotoChanges[] = [];
              const copiedPhotos: PhotoChanges[] = [];

              const deletedPhotos: PhotoChanges[] = (savedSegment?.photosIds || [])
                .filter(
                  photoId =>
                    actualSlideshow.locals.deleted_photos.includes(photoId) ||
                    (!actualSegment.photosIds.includes(photoId) && savedPhotos[photoId]?.mediaId)
                )
                .map(photoId => {
                  return {
                    id: photoId as number,
                    mediaId: savedPhotos[photoId].mediaId,
                  };
                });

              for (const id of value as ExtractField<Segment, 'photosIds'>) {
                const photoChanges = this.getPhotoChanges(
                  actualPhotos[id],
                  savedPhotos[id],
                  aspectRatio,
                  actualSegment,
                  savedSegment
                );

                if (Object.keys(photoChanges).length) {
                  photosChanges.push(photoChanges);
                }

                const copiedPhoto = this.getCopiedPhoto(actualPhotos[id], actualSegment, aspectRatio);
                if (copiedPhoto) {
                  copiedPhotos.push(copiedPhoto);
                }
              }

              if (photosChanges.length || copiedPhotos.length || deletedPhotos.length) {
                return {
                  has_manual_photos_order: savedSegment?.has_manual_photos_order || false,
                  ...changes,
                  ...(photosChanges.length ? {photos: photosChanges} : {}),
                  ...(copiedPhotos.length ? {copied_photos: copiedPhotos} : {}),
                  ...(deletedPhotos.length ? {deleted_photos: deletedPhotos} : {}),
                };
              }

              break;
            }
            default:
              return savedSegment && value === savedSegment[key] ? changes : {...changes, [key]: value};
          }
          return changes;
        },
        {} as SegmentChanges
      );

      if (
        !savedSegment ||
        actualSegmentList.ids.findIndex(item => actualSegment.id === item) !==
          savedSegmentList.ids.findIndex(item => savedSegment.id === item)
      ) {
        segmentChanges.order = actualSegmentList.ids.findIndex(item => actualSegment.id === item);
      }

      if (Object.keys(segmentChanges).length) {
        segmentChanges.gqlId = savedSegment?.gqlId;

        if (!segmentChanges.id && !segmentChanges.uuid) {
          segmentChanges.id = actualSegment.id;
        }
        return [...segments, segmentChanges];
      }

      return segments;
    }, [] as SegmentChanges[]);
  }

  getPhotoChanges(
    actualPhoto: Photo,
    savedPhoto: Photo,
    aspectRatio: AspectRatio,
    actualSegment: Segment,
    savedSegment?: Segment
  ): PhotoChanges {
    const actualOrder = actualSegment.photosIds.findIndex(id => id === actualPhoto.id);
    const oldOrder = savedSegment?.photosIds.findIndex(id => id === actualPhoto.id);

    const actualManualOrder = actualSegment.manualOrderedPhotosIds.findIndex(id => id === actualPhoto.id);
    const oldManualOrder = savedSegment?.manualOrderedPhotosIds?.findIndex(id => id === actualPhoto.id);

    let photoChanges: PhotoChanges = {};

    const isNewPhotoUploaded = !actualPhoto.locals?.clientOnly;

    if (actualPhoto?.id && isNewPhotoUploaded) {
      if (savedPhoto && oldOrder !== actualOrder) {
        photoChanges = {
          id: actualPhoto.id,
          mediaId: actualPhoto.mediaId,
          order: actualOrder,
        };
      }

      if (!savedPhoto) {
        photoChanges = {
          id: actualPhoto.id,
          mediaId: actualPhoto.mediaId,
          mediaItemId: actualPhoto.mediaItemId,
          order: actualOrder,
          shouldBeCreated: true,
        };
      }

      if (actualManualOrder !== oldManualOrder && (savedPhoto || isNewPhotoUploaded)) {
        photoChanges.id = actualPhoto.id;
        photoChanges.mediaId = actualPhoto.mediaId;
        photoChanges.manual_order = actualManualOrder;
      }

      if (savedPhoto && actualPhoto.cropped !== savedPhoto?.cropped) {
        photoChanges.id = actualPhoto.id;
        photoChanges.mediaId = actualPhoto.mediaId;
        photoChanges.cropped = actualPhoto.cropped;
      }

      if (
        savedPhoto &&
        this.isCroppingDataChanged(actualPhoto.cropping_data[aspectRatio], savedPhoto.cropping_data[aspectRatio])
      ) {
        photoChanges.id = actualPhoto.id;
        photoChanges.mediaId = actualPhoto.mediaId;
        photoChanges.cropped = actualPhoto.cropped;

        photoChanges.cropping_data = actualPhoto.cropping_data[aspectRatio];
      }
    }

    return photoChanges;
  }

  getCopiedPhoto(actualPhoto: Photo, actualSegment: Segment, aspectRatio: AspectRatio): PhotoChanges | null {
    if (actualPhoto.locals?.mirror_id) {
      const actualOrder = actualSegment.photosIds.findIndex(id => id === actualPhoto.id);
      const actualManualOrder = actualSegment.manualOrderedPhotosIds.findIndex(id => id === actualPhoto.id);

      return {
        id: actualPhoto.locals.mirror_id,
        order: actualOrder,
        manual_order: actualManualOrder,
        uuid: actualPhoto.locals.uuid,
        cropped: actualPhoto.cropped,
        cropping_data: actualPhoto.cropping_data?.[aspectRatio],
      };
    }

    return null;
  }

  isCroppingDataChanged(actualCroppingData?: ICroppingData, savedCroppingData?: ICroppingData): boolean {
    if (!actualCroppingData && !savedCroppingData) {
      return false;
    }
    return !actualCroppingData || !savedCroppingData || !whereEq(savedCroppingData)(actualCroppingData);
  }

  getSegmentIdByPhotoId(
    entities: Dictionary<Segment>,
    photoId: ExtractField<Photo, 'id'>
  ): ExtractField<Segment, 'id'> | null {
    return Object.values(entities).find(({photosIds}) => photosIds.includes(photoId))?.id;
  }

  sanitizeOrder(order?: number): number {
    return !order || order === -1 ? 0 : order;
  }

  calculateDurationPerSlide(slideshowDuration: number, photoCount: number): number {
    return (
      (slideshowDuration -
        this.platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START -
        this.platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.END) /
      photoCount
    );
  }

  calculateSlideshowDuration(segments: Segment[]): number {
    return segments.reduce((acc: number, segment: Segment) => {
      return (acc += (segment.audio_end - segment.audio_start) * 1000);
    }, 0);
  }

  getPhotoIdsFromSegments(segments: Segment[]): ExtractField<Photo, 'id'>[] {
    return segments.reduce((acc: ExtractField<Photo, 'id'>[], segment: Segment) => {
      return [...acc, ...segment.photosIds];
    }, []);
  }

  getNewSegmentSize(
    duration: number,
    durationPerSlide: number,
    slideshowSize: number,
    position: number,
    remainingSize: number
  ): number {
    let segmentPhotosCount = 0;

    if (position === slideshowSize - 1) {
      segmentPhotosCount = remainingSize;
    } else {
      const time =
        position === 0 ? duration - this.platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START / 1000 : duration;

      segmentPhotosCount = Math.min(
        Math.round((time * 1000) / durationPerSlide) || 1,
        Math.max(remainingSize - (slideshowSize - position - 1), 1)
      );
    }

    return segmentPhotosCount;
  }

  redistributeSegments(segments: Segment[], photosList: Dictionary<Photo>): RedistributeChanges {
    let hasChanges = false;
    let segmentsChanges: Update<Segment>[] = [];

    const remainingPhotos = this.getPhotoIdsFromSegments(segments);
    const slideshowDuration = this.calculateSlideshowDuration(segments);
    const durationPerSlide = this.calculateDurationPerSlide(slideshowDuration, remainingPhotos.length);
    const photosChanges: Update<Photo>[] = [];

    if (remainingPhotos.length) {
      segmentsChanges = segments.map((segment, position): Update<Segment> => {
        const segmentPhotosCount = this.getNewSegmentSize(
          segment.audio_end - segment.audio_start,
          durationPerSlide,
          segments.length,
          position,
          remainingPhotos.length
        );

        if (segment.photosIds.length !== segmentPhotosCount) {
          hasChanges = true;
        }

        let photosIds = remainingPhotos.splice(0, segmentPhotosCount);

        // TODO: need to move to reducer
        if (segment.current_sort === ImageSortType.NAME || segment.current_sort === ImageSortType.DATE_TAKEN) {
          photosIds = photosIds.sort((aId, bId) => {
            return this.naturalSortService.sort(
              photosList[aId][segment.current_sort],
              photosList[bId][segment.current_sort]
            );
          });
        }

        const manualOrderedPhotosIds =
          segment.current_sort === ImageSortType.MANUAL && segment.manualOrderedPhotosIds?.length ? photosIds : [];

        return {
          id: segment.id as number,
          changes: {
            photosIds,
            manualOrderedPhotosIds,
            ...this.pickSegmentCustomTemplate(segment),
          },
        };
      });
    }

    return {
      segmentsChanges,
      photosChanges,
      hasChanges,
    };
  }

  pickSegmentCustomTemplate(segment: Segment): Partial<Segment> {
    if (segment.is_beatmatching) {
      return {
        is_beatmatching: false,
        beat_matching_template: null,
      };
    }

    return {};
  }

  getAudios(segments: Segment[]): IPSSAudio[] {
    return segments.reduce((audios: IPSSAudio[], segment: Segment): IPSSAudio[] => {
      if (segment.audio) {
        audios.push(segment.audio);
      }
      return audios;
    }, []);
  }

  getTemplates(segments: Segment[]): IPSSTemplate[] {
    return segments.reduce((acc, {audio}) => {
      if (audio?.templates) {
        acc.push(...audio.templates);
      }
      return acc;
    }, []);
  }

  fetchTemplatesForAudio(audio: IAudio): Observable<IAudio> {
    return this.audioFacade.getTemplatesByAudioId(audio.id).pipe(
      map(templates => {
        return {...audio, templates: templates.map(t => t.getPlain())};
      })
    );
  }

  getPlainSlideshow(state: SlideShowState, isDownloadButton: boolean): ISlideshow {
    return {
      encoded_name: state.encoded_name,
      created: state.created,
      common_data: {
        is_download_pin: state.common_data?.is_download_pin,
        video_background: state.common_data?.video_background,
        is_audio_changed: state.common_data?.is_audio_changed,
        is_email_me: state.common_data?.is_email_me,
        is_email_registration_allowed: false,
        is_favorites_allowed: false,
        embed_config: {
          centered: state.common_data?.embed_config?.centered,
          width: state.common_data?.embed_config?.width,
          height: state.common_data?.embed_config?.height,
        },
        is_download_button: state.common_data?.is_download_button && isDownloadButton,
        download_protected: state.common_data?.download_protected,
        featured_image: {
          image_path: state.common_data?.featured_image?.image_path,
          image_link2k: state.common_data?.featured_image?.image_link2k,
          image_link4k: state.common_data?.featured_image?.image_link4k,
          id: state.common_data?.featured_image?.id,
          active_storage_service: state.common_data?.featured_image?.active_storage_service,
          image_1080p: state.common_data?.featured_image?.image_1080p,
          image_1080pWBlur: state.common_data?.featured_image?.image_1080pWBlur,
          width: state.common_data?.featured_image?.width,
          height: state.common_data?.featured_image?.height,
          cropped: state.common_data?.featured_image?.cropped,
          cropping_data: state.common_data?.featured_image?.cropping_data,
        },
        password: state.common_data?.password,
        download_options: state.common_data?.download_options as ImageQuality[],
        call_to_action: {
          enabled: state.common_data?.call_to_action?.enabled,
          button_name: state.common_data?.call_to_action?.button_name,
          external_link: state.common_data?.call_to_action?.external_link,
        },
        theme: state.common_data?.theme,
        state: state.common_data?.state,
        download_pin: state.common_data?.download_pin,
        event_date: state.common_data?.event_date?.getTime(),
        focal_point: state.common_data?.focal_point,
        is_view_button: null,
        display_filenames: false,
      },
      slug: state.slug,
      name: state.name,
      aspect_ratio: state.aspect_ratio,
      unique_identifier: state.unique_identifier,
      id: state.id,
      original_user_id: state.original_user_id,
      build_video_estimated_time: state.build_video_estimated_time,
      video_4k: state.video_4k,
      video_1080p: state.video_1080p,
      build_video_4k_estimated_time: state.build_video_4k_estimated_time,
      meta_data: state.meta_data,
      segments: state.segmentList.ids
        .map((id): ISegment => {
          const item = state.segmentList.entities[id];

          if (!item.audio) {
            return null;
          }

          return {
            slug: null,
            beat_matching_template: {
              is_custom: item.beat_matching_template?.is_custom,
              name: item.beat_matching_template?.name,
              tempo_id: item.beat_matching_template?.tempo_id,
              tempo_type: item.beat_matching_template?.tempo_type,
              id: item.beat_matching_template?.id,
              matching_images: item.beat_matching_template?.matching_images,
              version: item.beat_matching_template?.version,
              configs_path: item.beat_matching_template?.configs_path,
              transitions: item.beat_matching_template?.transitions,
            },
            is_beatmatching: item.is_beatmatching,
            audio: {
              templates: item.audio.templates,
              up_tempo: item.audio.up_tempo,
              down_tempo: item.audio.down_tempo,
              lyrics: item.audio.lyrics,
              energy: item.audio.energy,
              is_new: item.audio.is_new,
              expires_at: item.audio.expires_at,
              genre: item.audio.genre,
              original_file_name: item.audio.original_file_name,
              is_favorite: item.audio.is_favorite,
              instrumental: item.audio.instrumental,
              song_title: item.audio.song_title,
              audio_mp3: item.audio.audio_mp3,
              audio_ogg: item.audio.audio_ogg,
              expired: item.audio.expired,
              id: item.audio.id,
              category: item.audio.category,
              waveform_data: item.audio.waveform_data,
              artist: item.audio.artist,
              length: item.audio.length,
              provider: item.audio.provider,
              round_added: item.audio.round_added,
              category_id: item.audio.category_id,
              energy_id: item.audio.energy_id,
              is_recently_used: item.audio.is_recently_used,
            },
            audio_start: item.audio_start,
            order: item.order,
            has_manual_photos_order: item.has_manual_photos_order,
            is_published: item.is_published,
            current_sort: item.current_sort,
            id: id as number,
            audio_end: item.audio_end,
            name: item.name,
            template: {
              is_custom: item.template?.is_custom,
              name: item.template?.name,
              tempo_id: item.template?.tempo_id,
              tempo_type: item.template?.tempo_type,
              id: item.template?.id,
              matching_images: item.template?.matching_images,
              version: item.template?.version,
              configs_path: item.template?.configs_path,
              transitions: item.template?.transitions,
            },
            photos: item.photosIds.map((id: number) => {
              const photo: Omit<Photo, 'id'> = state.photoList.entities[id];

              return {
                id,
                mediaItemId: photo.mediaItemId,
                image_path: photo.image_path,
                image_link2k: photo.image_link2k,
                image_link4k: photo.image_link4k,
                active_storage_service: photo.active_storage_service,
                image_1080p: photo.image_1080p,
                image_1080pWBlur: photo.image_1080pWBlur,
                width: photo.width,
                height: photo.height,
                name: photo.name,
                height_4k: photo.height_4k,
                image_2k_hd: photo.image_2k_hd,
                image_4k: photo.image_4k,
                is_low_resolution: photo.is_low_resolution,
                order: photo.order,
                manual_order: photo.manual_order,
                width_4k: photo.width_4k,
                thumbnail: photo.thumbnail,
                image_2k_hd_white: photo.image_2k_hd_white,
                image_4k_video_white: photo.image_4k_video_white,
                selected: photo.selected,
                image_4k_video: photo.image_4k_video,
                cropped: photo.cropped,
                cropping_data: photo.cropping_data,
                date_taken: photo.date_taken,
              };
            }),
          };
        })
        .filter(s => !!s),
    };
  }

  createThumbnailUrl(photo: Photo, imageUrls: ImagesUrls, userId: string, croppingData?: ICroppingData): string {
    if (photo.image_link2k && photo.image_link4k) {
      const dpr = getDevicePixelRatio();
      const imageLink =
        croppingData &&
        (screen.width * dpr > MinScreenIntermidiateSize.WIDTH || screen.height * dpr > MinScreenIntermidiateSize.HEIGHT)
          ? photo.image_link4k
          : photo.image_link2k;

      const url = new URL(imageLink);

      if (croppingData) {
        url.searchParams.set('crop', this.croppingFacade.createCropParameter(croppingData));
      } else {
        const imageUrlParams = THUMBNAIL_IMAGE_SIZE_SEARCH_PARAMS[ThumbnailImageSize.EDITOR_PAGE];
        url.searchParams.set('width', imageUrlParams.width.toString());
        url.searchParams.set('height', imageUrlParams.height.toString());

        if (imageUrlParams.crop) {
          url.searchParams.set('crop', imageUrlParams.crop.toString());
        }
      }

      return url.toString();
    }
    const thumbnailUrlTemplate = croppingData ? imageUrls.imageUrl : imageUrls.editorThumbnailUrl;

    return (
      thumbnailUrlTemplate.replace(/STORE/i, photo.active_storage_service).replace(/USER_ID/i, userId) +
      photo.image_path +
      '?' +
      imageUrls.imageUrlHParam +
      (croppingData ? `&${this.croppingFacade.createCropParameter(croppingData)}` : '')
    );
  }

  addPhotoToSavedState(photo: Photo, segmentId: number, savedState: SlideShowState): SlideShowState {
    const newState: SlideShowState = {...savedState};
    newState.photoList = photoAdapter.addOne(photo, {...savedState.photoList});

    const savedSegment = savedState.segmentList.entities[segmentId];

    if (savedSegment) {
      const segmentChanges = {
        id: segmentId,
        changes: {
          photosIds: savedSegment.photosIds.concat(photo.id),
        },
      };
      newState.segmentList = segmentAdapter.updateOne(segmentChanges, savedState.segmentList);
    }

    return newState;
  }
}
