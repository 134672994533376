import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncated',
})
export class TruncatedPipe implements PipeTransform {
  private static isTruncated(string: string | null, length: number): boolean {
    return !!string && string.length > length;
  }

  transform(value: string | null, length: number): string {
    return TruncatedPipe.isTruncated(value, length) ? value ?? '' : '';
  }
}
