<div mat-dialog-title="" layout="row" layout-align="space-between center">
  <div>{{ 'MUSIC_UPLOAD_PAGE.PREVIEW_LYRICS' | translate }}</div>
  <span flex="flex"></span
  ><button mat-icon-button="" (click)="closeDialog()" [attr.aria-label]="'GLOBAL.CLOSE_LABEL' | translate">
    <i class="pss-icons_11-close"></i>
  </button>
</div>
<mat-dialog-content
  ><div class="audio-title">{{ audio.song_title }}</div>
  <div class="audio-artist">{{ 'GLOBAL.BY_LABEL' | translate }} {{ audio.artist }}</div>
  <div class="audio-lyric" [innerHtml]="audio.lyrics"></div></mat-dialog-content
><mat-dialog-actions layout="row" layout-align="end center"
  ><button
    class="px-btn-paddings"
    mat-button=""
    mat-raised-button=""
    color="primary"
    (click)="closeDialog()"
    tabindex="0"
    [attr.aria-label]="'GLOBAL.OK_LABEL' | translate"
  >
    <span>{{ 'GLOBAL.OK_LABEL' | translate }}</span>
  </button></mat-dialog-actions
>
