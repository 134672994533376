import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {IMdsDialogConfig, MDS_DIALOG_DATA, MdsDialogRef} from '@pui/components/dialog';
import {MdsTabChange, MdsTabsComponent} from '@pui/components/tabs';
import {Audio, AudioCategoryId, CATEGORIES_RATING, IFilter, ISortItem} from '@px/audio-domain';
import {AudioPlayerService, SHARED_AUDIO_PLAYER_SERVICE} from '@px/shared/audio-player';
import {BehaviorSubject, EMPTY, filter, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {AudioBrowserTab} from '../../enums/audio-browser-tab';
import {AudioChangeType} from '../../enums/audio-change-type';
import {IAudioBrowser} from '../../intefaces/audio-browser';
import {IAudioCategoryConfig} from '../../intefaces/audio-category-config';
import {IAudioChangeEvent} from '../../intefaces/audio-change-event';
import {IAudioDialogTabbedData} from '../../intefaces/audio-dialog-tabbed-data';
import {IAudioSelection} from '../../intefaces/audio-selection';
import {FilterParameters} from '../../intefaces/filter-parameters';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {ConfirmDialogReturnValues, IConfirmDialogData} from '../confirm-dialog/confirm-dialog.model';

@UntilDestroy()
@Component({
  selector: 'px-audio-browser-tabbed',
  templateUrl: './audio-browser-tabbed.component.html',
  styleUrls: ['./audio-browser-tabbed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: SHARED_AUDIO_PLAYER_SERVICE, useClass: AudioPlayerService}],
})
export class AudioBrowserTabbedComponent implements IAudioBrowser, OnInit {
  private readonly activeTabInternal$: BehaviorSubject<AudioBrowserTab>;
  readonly AudioBrowserTab = AudioBrowserTab;
  readonly TAB_INDEXES = new Map<AudioBrowserTab, number>([
    [AudioBrowserTab.TOP_SONGS, 0],
    [AudioBrowserTab.MY_UPLOADS, 1],
    [AudioBrowserTab.MY_FAVORITES, 2],
    [AudioBrowserTab.SEARCH, 3],
  ]);
  readonly recentSegmentsCount?: number;
  isUploadsDropzoneOpened = false;
  audioChanged$ = new EventEmitter<Audio[]>();
  searchTableSort$ = new EventEmitter<ISortItem | null>();

  get activeTab(): AudioBrowserTab {
    return this.activeTabInternal$.value;
  }

  get activeTab$(): Observable<AudioBrowserTab> {
    return this.activeTabInternal$.asObservable();
  }

  searchTableFilter$ = new Subject<IFilter>();
  searchTableFilter: IFilter;

  audioChangesEvent$ = new Subject<IAudioChangeEvent>();
  audioChangesEventForTab$: Map<AudioBrowserTab, Observable<IAudioChangeEvent[]>>;

  disabledAudiosIds: number[] = [];
  isSearchBarFocused?: boolean;
  audiosCategoriesConfig: Record<number, IAudioCategoryConfig> = {
    [AudioCategoryId.PARTY_TIME]: {
      order: CATEGORIES_RATING[AudioCategoryId.PARTY_TIME],
      thumbnailImgUrl: 'assets/images/category-thumbnails/01_Party Time.jpg',
    },
    [AudioCategoryId.BEATS]: {
      order: CATEGORIES_RATING[AudioCategoryId.BEATS],
      thumbnailImgUrl: 'assets/images/category-thumbnails/07_Beats.jpg',
    },
    [AudioCategoryId.EMOTIONAL]: {
      order: CATEGORIES_RATING[AudioCategoryId.EMOTIONAL],
      thumbnailImgUrl: 'assets/images/category-thumbnails/06_Emotional.jpg',
    },
    [AudioCategoryId.CALM_MELODIES]: {
      order: CATEGORIES_RATING[AudioCategoryId.CALM_MELODIES],
      thumbnailImgUrl: 'assets/images/category-thumbnails/03_Calm Melodies.jpg',
    },
    [AudioCategoryId.HAPPY]: {
      order: CATEGORIES_RATING[AudioCategoryId.HAPPY],
      thumbnailImgUrl: 'assets/images/category-thumbnails/02_Happy Folk.jpg',
    },
    [AudioCategoryId.EPIC_BEAT_MATCHING]: {
      order: CATEGORIES_RATING[AudioCategoryId.EPIC_BEAT_MATCHING],
      thumbnailImgUrl: 'assets/images/category-thumbnails/Epic_Beat-Matching.jpg',
    },
    [AudioCategoryId.LOVE_SONGS]: {
      order: CATEGORIES_RATING[AudioCategoryId.LOVE_SONGS],
      thumbnailImgUrl: 'assets/images/category-thumbnails/05_Love Songs.jpg',
    },
  };

  get orderedAvailableFilters(): string[] {
    return this.dialogConfig.data?.orderedAvailableFilters ?? [];
  }

  get filterParams(): FilterParameters {
    return this.dialogConfig.data?.filterParams ?? {};
  }

  @ViewChild(MdsTabsComponent) private tabsComponent?: MdsTabsComponent;

  constructor(
    private readonly translate: TranslateService,
    private readonly matDialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MdsDialogRef) private readonly dialogRef: MdsDialogRef<AudioBrowserTabbedComponent, IAudioSelection>,
    @Inject(MDS_DIALOG_DATA) readonly dialogConfig: IMdsDialogConfig<IAudioDialogTabbedData>
  ) {
    if (dialogConfig.data?.selectedAudios$) {
      dialogConfig.data?.selectedAudios$.pipe(untilDestroyed(this)).subscribe(da => (this.disabledAudiosIds = da));
    }

    this.searchTableFilter = this.dialogConfig.data?.searchTableFilter ?? {};

    this.activeTabInternal$ = new BehaviorSubject<AudioBrowserTab>(
      dialogConfig.data?.activeTabIndex ?? AudioBrowserTab.TOP_SONGS
    );

    this.audioChangesEventForTab$ = new Map(
      Array.from(this.TAB_INDEXES.entries()).map(([key]) => {
        return [key, this.createAudioChangesEventForTab$(key)];
      })
    );

    this.recentSegmentsCount = dialogConfig.data?.recentSegmentsCount;
  }

  private createAudioChangesEventForTab$(tab: AudioBrowserTab): Observable<IAudioChangeEvent[]> {
    let cash: IAudioChangeEvent[] = [];
    return this.audioChangesEvent$.pipe(
      switchMap(e => {
        if (this.activeTab !== tab) {
          return of([e]);
        } else {
          //NOTE: when we are on an active tab, we accumulate events and when we leave this tab, we receive those events.
          // It needs for a case when favorite state of track was changed and track shouldn't disappear immediately, only when we leave the tab.
          if ([AudioBrowserTab.TOP_SONGS, AudioBrowserTab.MY_UPLOADS].includes(tab)) {
            //NOTE: for top songs and uploads we don't need events back, so skip it to not do extra work
            return EMPTY;
          }

          if (e.type === AudioChangeType.FAVORITE_CHANGE) {
            const index = cash.findIndex(ce => ce.type === e.type && ce.audio.id === e.audio.id);
            if (index >= 0) {
              cash[index] = e;
            } else {
              cash.push(e);
            }
          }
          return this.activeTabInternal$.pipe(
            filter(t => t !== tab),
            map(() => cash),
            tap(() => (cash = []))
          );
        }
      }),
      filter(e => !!e.length)
    );
  }

  private getTabByIndex(index: number): AudioBrowserTab | undefined {
    for (const [key, value] of this.TAB_INDEXES.entries()) {
      if (value === index) {
        return key as AudioBrowserTab;
      }
    }
    return undefined;
  }

  private openConfirmDialog(audioSelection: IAudioSelection): void {
    const data: IConfirmDialogData = {
      helpText: this.translate.instant('MUSIC_UPLOAD_PAGE.BM_AUDIO_CONFIRMATION_MESSAGE'),
      confirmBtn: this.translate.instant('MUSIC_UPLOAD_PAGE.BM_AUDIO_CONFIRMATION_BUTTON'),
      cancelBtn: this.translate.instant('GLOBAL.CANCEL_LABEL'),
    };

    this.matDialog
      .open<ConfirmDialogComponent, IConfirmDialogData, ConfirmDialogReturnValues>(ConfirmDialogComponent, {
        data,
        panelClass: ['px-dialog', 'px-confirm-dialog'],
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (this.dialogConfig.data?.beatMatchingDialogAction) {
          this.dialogConfig.data.beatMatchingDialogAction(result);
        }

        if (result === ConfirmDialogReturnValues.CONFIRMED) {
          this.dialogRef.close(audioSelection);
        }
      });
  }

  onTabsFadeStateChange(event: Record<number, boolean>): void {
    this.isSearchBarFocused = event[this.TAB_INDEXES.get(AudioBrowserTab.SEARCH) ?? -1];
  }

  onTabChange(event: MdsTabChange): void {
    const tab = this.getTabByIndex(event.index ?? -1);
    if (tab !== undefined) {
      this.activeTabInternal$.next(tab);
    }
  }

  switchTab(tab: AudioBrowserTab): void {
    const index = this.TAB_INDEXES.get(tab);
    if (this.tabsComponent && index !== undefined) {
      this.tabsComponent.setActiveTab(index); //TODO make through prop binding
    }
  }

  switchToSearchByCategory(categoryId: number): void {
    this.switchTab(AudioBrowserTab.SEARCH);

    this.searchTableFilter = {
      category: [categoryId.toString()],
    };
    this.emitChangeFilter(this.searchTableFilter);
  }

  emitChangeFilter(filter: IFilter): void {
    this.searchTableFilter$.next(filter);
  }

  onAudioChanged(audio: Audio): void {
    this.audioChanged$.emit([audio]);
    this.audioChangesEvent$.next({
      audio,
      type: AudioChangeType.CHANGE,
    });
  }

  onAudioDeleted(audio: Audio): void {
    this.audioChangesEvent$.next({
      audio,
      type: AudioChangeType.DELETE,
    });
  }

  onAudioFavoritesChanged(audio: Audio): void {
    this.audioChangesEvent$.next({
      audio,
      type: AudioChangeType.FAVORITE_CHANGE,
    });
  }

  selectAudio(audioSelection: IAudioSelection): void {
    if (this.dialogConfig.data?.showBeatMatchingWarning) {
      this.openConfirmDialog(audioSelection);

      return;
    }

    this.dialogRef.close(audioSelection);
  }

  close(): void {
    this.dialogRef.close();
  }

  toggleDropzone(): void {
    this.isUploadsDropzoneOpened = !this.isUploadsDropzoneOpened;
    if (this.activeTab !== AudioBrowserTab.MY_UPLOADS && this.isUploadsDropzoneOpened) {
      this.switchTab(AudioBrowserTab.MY_UPLOADS);
    }
  }

  ngOnInit(): void {
    this.activeTabInternal$.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
  }
}
