<mds-datatable-container type="ngx" *ngIf="columns.length">
  <ngx-datatable
    (scroll)="scroll($event.offsetY)"
    (sort)="sort($event)"
    (select)="select($event)"
    (activate)="onActivate($event)"
    [columnMode]="columnMode"
    [externalSorting]="hasExternalSorting"
    [headerHeight]="headerHeight"
    [loadingIndicator]="isLoading"
    [messages]="{emptyMessage: ''}"
    [rowHeight]="rowHeight"
    [rows]="dataRows"
    [scrollbarV]="true"
    [sorts]="sortState"
    [selected]="[selected]"
    [rowClass]="getRowClass"
    [reorderable]="false"
    [swapColumns]="false"
    [selectionType]="selectionType!"
    [sortType]="SortType.single"
    [virtualization]="hasVirtualization"
    trackByProp="id"
  >
    <!--    selectionType! - is a cheat for resolving "Type 'SelectionType | undefined' is not assignable to type 'SelectionType". But undefined is expectable type (©"For no selection pass a falsey. Default value: undefined")-->
    <ng-container *ngFor="let item of columns; trackBy: trackBy">
      <ngx-datatable-column
        [name]="item.name"
        [resizeable]="item.resizeable"
        [width]="item.width"
        [prop]="item.prop"
        [cellClass]="item.cellClass"
        [sortable]="item.sortable"
        [headerClass]="item.headerClass"
        [headerTemplate]="headerTemplate"
        [canAutoResize]="item.canAutoResize"
        [frozenRight]="item.frozenRight"
        [frozenLeft]="item.frozenLeft"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ng-container [ngTemplateOutlet]="item.template" [ngTemplateOutletContext]="{row, value}" />
        </ng-template>
      </ngx-datatable-column>
      <ng-template #headerTemplate let-onSort="sortFn" let-sortDir="sortDir">
        <ng-container
          [ngTemplateOutlet]="item.headerTemplate ?? defaultHeaderTemplate"
          [ngTemplateOutletContext]="{$implicit: item.name, sortFn: onSort, sortDir: sortDir}"
        />
      </ng-template>
      <ng-template let-name let-onSort="sortFn" #defaultHeaderTemplate>
        <span
          (click)="onSort()"
          (keydown.enter)="onSort()"
          role="button"
          tabIndex="0"
          class="datatable-header-cell-label"
        >
          {{ name }}
        </span>
      </ng-template>
    </ng-container>
  </ngx-datatable>

  <ng-container *ngIf="data && data.length === 0 && !isLoading" [ngTemplateOutlet]="emptyTemplate" />
</mds-datatable-container>
