import {ChangeDetectionStrategy, Component, Inject, NgModule, ViewEncapsulation} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {ConfirmDialogReturnValues, IConfirmDialogData} from './confirm-dialog.model';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
  sendingData = false;

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dialogData: IConfirmDialogData
  ) {
    this.router.events
      .pipe(
        filter(evt => evt instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.closeDialog(ConfirmDialogReturnValues.ROUTE_UPDATE);
      });
  }

  cancel(): void {
    this.closeDialog(ConfirmDialogReturnValues.CANCEL);
  }

  closeDialog(event?: ConfirmDialogReturnValues): void {
    this.dialogRef.close(event);
  }

  confirm(): void {
    if (this.dialogData.beforeHide) this.dialogData.beforeHide();
    this.closeDialog(ConfirmDialogReturnValues.CONFIRMED);
  }
}

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [TranslateModule, MatButtonModule, MatDialogModule, ReactiveFormsModule, CommonModule],
  exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
