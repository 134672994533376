import {AudioCategory} from '../enums/audio-categoriy.enum';

/** @deprecated used only for legacy compatibility */
export const AUDIO_CATEGORY_TO_ID = new Map<AudioCategory, number>([
  [AudioCategory.BEATS, 4],
  [AudioCategory.USER_AUDIO, 0],
  [AudioCategory.EMOTIONAL, 5],
  [AudioCategory.EPIC_BM, 7],
  [AudioCategory.HAPPY_FOLK, 2],
  [AudioCategory.PARTY_TIME, 1],
  [AudioCategory.CALM_MELODIES, 3],
  [AudioCategory.LOVE_SONGS, 6],
]);

/** @deprecated used only for legacy compatibility */
export const ID_TO_AUDIO_CATEGORY = new Map(
  [...AUDIO_CATEGORY_TO_ID.entries()].map(entry => entry.reverse() as [number, AudioCategory])
);
