import {Directive, Input, TemplateRef} from '@angular/core';
import {ITemplateContext} from '../interfaces/column.interface';
import {nanoid} from 'nanoid';
import {MdsDataTableComponent} from '../components/datatable/datatable.component';

@Directive({
  selector: '[mdsDataTableRegister]',
})
export class MdsDataTableRegisterDirective<T, D> {
  private readonly id = nanoid();

  @Input() set name(value: string) {
    this.mdsDataTableComponent.setProp(this.id, 'name', value);
  }

  @Input() set resizeable(value: boolean) {
    this.mdsDataTableComponent.setProp(this.id, 'resizeable', value);
  }

  @Input() set sortable(value: boolean) {
    this.mdsDataTableComponent.setProp(this.id, 'sortable', value);
  }

  @Input() set width(value: number) {
    this.mdsDataTableComponent.setProp(this.id, 'width', value);
  }

  @Input() set prop(value: string) {
    this.mdsDataTableComponent.setProp(this.id, 'prop', value);
  }

  @Input() set cellClass(value: string) {
    this.mdsDataTableComponent.setProp(this.id, 'cellClass', value);
  }

  @Input() set headerClass(value: string) {
    this.mdsDataTableComponent.setProp(this.id, 'headerClass', value);
  }

  @Input() set headerTemplate(value: TemplateRef<null> | null) {
    this.mdsDataTableComponent.setProp(this.id, 'headerTemplate', value);
  }

  @Input() set order(value: number | string) {
    this.mdsDataTableComponent.setProp(this.id, 'order', Number(value ?? 0));
  }

  @Input() set canAutoResize(value: boolean) {
    this.mdsDataTableComponent.setProp(this.id, 'canAutoResize', value);
  }

  @Input() set frozenRight(value: boolean) {
    this.mdsDataTableComponent.setProp(this.id, 'frozenRight', value);
  }

  @Input() set frozenLeft(value: boolean) {
    this.mdsDataTableComponent.setProp(this.id, 'frozenLeft', value);
  }

  constructor(
    private readonly mdsDataTableComponent: MdsDataTableComponent<T, D>,
    private readonly templateRef: TemplateRef<ITemplateContext<T>>
  ) {
    this.mdsDataTableComponent.initStore(this.id);
    this.mdsDataTableComponent.setProp(this.id, 'template', templateRef);
  }
}
