export enum FilterNames {
  CATEGORY = 'category',
  ENERGY = 'energy',
  BM_TARGET = 'bm_target',
  BM_TARGET_MIN = 'bm_target_min',
  BM_TARGET_MAX = 'bm_target_max',
  INSTRUMENTAL = 'instrumental',
  IS_NEW = 'is_new',
  IS_FAVORITE = 'is_favorite',
}
