import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {forkJoin, Observable, Subscription, tap} from 'rxjs';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AudiosByCategoryState} from '../../intefaces/audios-by-category-state';
import {AudioPlayerControllerService} from '../../services/audio-player-controller/audio-player-controller.service';
import {AudioPlayerService, SHARED_AUDIO_PLAYER_SERVICE} from '@px/shared/audio-player';
import {IAudioCategoryState} from '../../intefaces/audio-category-state';
import {IAudioCategoryConfig} from '../../intefaces/audio-category-config';
import {IHotKeysService, SHARED_HOTKEYS_SERVICE} from '@px/shared/hotkeys';
import {uniq, uniqBy, update} from 'ramda';
import {IAudioChangeEvent} from '../../intefaces/audio-change-event';
import {AudioChangeType} from '../../enums/audio-change-type';
import {instanceToInstance} from 'class-transformer';
import {AudioSelectionSource} from '../../enums/audio-selection-source';
import {IAudioSelection} from '../../intefaces/audio-selection';
import {Audio, AUDIO_FACADE, IAudioFacadeService, IAudioParams, OrderDirection, RandomSortProp} from '@px/audio-domain';

type ShiftingDirection = 'right' | 'left';

@UntilDestroy()
@Component({
  selector: 'px-top-songs',
  templateUrl: './top-songs.component.html',
  styleUrls: ['./top-songs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: SHARED_AUDIO_PLAYER_SERVICE, useClass: AudioPlayerService}, AudioPlayerControllerService],
  animations: [
    trigger('shifting', [
      state('idle', style({transform: 'translateX(-334px)'})),
      state('left', style({transform: 'translateX(0px)'})),
      state('right', style({transform: 'translateX(-668px)'})),
      transition('idle => *', animate('300ms ease-out')),
      transition('* => idle', animate(0)),
    ]),
  ],
})
export class TopSongsComponent implements OnInit {
  private readonly DEFAULT_CATEGORY_STATE: IAudioCategoryState = {
    currentPage: 0,
    audios: [],
    favorites: [],
  };
  private audioChangesEventSub?: Subscription;
  readonly AudioSelectionSource = AudioSelectionSource;

  @Input() set isPlayerActive(value: boolean) {
    this.audioPlayerController.isPlayerActive = value;
  }
  @Input() tracksPerPage = 10;
  @Input() uploadedImagesCount: number | null = 0;
  @Input() categoriesConfig: Record<number, IAudioCategoryConfig> = {};
  @Input() fadedAudios: number[] = [];
  @Input() set audioChangesEvent$(obs$: Observable<IAudioChangeEvent[]> | undefined) {
    this.updateAudioChangedEventsSub(obs$);
  }
  @Output() selectAudio$ = new EventEmitter<IAudioSelection>();
  @Output() categoryClicked$ = new EventEmitter<number>();
  @Output() audioFavoriteChanged$ = new EventEmitter<Audio>();

  topSongsCategories: number[] = [];
  categoriesShiftedOrder: number[] = [];
  audiosByCategoryState: AudiosByCategoryState = {};
  isShiftLeftShown = false;
  shiftingState: ShiftingDirection | 'idle' = 'idle';
  shiftedCategoriesCount = 0;
  isContentShown = false;
  isLoading = true;

  constructor(
    @Inject(AUDIO_FACADE) private readonly audioService: IAudioFacadeService,
    @Inject(SHARED_HOTKEYS_SERVICE) private readonly hotKeysService: IHotKeysService,
    private readonly cdr: ChangeDetectorRef,
    readonly audioPlayerController: AudioPlayerControllerService
  ) {
    this.hotKeysService
      .like()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.audioPlayerController.playingAudio) {
          this.toggleFavorite(this.audioPlayerController.playingAudio);
        }
      });
  }

  private updateAudioChangedEventsSub(obs$: Observable<IAudioChangeEvent[]> | undefined): void {
    if (this.audioChangesEventSub) {
      this.audioChangesEventSub.unsubscribe();
    }
    if (obs$) {
      this.audioChangesEventSub = obs$.pipe(untilDestroyed(this)).subscribe(e => this.updateAudiosFromEvents(e));
    }
  }

  private updateAudiosFromEvents(events: IAudioChangeEvent[]): void {
    for (const e of events) {
      this.updateAudioFromEvent(e);
    }
  }

  private updateAudioFromEvent(event: IAudioChangeEvent): void {
    const {type} = event;
    const audio = instanceToInstance(event.audio);
    const {category_id} = audio;

    if (!this.topSongsCategories.includes(category_id)) {
      return;
    }

    switch (type) {
      case AudioChangeType.ADD: {
        let audios = this.audiosByCategoryState[category_id]?.audios ?? [];
        audios = uniqBy(a => a.id, [audio, ...audios]);
        this.updateAudioCategoryState(category_id, {audios});
        break;
      }
      case AudioChangeType.CHANGE: {
        let audios = this.audiosByCategoryState[category_id]?.audios ?? [];
        const index = audios.findIndex(a => a.id === audio.id);
        if (index >= 0) {
          audios = update(index, audio, audios);
          this.updateAudioCategoryState(category_id, {audios});
        }
        if (audio.id === this.audioPlayerController.playingAudio?.id) {
          this.audioPlayerController.updateIfPlayingAudio(audio);
        }
        break;
      }
      case AudioChangeType.DELETE: {
        const audios = (this.audiosByCategoryState[category_id]?.audios ?? []).filter(a => a.id !== audio.id);
        this.updateAudioCategoryState(category_id, {audios});
        if (audio.id === this.audioPlayerController.playingAudio?.id) {
          this.audioPlayerController.dispose();
        }
        break;
      }
      case AudioChangeType.FAVORITE_CHANGE: {
        let audios = this.audiosByCategoryState[category_id]?.audios ?? [];
        let favorites = this.audiosByCategoryState[category_id]?.favorites ?? [];
        const index = audios.findIndex(a => a.id === audio.id);

        if (index >= 0) {
          audios = update(index, audio, audios);
        }
        if (audio.is_favorite) {
          favorites = uniq([audio.id, ...favorites]);
        } else {
          favorites = favorites.filter(f => f !== audio.id);
        }

        this.audioPlayerController.updateIfPlayingAudio(audio);
        this.updateAudioCategoryState(category_id, {favorites, audios});
        break;
      }
      default:
        break;
    }
  }

  private updateAudioCategoryState(categoryId: number, newState: Partial<IAudioCategoryState>): void {
    const prevState = this.audiosByCategoryState[categoryId] ?? this.DEFAULT_CATEGORY_STATE;
    this.audiosByCategoryState[categoryId] = {
      ...prevState,
      ...newState,
    };
  }

  private loadNextPage(categoryId: number): Observable<void> {
    return this.sortAudiosByBMTarget(this.uploadedImagesCount, categoryId);
  }

  private sortAudiosByBMTarget(bmTarget: number | null, categoryId: number): Observable<void> {
    const nextPage = (this.audiosByCategoryState[categoryId]?.currentPage ?? 0) + 1;

    const defaultParams: IAudioParams = {
      filter: {
        category: [categoryId.toString()],
      },
      page: nextPage,
      size: this.tracksPerPage,
      exclude: this.audiosByCategoryState[categoryId]?.audios.map(a => a.id) ?? [],
      sort: {
        prop: RandomSortProp.VALUE,
        dir: OrderDirection.ASC,
      },
      queryCategory: 'TOP_SONGS',
    };

    const bmTargetParams =
      bmTarget && bmTarget > 0 && this.audioService.isInsideBMTargetRange(bmTarget)
        ? ({
            searchString: `${bmTarget}`,
            ...defaultParams,
          } as IAudioParams)
        : defaultParams;

    return this.audioService.find(bmTargetParams).pipe(
      untilDestroyed(this),
      map(([audios]) => Array.from(new Set([...audios])).slice(0, this.tracksPerPage)),
      tap(audios => {
        const prevState = this.audiosByCategoryState[categoryId] ?? this.DEFAULT_CATEGORY_STATE;

        this.updateAudioCategoryState(categoryId, {
          currentPage: nextPage,
          audios: [...(prevState?.audios ?? []), ...audios],
        });
      }),
      tap(() => this.updatePlayerQueue()),
      map(() => undefined)
    );
  }

  private loadFavorites(): Observable<void> {
    return this.audioService
      .find({
        filter: {
          is_favorite: true,
        },
        sort: {
          prop: 'song_title',
          dir: OrderDirection.ASC,
        },
        queryCategory: 'TOP_SONGS',
      })
      .pipe(
        tap(([audios]) => {
          for (const a of audios) {
            const categoryId = a.category_id;
            const prevState = this.audiosByCategoryState[categoryId] ?? this.DEFAULT_CATEGORY_STATE;

            if (a.is_favorite) {
              this.updateAudioCategoryState(categoryId, {
                favorites: [...prevState.favorites, a.id],
              });
            }
          }
        }),
        map(() => undefined),
        untilDestroyed(this)
      );
  }

  private updatePlayerQueue(): void {
    this.audioPlayerController.add(
      this.topSongsCategories.reduce((acc, c) => {
        return [...acc, ...(this.audiosByCategoryState[c]?.audios ?? [])];
      }, [] as Audio[])
    );
  }

  ngOnInit(): void {
    const topSongsCategories = Object.keys(this.categoriesConfig)
      .map(k => Number(k))
      .sort((a, b) => (this.categoriesConfig[a].order ?? 0) - (this.categoriesConfig[b].order ?? 0));

    if (topSongsCategories.length) {
      this.topSongsCategories = [topSongsCategories[topSongsCategories.length - 1], ...topSongsCategories.slice(0, -1)];
      this.updateCategoriesShiftingOrder();
      this.isLoading = true;
      forkJoin([...this.topSongsCategories.map(c => this.loadNextPage(c)), this.loadFavorites()])
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        });
      this.audioPlayerController.stateChanged$.subscribe(() => this.cdr.detectChanges());
    }
  }

  selectAudio(audio: Audio, source: AudioSelectionSource): void {
    this.selectAudio$.next({audio, source});
  }

  startShiftingRight(): void {
    this.isShiftLeftShown = true;
    this.shiftingState = 'right';
  }

  startShiftLeft(): void {
    this.shiftingState = 'left';
  }

  shiftingDone(event: AnimationEvent): void {
    if (event.toState === 'right' || event.toState === 'left') {
      this.shiftingState = 'idle';
      this.updateCategoriesShiftingOrder(event.toState);
      this.cdr.detectChanges();
    }
  }

  updateCategoriesShiftingOrder(shifting?: ShiftingDirection): void {
    if (shifting === 'right') {
      this.shiftedCategoriesCount++;
    } else if (shifting === 'left') {
      this.shiftedCategoriesCount--;
    }

    if (Math.abs(this.shiftedCategoriesCount) >= this.topSongsCategories.length) {
      this.categoriesShiftedOrder = [];
      this.shiftedCategoriesCount = 0;
    }

    for (const [index] of this.topSongsCategories.entries()) {
      if (shifting === 'right') {
        if (this.shiftedCategoriesCount <= 0) {
          this.categoriesShiftedOrder[index] =
            index === this.topSongsCategories.length - 1 + this.shiftedCategoriesCount
              ? index
              : this.categoriesShiftedOrder[index] ?? index;
        } else {
          this.categoriesShiftedOrder[index] =
            index === this.shiftedCategoriesCount - 1
              ? this.topSongsCategories.length + this.shiftedCategoriesCount
              : this.categoriesShiftedOrder[index];
        }
      } else if (shifting === 'left') {
        if (this.shiftedCategoriesCount >= 0) {
          this.categoriesShiftedOrder[index] =
            index === this.shiftedCategoriesCount ? index : this.categoriesShiftedOrder[index] ?? index;
        } else {
          this.categoriesShiftedOrder[index] =
            index === this.topSongsCategories.length + this.shiftedCategoriesCount
              ? this.shiftedCategoriesCount
              : this.categoriesShiftedOrder[index];
        }
      } else {
        this.categoriesShiftedOrder[index] = this.categoriesShiftedOrder[index] ?? index;
      }
    }
  }

  toggleFavorite(audio: Audio): void {
    const {category_id} = audio;
    const prevState = this.audiosByCategoryState[category_id];
    const audioIndex = prevState.audios.findIndex(a => a.id === audio.id);
    let newAudio: Audio;

    if (audio.is_favorite) {
      newAudio = audio.removeFromFavorites();
      this.updateAudioCategoryState(category_id, {
        favorites: prevState.favorites.filter(f => f !== newAudio.id),
        audios: update(audioIndex, newAudio, prevState.audios),
      });

      this.audioService.removeFromFavorites(audio.id).pipe(untilDestroyed(this)).subscribe();
    } else {
      newAudio = audio.markAsFavorite();
      this.updateAudioCategoryState(category_id, {
        favorites: [...prevState.favorites, newAudio.id],
        audios: update(audioIndex, newAudio, prevState.audios),
      });
      this.audioService.markAsFavorite(audio.id).pipe(untilDestroyed(this)).subscribe();
    }
    this.audioFavoriteChanged$.emit(newAudio);
    this.audioPlayerController.updateIfPlayingAudio(newAudio);
  }

  onCategoryScrollEnd(categoryId: number): void {
    this.loadNextPage(categoryId).subscribe(() => this.cdr.detectChanges());
  }

  onCategoryThumbnailClick(id: number): void {
    this.categoryClicked$.emit(id);
  }
}
