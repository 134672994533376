import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, booleanAttribute} from '@angular/core';
import {
  FileFactoryService,
  FileValidatorFunction,
  FileValidatorService,
  FileValidators,
  PxFile,
} from '@px/shared-data-access-file-upload';
import {head} from 'ramda';
import {ErrorType} from './error-type';
import {IFileDragSelectorConfig} from './file-drag-selector-config';
import {IFileHandle} from './file-handle';
import {IValidateError} from './validate-error';
import {IValidateResult} from './validate-result';

@Directive({
  selector: '[pxFileDragSelector]',
})
export class FileDragSelectorDirective {
  private input?: HTMLInputElement;

  @Output() files = new EventEmitter<IFileHandle[]>();
  @Output() errors = new EventEmitter<void>();

  @Input() config?: IFileDragSelectorConfig;
  @Input({transform: booleanAttribute}) clickable = true;

  constructor(
    private pxFileFactory: FileFactoryService,
    private fileValidator: FileValidatorService,
    private elementRef: ElementRef
  ) {}

  private processing(data: FileList): void {
    const files: IFileHandle[] = [];

    for (let i = 0; i < data.length; i++) {
      const rawFile = data[i];
      const file = this.pxFileFactory.create(rawFile);
      const validate = this.validate(file);

      files.push({file, isValid: validate.valid, error: head(validate.errors)?.type});
    }

    this.files.emit(files);
  }

  private validate(file: PxFile): IValidateResult {
    const validators: FileValidatorFunction<ErrorType>[] = [];

    if (this.config?.maxUploadFileSize) {
      validators.push(FileValidators.fileSize(this.config.maxUploadFileSize, ErrorType.SIZE));
    }

    if (this.config?.availableFileType) {
      validators.push(FileValidators.fileType(this.config.availableFileType, ErrorType.TYPE));
    }

    const errors: IValidateError[] =
      this.fileValidator
        .validate(file, validators)
        ?.map(e => {
          return {
            file: file.name,
            type: e,
          };
        })
        .filter(ve => ve.type) ?? [];
    return {
      valid: !errors.length,
      errors: errors,
    } as IValidateResult;
  }

  @HostListener('click', ['$event']) onClick(event: Event): void {
    if (!this.clickable) return;
    event.preventDefault();
    event.stopPropagation();
    this.inputClick();
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.classList.add('is-dragged');
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.classList.remove('is-dragged');
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.classList.remove('is-dragged');
    if (event.dataTransfer) {
      this.processing(event.dataTransfer.files);
    }
  }

  inputClick(): void {
    this.input = document.createElement('input');
    this.input.type = 'file';

    if (this.config?.multiple === undefined || this.config?.multiple) {
      this.input.multiple = true;
    }

    this.input.onchange = (event: Event): void => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        this.processing(target.files);
      }
    };
    this.input.click();
  }
}
