import {Inject, Injectable} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {IPlayerEvent} from '../../intefaces/player-event.interface';
import {IAudioPlayerService, IPlayable, SHARED_AUDIO_PLAYER_SERVICE} from '@px/shared/audio-player';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {PLAYER_EVENT_TYPE} from '../../enums/player-event.enum';
import {DatePipe} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IHotKeysService, SHARED_HOTKEYS_SERVICE} from '@px/shared/hotkeys';
import {Audio} from '@px/audio-domain';

@UntilDestroy()
@Injectable()
export class AudioPlayerControllerService {
  private static readonly PLAYER_EVENT_DEBOUNCE_MS = 500;

  private playerEvent$ = new Subject<IPlayerEvent>();
  private progressSubscription?: Subscription;
  private playingAudioInternal?: Audio;
  private stateChangedInternal$ = new Subject<void>();
  private isPlayerActiveInternal = false;

  favoriteChanged$ = new Subject<Audio>();

  set isPlayerActive(value: boolean) {
    this.isPlayerActiveInternal = value;
    if (!value && this.isAudioPlaying) {
      this.audioPlayer.pause();
    }
  }

  get playingAudio(): Audio | undefined {
    return this.playingAudioInternal;
  }

  get playingProgress(): number {
    return this.audioPlayer.progress();
  }

  get isAudioPlaying(): boolean {
    return !!this.playingAudioInternal && this.audioPlayer.isPlaying(this.playingAudioInternal.id);
  }

  get isAudioLoading(): boolean {
    return !!this.playingAudioInternal && this.audioPlayer.isLoading(this.playingAudioInternal.id);
  }

  get stateChanged$(): Observable<void> {
    return this.stateChangedInternal$.asObservable();
  }

  get playPositionInfo(): string {
    const id = this.playingAudioInternal?.id;

    if (!id) {
      return '';
    }

    return this.datePipe.transform(this.audioPlayer.seek() * 1000, 'mm:ss') ?? '';
  }

  constructor(
    @Inject(SHARED_AUDIO_PLAYER_SERVICE) private readonly audioPlayer: IAudioPlayerService,
    @Inject(SHARED_HOTKEYS_SERVICE) private readonly hotKeysService: IHotKeysService,
    private readonly datePipe: DatePipe
  ) {
    this.audioPlayer.hasChanges$.pipe(untilDestroyed(this)).subscribe(this.stateChangedInternal$);

    this.playerEvent$
      .pipe(
        debounceTime(AudioPlayerControllerService.PLAYER_EVENT_DEBOUNCE_MS),
        tap(({audio, type}: IPlayerEvent) => {
          if (type === PLAYER_EVENT_TYPE.PLAY && audio) {
            if (this.playingAudioInternal?.id !== audio.id) {
              this.playingAudioInternal = audio;
              this.updateProgressSubscription();
            }
            this.audioPlayer.play(audio);
            this.stateChangedInternal$.next();
          } else if (type === PLAYER_EVENT_TYPE.PAUSE) {
            this.audioPlayer.pause();
          }
        })
      )
      .subscribe();
    this.initHotKeys();
  }

  private updateProgressSubscription(): void {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
    if (this.playingAudioInternal) {
      this.progressSubscription = this.audioPlayer
        .getProgress(this.playingAudioInternal.id)
        .pipe(
          map(() => undefined),
          untilDestroyed(this)
        )
        .subscribe(this.stateChangedInternal$);
    }
  }

  initHotKeys(): void {
    this.hotKeysService
      .play()
      .pipe(
        filter(() => this.isPlayerActiveInternal),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.playingAudio) {
          if (this.isAudioPlaying) {
            this.pause();
          } else {
            this.playAudio(this.playingAudio);
          }
        }
      });

    this.hotKeysService
      .arrowLeft()
      .pipe(
        filter(() => this.isPlayerActiveInternal),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.playingAudio && this.isAudioPlaying) {
          this.seek(this.playingProgress / 100 - 0.025);
        }
      });

    this.hotKeysService
      .arrowRight()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.playingAudio && this.isAudioPlaying) {
          this.seek(this.playingProgress / 100 + 0.025);
        }
      });

    this.hotKeysService
      .shiftArrowRight()
      .pipe(
        filter(() => this.isPlayerActiveInternal),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.playNext();
      });

    this.hotKeysService
      .shiftArrowLeft()
      .pipe(
        filter(() => this.isPlayerActiveInternal),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.playPrev();
      });
  }

  updateIfPlayingAudio(audio: Audio): void {
    if (this.playingAudioInternal && this.playingAudioInternal.id === audio.id) {
      this.playingAudioInternal = audio;
      this.stateChangedInternal$.next();
    }
  }

  playAudio(audio: Audio): void {
    if (this.isPlayerActiveInternal) {
      this.playerEvent$.next({type: PLAYER_EVENT_TYPE.PLAY, audio});
    }
  }

  pause(): void {
    if (this.isPlayerActiveInternal) {
      this.playerEvent$.next({type: PLAYER_EVENT_TYPE.PAUSE});
    }
  }

  dispose(): void {
    this.playingAudioInternal = undefined;
    this.audioPlayer.dispose();
    this.stateChangedInternal$.next();
  }

  playNext(): void {
    if (this.isPlayerActiveInternal) {
      const next = this.audioPlayer.playNext();

      if (next) {
        this.playingAudioInternal = next as Audio;
        this.updateProgressSubscription();
      }
    }
  }

  playPrev(): void {
    if (this.isPlayerActiveInternal) {
      const next = this.audioPlayer.playPrev();

      if (next) {
        this.playingAudioInternal = next as Audio;
        this.updateProgressSubscription();
      }
    }
  }

  seek(seek: number): void {
    if (this.isPlayerActiveInternal) {
      this.audioPlayer.setSeek(seek);
    }
  }

  add(items: IPlayable[]): void {
    this.audioPlayer.add(items);
  }

  toggleFavorite(): void {
    if (this.playingAudioInternal) {
      if (this.playingAudioInternal.is_favorite) {
        this.playingAudioInternal.removeFromFavorites();
      } else {
        this.playingAudioInternal.markAsFavorite();
      }
      this.favoriteChanged$.next(this.playingAudioInternal);
    }
  }
}
