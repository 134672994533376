import {ChangeDetectorRef, Inject, LOCALE_ID, Optional, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {formatNumber} from '@angular/common';

@UntilDestroy()
@Pipe({
  name: 'slidePerSecondFormat',
  pure: false,
})
export class SlidePerSecondFormatPipe implements PipeTransform {
  private static frameToS(value: number): number {
    return value / 60;
  }

  constructor(
    private readonly translate: TranslateService,
    @Inject(LOCALE_ID) private locale: string,
    @Optional() private readonly cdr?: ChangeDetectorRef
  ) {
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => this.cdr?.detectChanges());
  }

  private showSlideTempo(audioLength: number, matchesImages: number): string {
    return String(formatNumber(SlidePerSecondFormatPipe.frameToS(audioLength / matchesImages), this.locale, '1.1-1'));
  }

  transform(value: number, length: number): string {
    return `(${this.showSlideTempo(length, value)} ${this.translate.instant('s/slide')})`;
  }
}
