import {IPSSTemplate} from '../../../../../models/pss-template';

export interface TPPData {
  templates: IPSSTemplate[];
  primaryBtn: TPPPrimaryBtn;
}

export interface TPPPrimaryBtn {
  templateId: number;
  type: TPPPrimaryBtnType;
}

export enum TPPPrimaryBtnType {
  TRIM = 1,
  ADD_IMAGES,
  MOVING,
  DELETE,
}
