<ng-template>
  <div
    class="c-hint"
    [style.transformOrigin]="transformOrigin"
    [@animationTransition]="animationState"
    [ngClass]="{
      'c-hint--d': type === UI_HINT_TYPE.default,
      'c-hint--left': position === UI_HINT_POSITION.left,
      'c-hint--right': position === UI_HINT_POSITION.right,
      'c-hint--top': position === UI_HINT_POSITION.top,
      'c-hint--bottom': position === UI_HINT_POSITION.bottom,
      'c-hint--left-top': position === UI_HINT_POSITION.leftTop,
      'c-hint--left-bottom': position === UI_HINT_POSITION.leftBottom,
      'c-hint--right-top': position === UI_HINT_POSITION.rightTop,
      'c-hint--right-bottom': position === UI_HINT_POSITION.rightBottom,
      'c-hint--top-left': position === UI_HINT_POSITION.topLeft,
      'c-hint--top-right': position === UI_HINT_POSITION.topRight,
      'c-hint--bottom-left': position === UI_HINT_POSITION.bottomLeft,
      'c-hint--bottom-right': position === UI_HINT_POSITION.bottomRight
    }"
    [ngStyle]="width ? {'--mds-hint-width': width + 'px'} : {}"
    [style]="style"
    [style.--mds-comp-hint-pointer-offset.px]="pointerOffset"
  >
    <div class="c-hint__body" [ngClass]="{'c-hint__body--wrap-balancer': mdsHintWrapBalancer}">
      <ng-container *ngIf="!mdsHintWrapBalancer; else withWrapBalancerTpl">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </ng-container>
      <ng-template #withWrapBalancerTpl>
        <mds-wrap-balancer>
          <ng-template [ngTemplateOutlet]="content"></ng-template>
        </mds-wrap-balancer>
      </ng-template>
      <ng-template #content>
        <ng-content></ng-content>
      </ng-template>
    </div>
  </div>
</ng-template>
