import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TempoTypeId} from '@px/audio-domain';

@Component({
  selector: 'px-audio-tempo-info',
  templateUrl: './audio-tempo-info.component.html',
  styleUrls: ['./audio-tempo-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioTempoInfoComponent {
  @Input() upTempo: number | null = null;
  @Input() downTempo: number | null = null;

  @Input() length = 0;
  @Input() showFullInfo = false;

  tempoType = TempoTypeId;
}
