import {inject, Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {IAudiosQueryArguments} from '../entities/gql-schema-types/audios-query-arguments';
import {filter, from, mergeMap, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IAudioGqlData} from '../entities/interfaces/audio-gql-data.interface';
import {IAudiosPaginationGQL} from '../entities/gql-schema-types/audios-pagination-gql';
import {IAudioUpdatableGQL} from '../entities/interfaces/audio-updatable-gql';
import {IAudioTemplateGQL} from '../entities/gql-schema-types/audio-template-gql';
import {IAudioGQL} from '../entities/gql-schema-types/audio-gql';

@Injectable()
export class AudioGqlDataService implements IAudioGqlData {
  private readonly apollo = inject(Apollo);
  private readonly audiosQuery = gql<{audios: IAudiosPaginationGQL}, IAudiosQueryArguments>`
    query Audios(
      $order: [AudiosOrderInput!]
      $pagination: OffsetPaginationInput
      $isNew: Boolean
      $isFavorite: Boolean
      $isInstrumental: Boolean
      $hasLyrics: Boolean
      $upTempo: TempoFilterInput
      $downTempo: TempoFilterInput
      $energy: AudioEnergies
      $energies: [AudioEnergies!]
      $category: AudioCategories
      $categories: [AudioCategories!]
      $excludeByIds: [String!]
      $songTitleContains: String
      $artistOrSongTitleContains: String
    ) {
      audios(
        order: $order
        pagination: $pagination
        isNew: $isNew
        isFavorite: $isFavorite
        isInstrumental: $isInstrumental
        hasLyrics: $hasLyrics
        upTempo: $upTempo
        downTempo: $downTempo
        energy: $energy
        energies: $energies
        category: $category
        categories: $categories
        excludeByIds: $excludeByIds
        songTitleContains: $songTitleContains
        artistOrSongTitleContains: $artistOrSongTitleContains
      ) {
        nodes {
          id
          artist
          length
          songTitle
          oggAudioFileUrl
          mp3AudioFileUrl
          waveform
          enabled
          categoryId
          category
          energyId
          energy
          genre
          instrumental
          lyrics
          expiresAt
          provider
          roundAdded
          isFavorite
          upTempo
          downTempo
          createdByPixellu
          recentUsageOrder
          recentlyUsedAt
          createdAt
          updatedAt
          picId
          originalAudioFileUrl
          originalAudioFileName
        }
        pageInfo {
          take
          offset
          totalCount
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `;
  private readonly updateAudioMutation = gql<{updateAudio: IAudioGQL}, {input: IAudioUpdatableGQL}>`
    mutation UpdateAudio($input: UpdateAudioInput!) {
      updateAudio(input: $input) {
        id
        artist
        length
        songTitle
        oggAudioFileUrl
        mp3AudioFileUrl
        waveform
        enabled
        categoryId
        category
        energyId
        energy
        genre
        instrumental
        lyrics
        expiresAt
        provider
        roundAdded
        isFavorite
        upTempo
        downTempo
        createdByPixellu
        recentUsageOrder
        recentlyUsedAt
        createdAt
        updatedAt
        picId
        originalAudioFileUrl
        originalAudioFileName
      }
    }
  `;
  private readonly audioTemplatesQuery = gql<{audio: {audioTemplates: IAudioTemplateGQL[]} | null}, {id: string}>`
    query Audio($id: ID!) {
      audio(id: $id) {
        audioTemplates {
          id
          picId
          name
          versionNumber
          matchingImages
          tempoType
          isCustom
          transitions {
            duration
            median
            type
          }
        }
      }
    }
  `;
  private readonly createAudioMutation = gql<
    {createAudio: IAudioGQL},
    {input: {s3Key: string; s3UploadPolicy: string; s3UploadSignature: string}}
  >`
    mutation CreateAudio($input: CreateAudioInput!) {
      createAudio(input: $input) {
        id
        artist
        length
        songTitle
        oggAudioFileUrl
        mp3AudioFileUrl
        waveform
        enabled
        categoryId
        category
        energyId
        energy
        genre
        instrumental
        lyrics
        expiresAt
        provider
        roundAdded
        isFavorite
        upTempo
        downTempo
        createdByPixellu
        recentUsageOrder
        recentlyUsedAt
        createdAt
        updatedAt
        picId
        originalAudioFileUrl
        originalAudioFileName
      }
    }
  `;
  private readonly deleteAudioMutation = gql<{deleteAudio: Pick<IAudioGQL, 'id'>}, {input: {id: string}}>`
    mutation DeleteAudio($input: DeleteAudioInput!) {
      deleteAudio(input: $input) {
        id
      }
    }
  `;

  private clearApolloCache(): Observable<void> {
    return from(this.apollo.client.cache.reset());
  }

  createAudio(s3Key: string, s3UploadPolicy: string, s3UploadSignature: string): Observable<IAudioGQL> {
    return this.clearApolloCache().pipe(
      mergeMap(() =>
        this.apollo.mutate({
          mutation: this.createAudioMutation,
          variables: {input: {s3Key, s3UploadPolicy, s3UploadSignature}},
        })
      ),
      filter(response => !!response.data),
      map(response => (response.data as {createAudio: IAudioGQL}).createAudio)
    );
  }

  getAudios(variables: IAudiosQueryArguments): Observable<IAudiosPaginationGQL> {
    return this.apollo
      .query({
        query: this.audiosQuery,
        variables,
      })
      .pipe(
        filter(response => !!response.data),
        map(response => response.data.audios)
      );
  }

  updateAudio(input: IAudioUpdatableGQL): Observable<IAudioGQL> {
    return this.clearApolloCache().pipe(
      mergeMap(() =>
        this.apollo.mutate({
          mutation: this.updateAudioMutation,
          variables: {input},
        })
      ),
      filter(response => !!response.data),
      map(response => (response.data as {updateAudio: IAudioGQL}).updateAudio)
    );
  }

  getAudioTemplates(id: string): Observable<IAudioTemplateGQL[]> {
    return this.apollo
      .query({
        query: this.audioTemplatesQuery,
        variables: {id},
      })
      .pipe(
        filter(response => !!response.data),
        map(response => response.data.audio?.audioTemplates ?? [])
      );
  }

  deleteAudio(id: string): Observable<void> {
    return this.clearApolloCache().pipe(
      mergeMap(() =>
        this.apollo.mutate({
          mutation: this.deleteAudioMutation,
          variables: {input: {id}},
        })
      ),
      filter(response => !!response.data),
      map(() => undefined)
    );
  }
}
