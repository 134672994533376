import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MdsIconComponent} from '@pui/components/icon-core';
import {MDS_CHIP_TYPE} from './chip.types';

@Component({
  selector: 'mds-chip',
  standalone: true,
  imports: [NgIf, NgClass, NgTemplateOutlet, MdsIconComponent],
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsChipComponent {
  @Output() remove$ = new EventEmitter<boolean>();

  @Input() type: MDS_CHIP_TYPE = 'base';
  @Input() size: 'sm' | 'md' = 'md';
  @Input({transform: booleanAttribute}) hasIcon = false;
  @Input({transform: booleanAttribute}) removable = true;

  remove(): void {
    this.remove$.emit(true);
  }
}
