import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Audio} from '@px/audio-domain';

@Component({
  selector: 'px-top-songs-category',
  templateUrl: './top-songs-category.component.html',
  styleUrls: ['./top-songs-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSongsCategoryComponent {
  @Input() id?: number;
  @Input() coverUrl?: string;
  @Input() playingAudioId?: number;
  @Input() isAudioLoading?: boolean;
  @Input() isAudioPlaying?: boolean;
  @Input() audios?: Audio[];
  @Input() favoritesCount = 0;
  @Input() fadedAudiosIds: number[] = [];

  @Output() playAudio$ = new EventEmitter<Audio>();
  @Output() pause$ = new EventEmitter<void>();
  @Output() scrollEnd$ = new EventEmitter<void>();
  @Output() favoriteChange$ = new EventEmitter<Audio>();
  @Output() select$ = new EventEmitter<Audio>();
  @Output() thumbnailClicked$ = new EventEmitter<void>();

  get categorySubtitle(): string {
    return this.favoritesCount ? `${this.favoritesCount} ${this.translate.instant('favorites')}` : '';
  }

  constructor(private readonly translate: TranslateService) {}

  isAudioFaded(id: number): boolean {
    return this.fadedAudiosIds.includes(id);
  }

  onAudioItemClick(audio: Audio): void {
    if (audio.id !== this.playingAudioId) {
      this.playAudio(audio);
    }
  }

  onFavoriteChange(audio: Audio): void {
    this.favoriteChange$.emit(audio);
  }

  playAudio(audio: Audio): void {
    this.playAudio$.emit(audio);
  }

  pause(): void {
    this.pause$.emit();
  }

  audioKey(index: number, audio: Audio): number {
    return audio.id;
  }

  checkAndEmitScrollEnd(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      this.scrollEnd$.emit();
    }
  }

  select(audio: Audio): void {
    this.select$.next(audio);
  }

  onThumbnailClick(): void {
    this.thumbnailClicked$.emit();
  }
}
