<ng-container *ngIf="audio">
  <div class="c-player">
    <div class="c-player__inner">
      <div class="c-player__title">
        <px-audio-title [title]="audio.song_title" [artist]="audio.artist" />
      </div>
      <div class="c-player__controls">
        <div class="c-player__control">
          <px-button (click)="prev()" [puiTooltip]="prevTooltip" size="md" type="fabNaked">
            <mds-icon icon="pxSkipPrevious" />
          </px-button>
        </div>
        <div class="c-player__control">
          <px-audio-player-control
            [isPlaying]="isPlaying"
            [isLoading]="isLoading"
            size="lg"
            (play$)="play()"
            (pause$)="pause()"
          />
        </div>
        <div class="c-player__control">
          <px-button (click)="next()" [puiTooltip]="nextTooltip" size="md" type="fabNaked">
            <mds-icon icon="pxSkipNext" />
          </px-button>
        </div>
      </div>
      <div class="c-player__progress">
        <div class="c-player__progress-line">
          <mds-waveform (seek$)="onSeek($event)" [points]="audio.waveform_data" [progress]="progress" hasProgress />
        </div>
        <div class="c-player__progress-right" [innerHtml]="audioProgress"></div>
      </div>
      <div class="c-player__actions">
        <div class="c-player__action">
          <px-button
            (click)="toggleFavorite()"
            [active]="audio.is_favorite"
            [puiTooltip]="tooltipFavorite"
            type="fabNaked"
          >
            <mds-icon *ngIf="!audio.is_favorite; else favoriteTpl" icon="pxFavoriteBorder" />
            <ng-template #favoriteTpl>
              <mds-icon icon="pxFavorite" />
            </ng-template>
          </px-button>
        </div>
        <div class="c-player__action">
          <px-button (click)="select()" [puiTooltip]="'Add song to segment' | translate" type="fabPrimary">
            <mds-icon icon="pxAdd" />
          </px-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
