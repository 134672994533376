import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AudiosPageFetcher} from '../../intefaces/audios-page-fetcher';
import {BehaviorSubject, EMPTY, Observable, Subject, switchMap} from 'rxjs';
import {instanceToInstance} from 'class-transformer';
import {uniqBy} from 'ramda';
import {map, tap} from 'rxjs/operators';
import {IAudioTableState} from '../../intefaces/audio-table-state';
import {Audio, ISortItem, OrderDirection} from '@px/audio-domain';

@UntilDestroy()
@Injectable()
export class AudioTableControllerService implements IAudioTableState {
  private readonly stateChangedInternal$: BehaviorSubject<Partial<IAudioTableState>>;
  private readonly favoriteChangedInternal$ = new Subject<Audio>();
  private readonly audioChangedInternal$ = new Subject<Audio>();
  private readonly audioDeletedInternal$ = new Subject<Audio>();
  private readonly selectInternal$ = new Subject<Audio>();
  private readonly changeSort$ = new Subject<ISortItem>();
  private isExternalSortingInternal = true;
  private isLoadingInternal = false;
  private audiosInternal: Audio[] = [];
  private currentPage = 0;
  private totalPages: number | null = null;
  private needToResetScrollInternal = false;
  private sortInternal: ISortItem = {
    prop: 'song_title',
    dir: OrderDirection.ASC,
  };

  audiosPerPage = 10;
  private audioPageFetcher?: AudiosPageFetcher;

  get sort(): ISortItem {
    return this.sortInternal;
  }

  set sort(value: ISortItem) {
    this.sortInternal = value;
    this.stateChangedInternal$.next(this.getStateByKeys('sort'));
  }

  get isExternalSorting(): boolean {
    return this.isExternalSortingInternal;
  }
  set isExternalSorting(value: boolean) {
    this.isExternalSortingInternal = value;
    this.stateChangedInternal$.next(this.getStateByKeys('isExternalSorting'));
  }
  get isLoading(): boolean {
    return this.isLoadingInternal;
  }
  get audios(): Audio[] {
    return this.audiosInternal;
  }
  get needToResetScroll(): boolean {
    return this.needToResetScrollInternal;
  }
  get stateChanged$(): Observable<Partial<IAudioTableState>> {
    return this.stateChangedInternal$.asObservable();
  }
  get favoriteChanged$(): Observable<Audio> {
    return this.favoriteChangedInternal$.asObservable();
  }
  get audioChanged$(): Observable<Audio> {
    return this.audioChangedInternal$.asObservable();
  }
  get audioDeleted$(): Observable<Audio> {
    return this.audioDeletedInternal$.asObservable();
  }
  get select$(): Observable<Audio> {
    return this.selectInternal$.asObservable();
  }

  get hasMorePages(): boolean {
    return this.totalPages === null || this.currentPage < this.totalPages;
  }

  constructor() {
    this.stateChangedInternal$ = new BehaviorSubject<Partial<IAudioTableState>>(this.getState());
    this.subscribeToChangeSort();
  }

  private resetPagesState(): void {
    this.currentPage = 0;
    this.totalPages = null;
  }

  private fetchNextAudiosPage$(needToResetScroll: boolean): Observable<void> {
    if (this.audioPageFetcher && this.hasMorePages) {
      this.isLoadingInternal = true;
      this.stateChangedInternal$.next(this.getStateByKeys('isLoading'));

      let nextPage = this.currentPage + 1;
      nextPage = this.totalPages ? Math.min(nextPage, this.totalPages) : nextPage;

      return this.audioPageFetcher(this.sort, nextPage, this.audiosPerPage).pipe(
        tap(([audios, {total_pages}]) => {
          if (nextPage === 1) {
            this.audiosInternal = audios;
          } else {
            this.audiosInternal = uniqBy((a: Audio) => a.id, [...this.audiosInternal, ...audios]);
          }

          this.isLoadingInternal = false;
          this.currentPage = nextPage;
          this.totalPages = total_pages;
          this.needToResetScrollInternal = needToResetScroll;
          this.isExternalSortingInternal = true;
          this.stateChangedInternal$.next(this.getStateByKeys('isLoading', 'needToResetScroll', 'isExternalSorting'));
        }),
        map(() => undefined)
      );
    } else {
      return EMPTY;
    }
  }
  private getState(): IAudioTableState {
    return {
      audios: this.audiosInternal,
      sort: this.sortInternal,
      needToResetScroll: this.needToResetScrollInternal,
      isLoading: this.isLoadingInternal,
      isExternalSorting: this.isExternalSortingInternal,
    };
  }
  private getStateByKeys(...keys: Array<keyof IAudioTableState>): Partial<IAudioTableState> {
    return keys.reduce((acc, key) => Object.assign(acc, {[key]: this[key]}), {} as Partial<IAudioTableState>);
  }

  private subscribeToChangeSort(): void {
    this.changeSort$
      .pipe(
        tap((sortItem: ISortItem) => (this.sort = sortItem)),
        switchMap(() => this.fetchAudios$()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private fetchAudios$(): Observable<void> {
    this.resetPagesState();
    return this.fetchNextAudiosPage$(true);
  }

  registerAudioPageFetcher(audioPageFetcher: AudiosPageFetcher): void {
    this.audioPageFetcher = audioPageFetcher;
  }

  changeSort(sortItem: ISortItem): void {
    this.changeSort$.next(sortItem);
  }

  refreshAudios(): void {
    const currentPage = this.currentPage;
    const totalPages = this.totalPages;
    const audiosPerPage = this.audiosPerPage;
    this.audiosPerPage = currentPage * audiosPerPage;
    this.resetPagesState();
    this.fetchNextAudiosPage$(false)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.audiosPerPage = audiosPerPage;
      });
  }

  fetchAudios(): void {
    this.fetchAudios$().pipe(untilDestroyed(this)).subscribe();
  }

  fetchNextAudiosPage(needToResetScroll = false): void {
    this.fetchNextAudiosPage$(needToResetScroll).pipe(untilDestroyed(this)).subscribe();
  }

  toggleFavorite(audio: Audio): void {
    const newAudio = audio.is_favorite ? audio.removeFromFavorites() : audio.markAsFavorite();

    this.updateAudiosState({change: [newAudio]});
    this.favoriteChangedInternal$.next(newAudio);
  }

  changeAudio(audio: Audio): void {
    this.updateAudiosState({change: [audio]});
    this.audioChangedInternal$.next(audio);
  }

  deleteAudio(audio: Audio): void {
    this.updateAudiosState({del: [audio]});
    this.audioDeletedInternal$.next(audio);
  }

  updateAudiosState(
    {add, change, del}: Partial<{add: Audio[]; change: Audio[]; del: Audio[]}>,
    isExternalSorting?: boolean
  ): void {
    if (!(add?.length || change?.length || del?.length)) {
      return;
    }

    let audios = this.audiosInternal;

    if (add) {
      audios = uniqBy(a => a.id, audios.concat(add));
    }

    if (change) {
      audios = audios.map(a => {
        const changedAudio = change.find(c => c.id === a.id);
        return changedAudio ? instanceToInstance(changedAudio) : a;
      });
    }

    if (del) {
      audios = audios.filter(a => !del.find(d => d.id === a.id));
    }

    if (isExternalSorting !== undefined) {
      this.isExternalSortingInternal = isExternalSorting;
    }
    this.audiosInternal = audios;
    this.stateChangedInternal$.next(this.getStateByKeys('audios'));
  }
}
