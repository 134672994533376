import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {Audio} from '@px/audio-domain';

@Component({
  templateUrl: './audio-lyrics.component.html',
  styleUrls: ['./audio-lyrics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioLyricsComponent {
  constructor(
    public dialogRef: MatDialogRef<AudioLyricsComponent>,
    @Inject(MAT_DIALOG_DATA) public audio: Audio
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
