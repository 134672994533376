<div class="c-dialog__search">
  <div class="c-search c-search--autocomplete">
    <div class="c-search__inner">
      <div class="c-search__action">
        <px-button
          *ngIf="!formControl.value"
          [puiTooltip]="searchActionTooltip"
          puiTooltipPosition="bottom"
          size="md"
          type="fabNaked"
          data-qa="search icon"
          class="c-search__action c-search__action--search"
          (click$)="searchActionClick$.emit()"
        >
          <mds-icon icon="pxSearch" />
        </px-button>
        <px-button
          *ngIf="formControl.value"
          [puiTooltip]="clearActionTooltip"
          puiTooltipPosition="bottom"
          size="md"
          type="fabNaked"
          class="c-search__action c-search__action--clear"
          (click$)="formControl.reset()"
        >
          <mds-icon icon="pxClear" />
        </px-button>
      </div>
      <div class="c-search__main">
        <div class="c-search__input">
          <input
            #input
            mdsAutofocus
            [formControl]="formControl"
            [attr.placeholder]="inputPlaceholder"
            (focus)="togglePlaceholder(true)"
            (blur)="togglePlaceholder(false)"
            type="text"
          />
        </div>
        <div class="c-search__values">
          <mds-chip-list>
            <ng-container *ngFor="let item of options">
              <div class="c-search__value">
                <mds-chip (remove$)="removeOption(item.key)" [puiTooltip]="item | filterTruncated">
                  {{ item | filterFormat }}
                </mds-chip>
              </div>
            </ng-container>
          </mds-chip-list>
        </div>
        <div
          class="c-search__title"
          [ready]="showFilterHint"
          [pxHintTriggerFor]="hint"
          pxHintPosition="left"
          [pxHintShowDelay]="200"
          [pxHintDisabled]="isFilterHintShown"
          (close$)="saveHintAsSeen()"
        >
          {{ 'Filters' | translate }}:
        </div>
      </div>
      <div class="c-search__action c-search__action--right">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<mds-hint #hint>
  {{ 'Refine your music search with filters' | translate }}
</mds-hint>
