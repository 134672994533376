import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdsDataTableComponent} from './components/datatable/datatable.component';
import {MdsDatatableContainerComponent} from './components/datatable-container/datatable-container.component';
import {MdsDataTableEmptyRegisterDirective} from './directives/datatable-empty-register.directive';
import {MdsDataTableRegisterDirective} from './directives/datatable-register.directive';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

@NgModule({
  imports: [CommonModule, NgxDatatableModule],
  declarations: [
    MdsDataTableComponent,
    MdsDatatableContainerComponent,
    MdsDataTableEmptyRegisterDirective,
    MdsDataTableRegisterDirective,
  ],
  exports: [
    MdsDataTableComponent,
    MdsDatatableContainerComponent,
    MdsDataTableEmptyRegisterDirective,
    MdsDataTableRegisterDirective,
  ],
})
export class MdsDatatableModule {}
