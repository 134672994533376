<div class="audio-search">
  <px-audio-browser-view [isPlayerShown]="!!audioPlayerController.playingAudio">
    <div class="audio-search__content">
      <px-audio-search-bar
        class="audio-search__bar"
        [searchQuery]="searchQuery"
        [options]="selectedOptions"
        [showFilterHint]="showFilterHint"
        [isFocused]="isSearchBarFocused"
        [searchActionTooltip]="'Refresh Search' | translate"
        (searchActionClick$)="audioTableController.refreshAudios()"
        (searchQuery$)="onChangeSearchQuery($event)"
        (removeOption$)="removeFilterOption($event)"
      >
        <px-button
          [mdsDropdownTriggerFor]="dropdown"
          [disabled]="!optionsDefinitions?.length"
          [puiTooltip]="'Add Filters' | translate"
          puiTooltipPosition="bottom"
          size="md"
          type="fabNaked"
          data-qa="filter icon"
        >
          <mds-icon icon="pxFilterList" />
        </px-button>
      </px-audio-search-bar>

      <px-audio-table
        class="audio-search__table"
        [activeAudioId]="audioPlayerController.playingAudio?.id"
        [disabledAudiosIds]="disabledAudiosIds"
        [defaultSort]="audioTableController.sort"
        [isLoading]="audioTableController.isLoading"
        [audios]="audioTableController.audios"
        [needToResetScroll]="audioTableController.needToResetScroll"
        [activeAudioPlayProgress]="audioPlayerController.playingProgress"
        [isActiveAudioLoading]="audioPlayerController.isAudioLoading"
        [isActiveAudioPlaying]="audioPlayerController.isAudioPlaying"
        [hasExternalSorting]="audioTableController.isExternalSorting"
        [shuffleSortEnabled]="true"
        [isShuffleSort]="isRandomSortProp(audioTableController.sort.prop)"
        (playAudio$)="audioPlayerController.playAudio($event)"
        (pause$)="audioPlayerController.pause()"
        (sort$)="audioTableController.changeSort($event)"
        (shuffleSort$)="shuffleSort()"
        (seekActiveAudio$)="audioPlayerController.seek($event)"
        (nextPage$)="audioTableController.fetchNextAudiosPage()"
        (toggleFavoriteAudio$)="audioTableController.toggleFavorite($event)"
        (select$)="selectAudio($event, AudioSelectionSource.SEARCH_TAB_ROW)"
        (audioChanged$)="audioTableController.changeAudio($event)"
        (audioDeleted$)="audioTableController.deleteAudio($event)"
      >
        <div class="audio-search__empty">
          <px-audio-empty
            [mainText]="'We couldn\'t find any songs that match your search' | translate"
            [secondaryText]="'Try checking spelling or removing some filters' | translate"
            [actionBtnText]="'Clear all' | translate"
            [withAction]="true"
            (actionClick$)="clearAll()"
          />
        </div>
      </px-audio-table>
    </div>

    <px-audio-player
      audio-player
      *ngIf="audioPlayerController.playingAudio"
      class="audio-search__player"
      (seekAudio$)="audioPlayerController.seek($event)"
      (playAudio$)="audioPlayerController.playAudio(audioPlayerController.playingAudio)"
      (pauseAudio$)="audioPlayerController.pause()"
      (nextAudio$)="audioPlayerController.playNext()"
      (prevAudio$)="audioPlayerController.playPrev()"
      (selectAudio$)="selectAudio(audioPlayerController.playingAudio, AudioSelectionSource.SEARCH_TAB_PLAYER)"
      (toggleFavoriteAudio$)="audioPlayerController.toggleFavorite()"
      [audio]="audioPlayerController.playingAudio"
      [titleTruncateLength]="35"
      [isPlaying]="audioPlayerController.isAudioPlaying"
      [isLoading]="audioPlayerController.isAudioLoading"
      [progress]="audioPlayerController.playingProgress"
      [playPositionInfo]="audioPlayerController.playPositionInfo"
    />
  </px-audio-browser-view>
</div>

<mds-dropdown
  #dropdown
  [position]="MDS_DROPDOWN_POSITION.bottomRight"
  [type]="MDS_DROPDOWN_TYPE.default"
  (close$)="audioFilters.resetCollapseStates()"
>
  <px-audio-filters
    #audioFilters
    [options]="optionsDefinitions"
    [selectedOptions]="selectedOptions"
    (optionSelected$)="addFilterOption($event.key, $event.value)"
    (optionCleared$)="removeFilterOption($event.key, $event.value)"
    (allOptionsCleared$)="clearFilters()"
    (selectGroup$)="addFilterGroup($event)"
  />
</mds-dropdown>
