import {IPSSPlatformConfig, PSSFeatureConfig, PSSFeatureList} from './core/interfaces/platform-config';
import {EventDateFormatOverrides, Languages, PlatformEnvironment} from '@px/shared/env';
import {SlideshowState} from '@px/shared/api';
import {SortFieldEnum} from '@px/legacy/feature-page-manager';
import {TransitionTypeId} from '@px/audio-domain';
import {AspectRatio} from '@px/util/aspect-ratio';

export class PSSPlatformEnvironment
  extends PlatformEnvironment<PSSFeatureList, PSSFeatureConfig>
  implements IPSSPlatformConfig
{
  protected static self: PSSPlatformEnvironment;
  //TODO make fields readonly
  SUBMIT_FEEDBACK: string;
  BUILD_VIDEO_ENABLED: boolean;
  LANGUAGE_LIST = [
    {id: 0, title: 'PREFERENCES_PAGE.AUTOMATIC', value: Languages.auto},
    {id: 1, title: 'PREFERENCES_PAGE.ENGLISH', value: Languages.en},
    {id: 2, title: 'PREFERENCES_PAGE.PORTUGUESE', value: Languages.pt},
    {id: 3, title: 'PREFERENCES_PAGE.RUSSIAN', value: Languages.ru},
    {id: 4, title: 'Ukrainian', value: Languages.uk},
    {id: 5, title: 'Italian', value: Languages.it},
    {id: 6, title: 'German', value: Languages.de},
  ];
  SLIDESHOW_STATE_LIST = {
    CREATED: SlideshowState.CREATED,
    EDITOR: SlideshowState.EDITOR,
    SELECTED_FEATURE_IMAGE: SlideshowState.SELECTED_FEATURE_IMAGE,
    PUBLISHED: SlideshowState.PUBLISHED,
    CHANGED: SlideshowState.CHANGED,
  };
  VIDEO_BACKGROUND_COLOR = [
    {id: 0, title: 'GLOBAL.BLACK', value: 'black'},
    {id: 1, title: 'GLOBAL.WHITE', value: 'white'},
  ];
  IFRAME_SIZES = {
    [AspectRatio.ASPECT_16_9]: {
      min: {width: 300, height: 200},
      default: {width: 800, height: 533},
    },
    [AspectRatio.ASPECT_9_16]: {
      min: {width: 200, height: 300},
      default: {width: 533, height: 800},
    },
  };
  COMMON_SETTINGS = {
    availableMusicTypes: {
      mp3: '|mp3|audio/mp3|audio/mpeg|',
      m4a: '|m4a|audio/x-m4a|audio/mp4|audio/m4a|', // mp4 - mime type of m4a file on Win, x-m4a - mime type of m4a file on Mac
      aac: '|aac|audio/aac|',
    },
    availablePhotoTypes: '|image/jpeg|',
    PHOTO: {
      AVAILABLE_PHOTO_EXTENSIONS: ['.jpg', '.jpeg'],
    },
    LOGO: {
      MIN_HEIGHT: 50,
      MIN_THUMB_HEIGHT: 100,
      MIN_WIDTH: 400,
      AVAILABLE_LOGO_EXTENSIONS: ['.jpg', '.jpeg', '.png'],
    },
    SLIDESHOW_LIMITS: {
      MIN_SLIDE_DURATION: 6,
      MIN_SEGMENT_DURATION: 600,
      MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT: 102, //1.7s
      MIN_LENGTH_FROM_END_TO_LAST_BM_POINT: 150, //2.5s
      MAX_SEGMENTS: 20,
      MAX_DURATION: 216000, // 60min in frames
      MAX_IMAGES: 1000,
    },
    MAX_DPR: 4,
    MAX_UPLOAD_FILE_SIZE: 40000000,
    processingImageTime: 10000,
    checkVideoStatusInterval: 30000,
    AUTO_SAVE_INTERVAL: 10000,
    checkViewStatusInterval: 5000,
    maxUploadStreams: 3,
    FRAMES_PER_SEC: 60,
    CHANGE_SEGMENT_ANIMATION_TIME: 150,
    AUTO_TRANSITION_DURATION: 60,
    FADE_TRANSITION_DURATION: {
      START: 30,
      END: 108,
    },
    s3signature: {
      minExpirationTime: 3600000,
    },
  };
  USER_FOLDER_ID = 0;
  IMAGES_SORT_TYPE_LIST = {
    name: {
      order: 0,
      title: 'EDITOR_PAGE.IMAGES_SORT_TYPE.FILENAME',
      mpPropName: 'Filename',
      disabled: false,
    },
    date_taken: {
      order: 1,
      title: 'EDITOR_PAGE.IMAGES_SORT_TYPE.DATE_TAKEN',
      mpPropName: 'Date taken',
      disabled: false,
    },
    shuffle: {
      order: 2,
      title: 'EDITOR_PAGE.IMAGES_SORT_TYPE.SHUFFLE',
      disabled: false,
    },
    manual: {
      order: 3,
      title: 'EDITOR_PAGE.IMAGES_SORT_TYPE.MANUAL',
      mpPropName: 'Manual',
      disabled: false,
    },
  };
  MANAGER_SLIDESHOWS_PER_PAGE = 100;
  MANAGER_SORT_TYPE_LIST = {
    name_asc: {
      order: 0,
      title: 'MANAGER_PAGE.MANAGER_SORT_TYPE.NAME',
      field: SortFieldEnum.NAME,
      asc: true,
    },
    date_created_asc: {
      order: 1,
      title: 'MANAGER_PAGE.MANAGER_SORT_TYPE.DATE_CREATED_ASC',
      field: SortFieldEnum.CREATED,
      asc: true,
    },
    date_created_desc: {
      order: 2,
      title: 'MANAGER_PAGE.MANAGER_SORT_TYPE.DATE_CREATED_DESC',
      field: SortFieldEnum.CREATED,
      asc: false,
    },
    date_event_asc: {
      order: 3,
      title: 'MANAGER_PAGE.MANAGER_SORT_TYPE.DATE_EVENT_ASC',
      field: SortFieldEnum.EVENT_DATE,
      asc: true,
    },
    date_event_desc: {
      order: 4,
      title: 'MANAGER_PAGE.MANAGER_SORT_TYPE.DATE_EVENT_DESC',
      field: SortFieldEnum.EVENT_DATE,
      asc: false,
    },
  };
  TRANSITION_TYPE_LIST = [
    {id: TransitionTypeId.AUTO, title: 'SLIDESHOW_TRANSITION_TYPES.AUTO_TRANSITION_LABEL', disabled: false},
    {id: TransitionTypeId.CROSS_FADE, title: 'SLIDESHOW_TRANSITION_TYPES.CROSSFADE_TRANSITION_LABEL', disabled: false},
    {id: TransitionTypeId.HARD_CUT, title: 'SLIDESHOW_TRANSITION_TYPES.HARD_CUT_TRANSITION_LABEL', disabled: false},
    {
      id: TransitionTypeId.DIP_TO_BACKGROUND,
      title: 'SLIDESHOW_TRANSITION_TYPES.DIP_TO_BACKGROUND_TRANSITION_LABEL',
      disabled: false,
    },
  ];
  URL_PATTERN =
    /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i;
  EVENT_DATE_FORMAT_OVERRIDES: EventDateFormatOverrides = {
    en: {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    },
  };
  AVAILABLE_FILES_EXTENSIONS = {
    audio: ['.mp3', '.m4a', '.aac'],
  };

  CONFIG_CAT: string;
  readonly RECENT_SEGMENTS_COUNT = 20;
  readonly MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE = 2;
  readonly BEATMATCHING_SEARCH_RANGE = {
    min: 8,
    max: 350,
  };

  readonly LOW_RESOLUTION_THRESHOLD = 400;

  readonly SAMPLE_CONTENT_USER_ID = 141053;

  static getSelf(): PSSPlatformEnvironment {
    return this.self;
  }

  constructor() {
    super();
    PSSPlatformEnvironment.self = this;
  }

  override getAuthNavigationUrl(): string {
    return `${this.NO_AUTH_REDIRECT_URL}/smartslides/`;
  }
}
