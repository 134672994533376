import {inject, Injectable} from '@angular/core';
import {AssetCreator, IAssetsCreationProcess} from '@px/shared/asset-creation';
import {IAudioGQL} from '../entities/gql-schema-types/audio-gql';
import {IS3Credentials, IS3FormProperties, S3FileHostingUploader} from '@px/shared/data-access/file-hosting';
import {FileUploaderService, PxFile} from '@px/shared-data-access-file-upload';
import {AUDIO_S3_HOST} from '../entities/tokens/audio-s3-host';
import {MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE} from '../entities/tokens/max-audio-assets-uploadig-queue-size';
import {AudioAssetBackendCreationService} from './audio-asset-backend-creation.service';
import {Observable} from 'rxjs';
import {AudioS3CredentialsService} from './audio-s3-credentials.service';

function formPropertiesFactory(file: PxFile, s3Creds: IS3Credentials): IS3FormProperties {
  return {
    key: s3Creds.s3Key,
    AWSAccessKeyId: s3Creds.awsPublicKey,
    Policy: s3Creds.policy,
    Signature: s3Creds.signature,
    ['Content-Type']: (file: PxFile): string => file.type,
    ['x-amz-storage-class']: s3Creds.storageClass,
    ['x-amz-acl']: 'public-read',
  };
}

@Injectable()
export class AudioAssetCreatorService {
  private readonly s3FileUploader = new S3FileHostingUploader(
    inject(FileUploaderService),
    inject(AudioS3CredentialsService),
    inject(AUDIO_S3_HOST),
    formPropertiesFactory
  );

  private readonly assetCreator = new AssetCreator(
    this.s3FileUploader,
    inject(AudioAssetBackendCreationService),
    inject(MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE)
  );

  get creationProcess$(): Observable<IAssetsCreationProcess<IAudioGQL>> {
    return this.assetCreator.creationProcess$;
  }

  addFilesToCreationQueue(pxFiles: PxFile[]): void {
    this.assetCreator.addFilesToCreationQueue(
      pxFiles.map(pxFile => ({
        pxFile,
        creationParameters: null,
        creationContext: null,
        fileHostingParams: null,
      }))
    );
  }

  discard(pxFiles: PxFile[]): void {
    this.assetCreator.discard(pxFiles);
  }
}
