export enum HotKeys {
  play = 'play',
  like = 'like',
  mute = 'mute',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  shiftArrowUp = 'shiftArrowUp',
  shiftArrowRight = 'shiftArrowRight',
  shiftArrowDown = 'shiftArrowDown',
  shiftArrowLeft = 'shiftArrowLeft',
  favorite = 'favorite',
  hidePhoto = 'hidePhoto',
  ctrlZ = 'ctrlZ',
  shiftCtrlZ = 'shiftCtrlZ',
}
