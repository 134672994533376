import {TempoType, TempoTypeId} from '../enums/tempo-types.enum';

/** @deprecated used only for legacy compatibility */
export const TEMPO_TYPE_TO_ID = new Map<TempoType, TempoTypeId>([
  [TempoType.SLOW, TempoTypeId.SLOW],
  [TempoType.UPTEMPO, TempoTypeId.UPTEMPO],
]);

/** @deprecated used only for legacy compatibility */
export const ID_TO_TEMPO_TYPE = new Map(
  [...TEMPO_TYPE_TO_ID.entries()].map(entry => entry.reverse() as [TempoTypeId, TempoType])
);
