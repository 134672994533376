import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiHintTriggerForDirective} from './directives/ui-hint-trigger-for.directive';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [UiHintTriggerForDirective],
  exports: [UiHintTriggerForDirective],
})
export class UiHintModule {}
