import {Observable} from 'rxjs';

export interface IHotKeysService {
  play(): Observable<KeyboardEvent>;

  like(): Observable<KeyboardEvent>;

  mute(): Observable<KeyboardEvent>;

  arrowLeft(): Observable<KeyboardEvent>;

  arrowRight(): Observable<KeyboardEvent>;

  shiftArrowUp(): Observable<KeyboardEvent>;

  shiftArrowRight(): Observable<KeyboardEvent>;

  shiftArrowDown(): Observable<KeyboardEvent>;

  shiftArrowLeft(): Observable<KeyboardEvent>;

  favorite(): Observable<KeyboardEvent>;

  hidePhoto(): Observable<KeyboardEvent>;

  undo(): Observable<KeyboardEvent>;

  redo(): Observable<KeyboardEvent>;
}
