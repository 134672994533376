export enum AudiosOrderBy {
  ARTIST = 'ARTIST',
  CATEGORY = 'CATEGORY',
  DATE_OF_RECENT_USE = 'DATE_OF_RECENT_USE',
  ENERGY = 'ENERGY',
  IS_FAVORITE = 'IS_FAVORITE',
  LENGTH = 'LENGTH',
  HAS_LYRICS = 'HAS_LYRICS',
  RANDOM = 'RANDOM',
  TEMPO = 'TEMPO',
  TITLE = 'TITLE',
  DOWN_TEMPO = 'DOWN_TEMPO',
  UP_TEMPO = 'UP_TEMPO',
}
