import {FileConstructor} from '@px/shared-data-access-file-upload';

export interface IFileDragSelectorConfig {
  availableFileType?: FileConstructor;
  maxUploadFileSize?: number;
  multiple?: boolean;
  requiredImageSize?: {
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number;
  };
}
