<div class="c-audio-empty">
  <div class="c-audio-empty__inner">
    <div class="c-audio-empty__icon">(·_·)</div>
    <div class="c-audio-empty__title">
      {{ mainText }}
    </div>
    <div class="c-audio-empty__subtitle">
      {{ secondaryText }}
    </div>
    <div class="c-audio-empty__action" *ngIf="withAction">
      <px-button (click)="actionClick()" type="defaultGray">{{ actionBtnText }}</px-button>
    </div>
  </div>
</div>
