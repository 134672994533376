import {Pipe, PipeTransform} from '@angular/core';
import {IPhotoUploadingWarningsData, PhotoUploadingWarningMessages} from '../interfaces';

@Pipe({
  name: 'photoUploadingWarnings',
  standalone: true,
})
export class PhotoUploadingWarningsPipe implements PipeTransform {
  transform(
    data: IPhotoUploadingWarningsData,
    messages: PhotoUploadingWarningMessages
  ): {message: string; count: number} {
    const warnings: string[] = [];

    for (const warningsKey in data) {
      const key = warningsKey as keyof IPhotoUploadingWarningsData;

      if (data[key]) {
        warnings.push(messages[key] as string);
      }
    }
    return {
      message: warnings.join('\n--\n'),
      count: warnings.length,
    };
  }
}
