import {AudioCategory} from '../enums/audio-categoriy.enum';

export const AUDIO_CATEGORIES_NAMES = new Map<AudioCategory, string>([
  [AudioCategory.BEATS, 'Beats'],
  [AudioCategory.USER_AUDIO, 'My Uploads'],
  [AudioCategory.EMOTIONAL, 'Emotional'],
  [AudioCategory.EPIC_BM, 'Epic Beat-Matching'],
  [AudioCategory.HAPPY_FOLK, 'Happy'],
  [AudioCategory.PARTY_TIME, 'Party Time'],
  [AudioCategory.CALM_MELODIES, 'Calm Melodies'],
  [AudioCategory.LOVE_SONGS, 'Love Songs'],
]);
