import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {SelectionType, SortDirection} from '@swimlane/ngx-datatable';
import {filter, map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AudioEditDialogComponent} from '../audio-edit-dialog/audio-edit-dialog.component';
import {plainToInstance} from 'class-transformer';
import {AudioDeleteDialogComponent} from '../audio-delete-dialog/audio-delete-dialog.component';
import {AudioLyricsComponent} from '../audio-lyrics/audio-lyrics.component';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {AudioTableColumns} from '../../enums/audio-table-columns';
import {AudioTableColumnsOverrides} from '../../enums/audio-table-columns-overrides';
import {IAudioTableColumnConfig} from '../../enums/audio-table-column-config';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {uniq} from 'ramda';
import {Audio, AudioChange, ISortItem, OrderDirection, RandomSortProp} from '@px/audio-domain';
import {ISortEvent} from '@pui/components/datatable';
import {IAudioTableSort} from '../../intefaces/audio-table-sort';
import {BrowserService} from '@px/shared/browser';
import {PlatformEnvironment} from '@px/shared/env';

@UntilDestroy()
@Component({
  selector: 'px-audio-table',
  templateUrl: './audio-table.component.html',
  styleUrls: ['./audio-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioTableComponent implements OnChanges {
  private readonly COLUMNS_CONFIG: Record<AudioTableColumns, IAudioTableColumnConfig> = {
    [AudioTableColumns.SONG_TITLE]: {width: 290},
    [AudioTableColumns.CATEGORY]: {width: 155},
    [AudioTableColumns.ENERGY]: {width: 98},
    [AudioTableColumns.LENGTH]: {width: 75},
    [AudioTableColumns.BM_TARGET]: {width: 163},
    [AudioTableColumns.STATUS]: {width: 73},
    [AudioTableColumns.LYRICS]: {width: 60},
    [AudioTableColumns.PLAYER_CONTROLS]: {width: 52},
    [AudioTableColumns.WAVE_FORM]: {width: 248},
    [AudioTableColumns.IS_FAVORITE]: {width: 104},
  };
  readonly ROW_HEIGHT = 72;
  readonly HEADER_HEIGHT = 48;
  readonly SelectionType = SelectionType;
  readonly AudioTableColumns = AudioTableColumns;

  @Input() availableColumns?: AudioTableColumns[];
  @Input() columnsOverrides?: AudioTableColumnsOverrides;
  @Input() isLoading = false;
  @Input() isActiveAudioLoading = false;
  @Input() isActiveAudioPlaying = false;
  @Input() audios: Audio[] = [];
  @Input() defaultSort?: ISortItem;
  @Input() activeAudioId?: number;
  @Input() activeAudioPlayProgress?: number;
  @Input() disabledAudiosIds?: number[];
  @Input() needToResetScroll?: boolean;
  @Input() hasExternalSorting = true;
  @Input() shuffleSortEnabled = false;
  @Input() isShuffleSort = false;

  @Output() playAudio$ = new EventEmitter<Audio>();
  @Output() pause$ = new EventEmitter<void>();
  @Output() seekActiveAudio$ = new EventEmitter<number>();
  @Output() audioChanged$ = new EventEmitter<Audio>();
  @Output() audioDeleted$ = new EventEmitter<Audio>();
  @Output() select$ = new EventEmitter<Audio>();
  @Output() toggleFavoriteAudio$ = new EventEmitter<Audio>();
  @Output() sort$ = new EventEmitter<ISortItem>();
  @Output() shuffleSort$ = new EventEmitter<void>();
  @Output() nextPage$ = new EventEmitter<void>();

  @HostBinding('style.height') private readonly HOST_HEIGHT = '100%';

  activeAudio?: Audio;
  hasVirtualization = true;

  get currentCount(): number {
    return this.audios.length ?? 0;
  }

  get datatableSortState(): IAudioTableSort[] {
    return this.defaultSort && this.defaultSort.prop !== RandomSortProp.VALUE
      ? [
          {
            dir: this.defaultSort.dir === OrderDirection.ASC ? 'asc' : 'desc',
            prop: this.defaultSort.prop,
          },
        ]
      : [];
  }

  constructor(
    private readonly matDialog: MatDialog,
    private readonly translate: TranslateService,
    private datePipe: DatePipe,
    private readonly browserService: BrowserService,
    private platform: PlatformEnvironment<'DISABLE_AUDIO_VIRTUAL_SCROLLING_IN_FF'>
  ) {
    this.hasVirtualization = !(
      this.browserService.isFirefox() && this.platform.hasFeature('DISABLE_AUDIO_VIRTUAL_SCROLLING_IN_FF')
    );
  }

  private isShuffleTurn(sortDir?: SortDirection): boolean {
    return !sortDir || sortDir === SortDirection.desc;
  }

  private assertToSortItem(raw: IAudioTableSort): ISortItem {
    return {
      dir: raw.dir === 'asc' ? OrderDirection.ASC : OrderDirection.DESC,
      prop: raw.prop,
    };
  }

  getRowClass = (row: Audio): {[key: string]: boolean} => {
    return {
      'is-selected': !!this.disabledAudiosIds?.includes(row.id),
    };
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audios'] || changes['activeAudioId']) {
      this.activeAudio = this.activeAudioId ? this.audios.find(a => a.id === this.activeAudioId) : undefined;
    }
  }

  getColumnWidth(column: AudioTableColumns): number {
    return this.columnsOverrides?.[column]?.width ?? this.COLUMNS_CONFIG[column].width;
  }

  isColumnAvailable(column: AudioTableColumns): boolean {
    return this.availableColumns ? this.availableColumns.includes(column) : true;
  }

  nextPage(): void {
    this.nextPage$.emit();
  }

  onRowClick(rowsSelectedEvent: Audio[]): void {
    const playAudio = rowsSelectedEvent[0];
    if (playAudio && playAudio.id !== this.activeAudio?.id) {
      this.playAudio(rowsSelectedEvent[0]);
    }
  }

  sort(event: ISortEvent<IAudioTableSort>): void {
    if (event.sorts.length) {
      this.sort$.emit(this.assertToSortItem(event.sorts[0]));
    }
  }

  rowToAudio(row: unknown): Audio {
    return row as Audio;
  }

  playAudio(audio: Audio): void {
    this.playAudio$.emit(audio);
  }

  pause(): void {
    this.pause$.emit();
  }

  seekAudio(seek: number, audio: Audio): void {
    if (audio.id === this.activeAudio?.id) {
      this.seekActiveAudio$.emit(seek);
    }
  }

  openEditDialog(audio: Audio): void {
    if (this.activeAudio) {
      this.pause();
    }

    const audioEditDialogRef = this.matDialog.open(AudioEditDialogComponent, {
      data: [audio],
      panelClass: ['px-dialog', 'px-edit-tracks-dialog'],
    });

    audioEditDialogRef
      .afterClosed()
      .pipe(
        map(changes => changes as AudioChange[]),
        filter(changes => !!changes && uniq(changes.map(c => c.id)).length === 1),
        map(changes => {
          const audioId = changes[0].id;
          const audio = this.audios.find(a => a.id === audioId);
          const audioChanges = changes.reduce((acc, curr) => ({...acc, ...curr}), {});

          return plainToInstance(Audio, {
            ...audio,
            ...audioChanges,
          });
        }),
        filter(changedAudios => !!changedAudios.length),
        untilDestroyed(this)
      )
      .subscribe(audio => this.audioChanged$.next(audio));
  }

  openDeleteDialog(audio: Audio): void {
    if (this.activeAudio) {
      this.pause();
    }

    const audioDeleteDialogRef = this.matDialog.open(AudioDeleteDialogComponent, {
      data: audio.song_title,
      panelClass: ['px-dialog', 'px-confirm-dialog'],
    });

    audioDeleteDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.audioDeleted$.emit(audio);
      });
  }

  openAudioLyric(audio: Audio): void {
    this.matDialog.open<AudioLyricsComponent, Audio>(AudioLyricsComponent, {
      data: audio,
      panelClass: ['px-dialog', 'px-custom-width', 'px-audio-lyric-dialog'],
    });
  }

  select(audio: Audio): void {
    this.select$.emit(audio);
  }

  toggleFavorite(audio: Audio): void {
    this.toggleFavoriteAudio$.emit(audio);
  }

  showExpiredTitle(audio: Audio): string {
    let expireTooltipText = '';
    if (!audio.expired && audio.expires_at) {
      // TODO: Rewrite by DayJS
      const timezoneOffset = new Date().getTimezoneOffset() * 60000;
      if (audio.expires_at - new Date().getTime() + timezoneOffset > 0) {
        expireTooltipText =
          this.translate.instant('GLOBAL.SONG_EXPIRATION_WARNING_PART1', {
            expiration_date: this.datePipe.transform(audio.expires_at + timezoneOffset, 'MMMM d, yyyy'),
          }) +
          '\n' +
          this.translate.instant('GLOBAL.SONG_EXPIRATION_WARNING_PART2');
      }
    }

    return expireTooltipText;
  }

  getShufflingHeaderSortClass(sortDir?: SortDirection): string {
    return this.isShuffleSort
      ? 'shuffling-header--shuffle'
      : sortDir
        ? `shuffling-header--${sortDir.toString()}`
        : 'shuffling-header--unset';
  }

  sortShuffle(sortFn: () => void, currentSortDir?: SortDirection): void {
    this.isShuffleSort = this.isShuffleSort ? false : this.isShuffleTurn(currentSortDir);
    if (this.isShuffleSort) {
      this.shuffleSort$.emit();
    } else {
      sortFn();
    }
  }
}
