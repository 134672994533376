import {TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  booleanAttribute,
} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {MDS_TAB_CONTENT} from '../../directives/tab-content.directive';
import {MDS_TAB_TITLE} from '../../directives/tab-title.directive';

@UntilDestroy()
@Component({
  selector: 'mds-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsTabComponent implements OnInit {
  @ContentChild(MDS_TAB_CONTENT, {read: TemplateRef, static: true}) private lazyContentInternal?: TemplateRef<unknown>;

  @ContentChild(MDS_TAB_TITLE, {read: TemplateRef, static: true}) private titleContentInternal?: TemplateRef<unknown>;

  @ViewChild(TemplateRef, {static: true}) private contentInternal?: TemplateRef<unknown>;

  private isActiveInternal = false;
  private contentPortalInternal: TemplatePortal | null = null;
  private titlePortalInternal: TemplatePortal | null = null;

  readonly activeStateChange$: Subject<boolean> = new Subject<boolean>();

  @Input() title?: string;
  @Input() dataQa?: string;
  @Input({transform: booleanAttribute}) disabled = false;
  @Input({transform: booleanAttribute}) fullWidth = false;

  @Output() tabChanged = new EventEmitter<boolean>();

  get active(): boolean {
    return this.isActiveInternal;
  }

  set active(value: boolean) {
    this.isActiveInternal = value;
    this.activeStateChange$.next(value);
    if (value) {
      this.tabChanged.emit();
    }
  }

  get content(): TemplatePortal | null {
    return this.contentPortalInternal;
  }

  get titleContent(): TemplatePortal | null {
    return this.titlePortalInternal;
  }

  get shouldAttach(): boolean {
    return this.lazyContentInternal === undefined;
  }

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  private createContentPortal(): void {
    const content = this.lazyContentInternal || this.contentInternal;
    if (content !== undefined) {
      this.contentPortalInternal = new TemplatePortal(content, this.viewContainerRef);
    }
  }

  private createTitlePortal(): void {
    if (this.titleContentInternal !== undefined) {
      this.titlePortalInternal = new TemplatePortal(this.titleContentInternal, this.viewContainerRef);
    }
  }

  ngOnInit(): void {
    this.createContentPortal();

    if (this.titleContentInternal) {
      this.createTitlePortal();
    }
  }
}
