<mds-datatable
  class="audio-table"
  [isLoading]="isLoading"
  [headerHeight]="HEADER_HEIGHT"
  [rowHeight]="ROW_HEIGHT"
  [totalCount]="currentCount"
  [needToResetScroll]="needToResetScroll"
  [data]="audios ?? []"
  [sortState]="datatableSortState"
  [selected]="activeAudio"
  [getRowClass]="getRowClass"
  [selectionType]="SelectionType.single"
  [hasExternalSorting]="hasExternalSorting"
  [hasVirtualization]="hasVirtualization"
  (nextPage$)="nextPage()"
  (select$)="onRowClick($event)"
  (sort$)="sort($event)"
>
  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.SONG_TITLE)"
    mdsDataTableRegister
    [name]="'Title' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.SONG_TITLE)"
    [headerTemplate]="shuffleSortEnabled ? shufflingHeader : null"
    prop="song_title"
    let-row="row"
    let-value="value"
    order="0"
  >
    <div class="c-data-field" [ngClass]="{'c-data-field--on-hover-actions': rowToAudio(row).isCustom}">
      <ng-container *ngIf="rowToAudio(row).isCustom">
        <div class="c-data-field__actions">
          <div class="c-data-field__action">
            <px-button
              [puiTooltip]="'Edit artist and track name' | translate"
              size="xs"
              type="fabSquare"
              data-qa="edit track title button"
              (click)="openEditDialog(row)"
              pxClickStopPropagation
            >
              <mds-icon icon="pxEdit" />
            </px-button>
          </div>
          <div class="c-data-field__action">
            <px-button
              [puiTooltip]="'Delete song from My Uploads' | translate"
              size="xs"
              type="fabSquare"
              data-qa="delete track button"
              (click)="openDeleteDialog(row)"
              pxClickStopPropagation
            >
              <mds-icon icon="pxDelete" />
            </px-button>
          </div>
        </div>
      </ng-container>
      <div class="c-data-field__main">
        <px-audio-title [title]="value" [artist]="row.artist" [expiredInfo]="showExpiredTitle(row)" />
      </div>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.CATEGORY)"
    mdsDataTableRegister
    [name]="'Category' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.CATEGORY)"
    prop="category"
    let-row="row"
    order="1"
  >
    <div class="c-data-field c-data-field--category">
      <div class="c-data-field__title">{{ rowToAudio(row).category_id | audioCategory }}</div>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.ENERGY)"
    mdsDataTableRegister
    [name]="'Energy' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.ENERGY)"
    prop="energy"
    let-row="row"
    order="2"
  >
    <div class="c-data-field c-data-field--string">{{ rowToAudio(row).energy_id | audioEnergy }}</div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.LENGTH)"
    mdsDataTableRegister
    [name]="'Length' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.LENGTH)"
    prop="length"
    let-row="row"
    order="3"
  >
    <div class="c-data-field c-data-field--string">{{ rowToAudio(row).durationFormat }}</div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.BM_TARGET)"
    mdsDataTableRegister
    [name]="'Beat-Matching' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.BM_TARGET)"
    prop="bm_target"
    let-row="row"
    order="4"
  >
    <px-audio-tempo-info
      class="c-data-field c-data-field--show-on-row-hover"
      [upTempo]="rowToAudio(row).up_tempo"
      [downTempo]="rowToAudio(row).down_tempo"
      [length]="row.length"
      [showFullInfo]="true"
    />
    <px-audio-tempo-info
      class="c-data-field c-data-field--hide-on-row-hover"
      [upTempo]="rowToAudio(row).up_tempo"
      [downTempo]="rowToAudio(row).down_tempo"
      [length]="rowToAudio(row).length"
      [showFullInfo]="false"
    />
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.STATUS)"
    mdsDataTableRegister
    [resizeable]="false"
    [sortable]="false"
    [width]="getColumnWidth(AudioTableColumns.STATUS)"
    let-row="row"
    let-value="value"
    order="5"
  >
    <div class="audio-status-cell">
      <ng-container *ngIf="rowToAudio(row).is_recently_used as recently_used">
        <div class="audio-status-cell__recently-used">
          <mds-icon
            icon="pxHistory"
            [puiTooltip]="'Last used on [date]' | translate: {date: recently_used * 1000 | date}"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="rowToAudio(row).is_new">
        <div class="c-label c-label--primary">{{ 'New' | translate }}</div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.LYRICS)"
    mdsDataTableRegister
    [name]="'Vocals' | translate"
    [resizeable]="false"
    [sortable]="true"
    [width]="getColumnWidth(AudioTableColumns.LYRICS)"
    cellClass="p-0"
    headerClass="p-0 h-text-center"
    let-row="row"
    let-value="value"
    prop="lyrics"
    order="6"
  >
    <div class="c-audio-controls c-audio-controls--start">
      <ng-container *ngIf="rowToAudio(row).lyrics">
        <div class="c-audio-controls__item">
          <px-button
            (click)="openAudioLyric(row)"
            [puiTooltip]="'View lyrics' | translate"
            size="md"
            type="fabNaked"
            pxClickStopPropagation
          >
            <mds-icon icon="pxMic" />
          </px-button>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.PLAYER_CONTROLS)"
    mdsDataTableRegister
    [resizeable]="false"
    [sortable]="false"
    [width]="getColumnWidth(AudioTableColumns.PLAYER_CONTROLS)"
    cellClass="p-0"
    headerClass="p-0"
    let-row="row"
    let-value="value"
    order="7"
  >
    <div class="c-audio-controls c-audio-controls--start p-l">
      <div class="c-audio-controls__item">
        <px-audio-player-control
          [isPlaying]="rowToAudio(row).id === activeAudio?.id && isActiveAudioPlaying"
          [isLoading]="rowToAudio(row).id === activeAudio?.id && isActiveAudioLoading"
          (play$)="playAudio(rowToAudio(row))"
          (pause$)="pause()"
          size="md"
          pxClickStopPropagation
        />
      </div>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.WAVE_FORM)"
    mdsDataTableRegister
    [resizeable]="false"
    [sortable]="false"
    [width]="getColumnWidth(AudioTableColumns.WAVE_FORM)"
    cellClass="p-0"
    headerClass="p-0"
    let-row="row"
    let-value="value"
    order="8"
  >
    <div class="c-audio-controls c-audio-controls--start p-l">
      <div class="c-audio-controls__item c-audio-controls__item--waveform">
        <mds-waveform
          (seek$)="seekAudio($event, rowToAudio(row))"
          [points]="rowToAudio(row).waveform_data"
          [progress]="row.id === activeAudio?.id ? activeAudioPlayProgress ?? 0 : 0"
          hasProgress
        />
      </div>
    </div>
  </ng-template>

  <ng-template
    *ngIf="isColumnAvailable(AudioTableColumns.IS_FAVORITE)"
    mdsDataTableRegister
    [name]="'Faves' | translate"
    [resizeable]="false"
    [width]="getColumnWidth(AudioTableColumns.IS_FAVORITE)"
    cellClass="p-0"
    prop="is_favorite"
    let-row="row"
    let-value="value"
    order="9"
  >
    <div class="c-audio-controls c-audio-controls--start p-l">
      <div class="c-audio-controls__item">
        <px-button
          (click)="toggleFavorite(row)"
          [active]="rowToAudio(row).is_favorite"
          [puiTooltip]="
            !rowToAudio(row).is_favorite ? ('Add to favorites' | translate) : ('Remove from favorites' | translate)
          "
          type="fabNaked"
          data-qa="favorites button"
          pxClickStopPropagation
        >
          <mds-icon *ngIf="!rowToAudio(row).is_favorite" icon="pxFavoriteBorder" />
          <mds-icon *ngIf="rowToAudio(row).is_favorite" icon="pxFavorite" />
        </px-button>
      </div>
      <div class="c-audio-controls__item">
        <px-button
          (click)="select(row)"
          [puiTooltip]="'Add song to segment' | translate"
          size="md"
          type="fabNakedPrimary"
          data-qa="add song button"
          pxClickStopPropagation
        >
          <mds-icon icon="pxAdd" />
        </px-button>
      </div>
    </div>
  </ng-template>

  <ng-template mdsDataTableEmptyRegister>
    <div class="audio-table__empty">
      <ng-content></ng-content>
    </div>
  </ng-template>

  <ng-template #shufflingHeader let-name let-sortFn="sortFn" let-sortDir="sortDir">
    <div
      class="shuffling-header"
      [ngClass]="getShufflingHeaderSortClass(sortDir)"
      (click)="sortShuffle(sortFn, sortDir)"
    >
      <span class="shuffling-header__name">{{ name }}</span>
      <button class="shuffling-header__sort shuffling-header__sort--asc-desc">
        <mds-icon icon="pxSortDirectionAsc" />
      </button>
      <button class="shuffling-header__sort shuffling-header__sort--shuffle">
        <mds-icon icon="pxShuffle" />
      </button>
    </div>
  </ng-template>
</mds-datatable>
