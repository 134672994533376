import {Pipe, PipeTransform} from '@angular/core';
import {OptionView} from '../intefaces/filter-select-item';
import {FilterTruncatePipe} from './filter-truncate.pipe';

@Pipe({
  name: 'filterTruncated',
})
export class FilterTruncatedPipe implements PipeTransform {
  constructor(private readonly filterTruncatePipe: FilterTruncatePipe) {}

  transform(item: OptionView): string {
    if (!Array.isArray(item.value)) {
      return '';
    }
    if (item.value.length <= 2) {
      return '';
    }

    return this.filterTruncatePipe.transform(item);
  }
}
