import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import {ContentObserver, ObserversModule} from '@angular/cdk/observers';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map} from 'rxjs';
import {NgClass} from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'mds-chip-list',
  standalone: true,
  imports: [NgClass, ObserversModule],
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsChipListComponent implements AfterViewInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly contentObserver = inject(ContentObserver);

  private readonly mutationRecordType: MutationRecordType = 'childList';

  @ViewChild('scrollEl') scrollEl!: ElementRef<HTMLElement>;

  hostClass: string[] = [];

  private initHostClass(): void {
    const maxScrollLeft = this.scrollEl.nativeElement.scrollWidth - this.scrollEl.nativeElement.clientWidth;
    if (this.scrollEl.nativeElement.scrollLeft === 0 && maxScrollLeft > 0) {
      this.hostClass = ['is-scrolled-right'];
    }

    if (this.scrollEl.nativeElement.scrollLeft > 0 && this.scrollEl.nativeElement.scrollLeft < maxScrollLeft) {
      this.hostClass = ['is-scrolled-left', 'is-scrolled-right'];
    }

    if (this.scrollEl.nativeElement.scrollLeft === maxScrollLeft && maxScrollLeft > 0) {
      this.hostClass = ['is-scrolled-left'];
    }

    if (maxScrollLeft === 0 && this.scrollEl.nativeElement.scrollLeft === 0) {
      this.hostClass = [];
    }

    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.contentObserver
      .observe(this.scrollEl.nativeElement)
      .pipe(map((event: MutationRecord[]) => event.filter(record => record.type === this.mutationRecordType)))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onChange());
  }

  onChange(): void {
    this.initHostClass();
  }

  onMouseWheel($event: Event): void {
    $event.preventDefault();
    this.scrollEl.nativeElement.scrollLeft -= ($event as WheelEvent).deltaY / -2;
    this.initHostClass();
  }
}
