<div
  class="audio-browser-view"
  [ngClass]="{
    'audio-browser-view--player-shown': isPlayerShown,
    'audio-browser-view--margin-increased': sidesMarginIncreased
  }"
>
  <div class="audio-browser-view__content">
    <ng-content></ng-content>
  </div>

  <div class="audio-browser-view__player">
    <ng-content select="[audio-player]"></ng-content>
  </div>
</div>
