import sha1 from 'sha1';
import {Preferences} from '../../models/preferences.model';
import {Update} from '@ngrx/entity';
import {Locals, Segment, Slideshow} from '../../models/slideshow.model';
import {SlideShowState} from './slideshow.state';
import {PartialSlideshowSaveResponse} from '../../core/interfaces/slideshow-save-response';
import dayjs, {ConfigTypeMap} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {IAudio, SlideshowState, TransitionTypeId} from '@px/shared/api';
import {Nullable} from '../../core/types/nullable';
import {DeepPartial} from 'ts-essentials';
import {ManagerSlideshow} from '../../models/manager.model';
import {ImagesUrls} from '../../models/user.model';
import {EditorContinueError} from '../../core/enums/editor-continue-error';
import {PSSPlatformEnvironment} from '../../platform-environment';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import findLast from 'lodash/findLast';
import sortBy from 'lodash/sortBy';
import {IPSSTransition} from '../../models/pss-transition';
import {IPSSTemplate} from '../../models/pss-template';
import {IPSSAudio} from '../../models/pss-audio.model';
import {FeatureImage} from '@px/legacy/feature-photo-uploader';

dayjs.extend(utc);

export const audioStartIsValidAndAboveZero = (audio_start: number): boolean =>
  Number.isFinite(audio_start) && audio_start > 0;

export const updateSlideshowAfterPublish = (
  slideshow: SlideShowState,
  response: PartialSlideshowSaveResponse
): SlideShowState => {
  return {
    ...slideshow,
    video_1080p: response.video_1080p || null,
    video_4k: response.video_4k || null,
    build_video_estimated_time: response.build_video_estimated_time || null,
    build_video_4k_estimated_time: response.build_video_4k_estimated_time || null,
    common_data: {
      ...slideshow.common_data,
      state: SlideshowState.PUBLISHED,
    },
    locals: {
      ...slideshow.locals,
    },
  };
};

export const parseSlideshowDate = (slideshow: Slideshow<number>): Slideshow => {
  const commonData = omit(slideshow.common_data, 'event_date');

  const value: Slideshow = {...slideshow, common_data: {...commonData}};

  if (slideshow.common_data?.event_date) {
    const date = dayjs.unix(slideshow.common_data.event_date);

    value.common_data.event_date = date
      .utc()
      // because the PSS CA don't know real tz of user
      // PSS CA shows all dates in GTM (+0 tz), but we should pretend that is date in client tz
      .add(-date.utcOffset(), 'minutes')
      .toDate();
  }

  return value;
};

export const toApiDate = (value: ConfigTypeMap[keyof ConfigTypeMap]): number => {
  const date = dayjs(value);
  return date.utc().add(date.utcOffset(), 'minutes').unix();
};

export const toApiSlideshowDate = (slideshow: Slideshow): Slideshow<number> => {
  const commonData = omit(slideshow.common_data, 'event_date');
  const value: Slideshow<number> = {...slideshow, common_data: {...commonData}};

  if (slideshow.common_data?.event_date != null) {
    value.common_data.event_date = toApiDate(slideshow.common_data.event_date);
  }

  if (slideshow?.common_data?.event_date === null) {
    value.common_data.event_date = null;
  }

  if (!Object.keys(value.common_data).length) {
    delete value.common_data;
  }

  return value;
};

export function getFeatureImageUrl(
  featureImage: FeatureImage,
  userId: number | null,
  imagesUrls: ImagesUrls | null,
  blurUrl = false
): string {
  const platform = PSSPlatformEnvironment.getSelf();

  return blurUrl
    ? platform.IMAGES_PATH +
        platform.SCOPE +
        '/' +
        featureImage.active_storage_service +
        '/2k/1x/u' +
        userId +
        '/' +
        featureImage.image_path +
        '?width=1920&height=1080&' +
        imagesUrls?.imageUrlHParam +
        '&blur=100'
    : imagesUrls?.imageUrl.replace(/STORE/i, featureImage.active_storage_service) +
        featureImage.image_path +
        '?width=1920&height=1080&' +
        imagesUrls?.imageUrlHParam;
}

export function createTransitionListByPhotos(segment: Segment): IPSSTransition[] {
  const {photosIds, is_beatmatching, audio_start, template, audio_end, beat_matching_template} = segment;

  let startIndex = 0;

  if (beat_matching_template && is_beatmatching && audioStartIsValidAndAboveZero(audio_start)) {
    startIndex = beat_matching_template.transitions.findIndex(
      (transition): boolean => transition.median - audio_start >= 102
    );
  }

  return photosIds.map((photo, j) => {
    if (beat_matching_template && is_beatmatching) {
      if (template.transitions[0].type === TransitionTypeId.AUTO) {
        if (j !== photosIds.length - 1) {
          return {
            median: beat_matching_template.transitions[j + startIndex].median,
            type: beat_matching_template.transitions[j + startIndex].type,
            duration: beat_matching_template.transitions[j + startIndex].duration,
          };
        } else {
          return {
            median: audio_end - 60 / 2,
            type: TransitionTypeId.CROSS_FADE,
            duration: 60,
          };
        }
      } else {
        if (j !== photosIds.length - 1) {
          return {
            median: beat_matching_template.transitions[j + startIndex].median,
            type: template.transitions[0].type,
            duration: template.transitions[0].type === TransitionTypeId.HARD_CUT ? 0 : template.transitions[0].duration,
          };
        } else {
          return {
            median:
              audio_end -
              (template.transitions[0].type === TransitionTypeId.HARD_CUT ? 0 : template.transitions[0].duration / 2),
            type: template.transitions[0].type,
            duration: template.transitions[0].type === TransitionTypeId.HARD_CUT ? 0 : template.transitions[0].duration,
          };
        }
      }
    } else {
      return {
        type:
          template.transitions[0].type === TransitionTypeId.AUTO
            ? TransitionTypeId.CROSS_FADE
            : template.transitions[0].type,
        duration: template.transitions[0].type === TransitionTypeId.HARD_CUT ? 0 : template.transitions[0].duration,
      };
    }
  });
}

export function countDurationPerSlideForSegment(
  segment: Segment,
  segmentsList: Segment[]
): {locals: Partial<Locals>; updateDuration: number; updateTransitionId: number} {
  const platform = PSSPlatformEnvironment.getSelf();

  const isLastSegment = segmentsList[segmentsList.length - 1].id === segment.id;
  const isFirstSegment = segmentsList[0].id === segment.id;
  const segmentDuration = segment.audio ? segment.audio_end - segment.audio_start : 0;
  const photosCount = segment.photosIds.length;
  let segmentPhotosDuration = 0;
  let durationPerSlide = 0;
  let transition: IPSSTransition;
  let updateDuration = null;
  let updateTransitionId = null;

  if (photosCount > 0 && segment.template) {
    let [base] = segment.template.transitions;

    // TODO: it takes from user model by default have to be changed
    if (!base) {
      base = {duration: 0, type: TransitionTypeId.HARD_CUT};
    }

    transition = {
      duration: base.type === TransitionTypeId.HARD_CUT ? 0 : base.duration,
      type: base.type,
    };

    if (!segment.is_beatmatching) {
      if (segmentsList.length === 1) {
        segmentPhotosDuration =
          segmentDuration -
          (platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START +
            platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.END);
        durationPerSlide = (segmentPhotosDuration - (photosCount - 1) * transition.duration) / photosCount;
      } else if (isLastSegment) {
        segmentPhotosDuration = segmentDuration - platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.END;
        durationPerSlide = (segmentPhotosDuration - (photosCount - 1) * transition.duration) / photosCount;
      } else if (isFirstSegment) {
        segmentPhotosDuration = segmentDuration - platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START;
        durationPerSlide = segmentPhotosDuration / photosCount - transition.duration;
      } else {
        segmentPhotosDuration = segmentDuration;
        durationPerSlide = segmentPhotosDuration / photosCount - transition.duration;
      }
    } else {
      durationPerSlide = segmentDuration / photosCount;
    }
  }
  const maxTransitionDuration = countMaxTransitionDuration(segment, isFirstSegment, isLastSegment);

  if (
    segment.template &&
    photosCount &&
    transition.type !== 1 &&
    ((durationPerSlide < 0.1 * platform.COMMON_SETTINGS.FRAMES_PER_SEC && !segment.is_beatmatching) ||
      (segment.is_beatmatching && maxTransitionDuration < transition.duration && transition.type !== 7))
  ) {
    for (const segmentTransition of segment.template.transitions) {
      updateDuration = Math.min(maxTransitionDuration, segmentTransition.duration);
      if (maxTransitionDuration === TransitionTypeId.CROSS_FADE || transition.type === TransitionTypeId.AUTO) {
        updateTransitionId = TransitionTypeId.HARD_CUT;
      }
    }
  }

  return {
    locals: {
      durationPerSlide,
      maxTransitionDuration,
    },
    updateDuration,
    updateTransitionId,
  };
}

export function countMaxTransitionDuration(segment: Segment, isFirstSegment: boolean, isLastSegment: boolean): number {
  const platform = PSSPlatformEnvironment.getSelf();
  const photosCount = segment.photosIds.length,
    selectedSegmentDuration = segment.audio ? segment.audio_end - segment.audio_start : 0;
  let maxTransitionTime;
  if (!photosCount) {
    return 5 * platform.COMMON_SETTINGS.FRAMES_PER_SEC;
  }
  if (segment.is_beatmatching && segment.beat_matching_template) {
    if (!segment.beat_matching_template.transitions) {
      return 5 * platform.COMMON_SETTINGS.FRAMES_PER_SEC;
    }

    const audioStartIsValidAndAboveZero = Number.isFinite(segment.audio_start) && Math.round(segment.audio_start) > 0;

    let startIndex = 0;

    if (audioStartIsValidAndAboveZero) {
      startIndex = segment.beat_matching_template.transitions.findIndex(
        (transition): boolean => transition.median - segment.audio_start >= 102
      );
    }

    let endIndex = photosCount + startIndex - 2;

    if (Math.round(segment.audio_end) === segment.audio.length) {
      endIndex = segment.beat_matching_template.transitions.length - 1;
    }

    maxTransitionTime = reduce(
      segment.beat_matching_template.transitions,
      (result, transition, index) => {
        let durationBetweenTransitions =
          index >= startIndex && index <= endIndex
            ? transition.median -
              result.prevEndTransition -
              platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION
            : result.minDuration;
        if (index === startIndex && isFirstSegment) {
          durationBetweenTransitions = Math.max(
            (transition.median -
              result.prevEndTransition -
              platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START -
              platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION) *
              2,
            0
          );
        }
        return {
          prevEndTransition: transition.median,
          minDuration: result.minDuration
            ? Math.min(result.minDuration, durationBetweenTransitions)
            : durationBetweenTransitions,
        };
      },
      {prevEndTransition: 0, minDuration: null}
    ).minDuration;
    // Last transition in segment
    maxTransitionTime = Math.min(
      maxTransitionTime,
      isLastSegment
        ? 2 *
            (segment.audio_end -
              segment.beat_matching_template.transitions[endIndex].median -
              platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION -
              108)
        : (segment.audio_end -
            segment.beat_matching_template.transitions[endIndex].median -
            platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION) /
            1.5
    );
  } else {
    maxTransitionTime =
      (selectedSegmentDuration -
        ((isFirstSegment ? platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.START : 0) +
          (isLastSegment ? platform.COMMON_SETTINGS.FADE_TRANSITION_DURATION.END : 0)) -
        0.1 * platform.COMMON_SETTINGS.FRAMES_PER_SEC * photosCount) /
      Math.max(1, photosCount - 1 * (isLastSegment ? 1 : 0));
  }
  return Math.max(
    0,
    Math.min(
      5 * platform.COMMON_SETTINGS.FRAMES_PER_SEC,
      (Math.floor((2 * maxTransitionTime) / platform.COMMON_SETTINGS.FRAMES_PER_SEC) / 2) *
        platform.COMMON_SETTINGS.FRAMES_PER_SEC
    )
  );
}

export function countTrimPoint(template: IPSSTemplate, {audio_start, audio_end, photosIds, audio}: Segment): number {
  const platform = PSSPlatformEnvironment.getSelf();

  const MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT =
    platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT;
  const MIN_LENGTH_FROM_END_TO_LAST_BM_POINT =
    platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_LENGTH_FROM_END_TO_LAST_BM_POINT;
  let trimPoint = audio_end;
  let startIndex: number;

  if (photosIds.length === 1) {
    for (let i = template.transitions.length - 1; i >= 0; i--) {
      if (
        audio_start +
          MIN_LENGTH_FROM_END_TO_LAST_BM_POINT +
          MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT +
          platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION <=
        template.transitions[i].median
      ) {
        startIndex = i;
        trimPoint = template.transitions[i].median;
      } else {
        /* if length from trim point to audio start is smaller than min length (MIN_LENGTH_FROM_END_TO_LAST_BM_POINT+
          MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT+AppConstants.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION)
          then we found trim point in previous loop step */
        break;
      }
    }
  } else {
    let trimIndex: number;
    let endIndex: number;
    startIndex = 0;

    if (audioStartIsValidAndAboveZero(audio_start)) {
      startIndex = template.transitions.findIndex(
        ({median}) => median - audio_start >= MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT
      );
    }

    /* All BM markers within 2.5s from the trim marker are ignored
    The last image will last at least 2.5s */
    for (let i = template.transitions.length - 1; i >= 0; i--) {
      if (
        template.transitions[startIndex + photosIds.length - 2].median + MIN_LENGTH_FROM_END_TO_LAST_BM_POINT <=
        template.transitions[i].median
      ) {
        trimPoint = template.transitions[i].median;
        trimIndex = i;
      } else {
        break;
      }
    }

    for (let i = template.transitions.length - 1; i >= 0; i--) {
      if (trimPoint - template.transitions[i].median >= MIN_LENGTH_FROM_END_TO_LAST_BM_POINT) {
        endIndex = i;
        break;
      }
    }

    if (trimIndex && endIndex && endIndex - startIndex + 2 !== photosIds.length) {
      trimPoint = template.transitions[startIndex + photosIds.length - 2].median + MIN_LENGTH_FROM_END_TO_LAST_BM_POINT;
    }
  }

  // If no closest beat
  if (trimPoint === audio.length && startIndex != null) {
    trimPoint = Math.min(
      audio_end,
      template.transitions[startIndex + photosIds.length - 2].median + MIN_LENGTH_FROM_END_TO_LAST_BM_POINT
    );
  }

  return trimPoint;
}

export function getSlideshowPreviewHost(preferences: Preferences, createEmbedUrl?: boolean): string {
  if (!preferences) {
    return '';
  }

  const platform = PSSPlatformEnvironment.getSelf();

  return preferences.custom_domain && !createEmbedUrl
    ? `https://${preferences.custom_domain}/`
    : `https://${preferences.display_name}.${platform.HOST}/`;
}

export function getSlideshowPreviewUrl(slug: string, preferences: Preferences, createEmbedUrl?: boolean): string {
  if (!preferences) {
    return '';
  }

  const host = getSlideshowPreviewHost(preferences, createEmbedUrl);
  return `${host}${slug}`;
}

export function getSlideshowOrderUpdate(slideshowList, insertOrder): Update<ManagerSlideshow>[] {
  return slideshowList.reduce((res, slideshow) => {
    return slideshow._order === insertOrder
      ? [
          ...res,
          {
            id: slideshow.unique_identifier,
            changes: {
              _order: slideshow._order + 1,
            },
          },
          ...getSlideshowOrderUpdate(slideshowList, insertOrder + 1),
        ]
      : res;
  }, []);
}

export function checkSlideshowError(slideshowSegments: Segment[]): EditorContinueError {
  const platform = PSSPlatformEnvironment.getSelf();

  let notCorrectDataValue = EditorContinueError.NO_ERROR;
  let slideshowLength = 0;

  if (slideshowSegments.some(item => !item.audio)) {
    return EditorContinueError.NO_SONG;
  }

  for (const segment of slideshowSegments) {
    if (segment.photosIds.length === 0) {
      notCorrectDataValue = EditorContinueError.EMPTY_PHOTO_LIST;
    } else if (segment.locals.durationPerSlide < platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SLIDE_DURATION) {
      notCorrectDataValue = EditorContinueError.MIN_DURATION;
    }

    slideshowLength += segment.audio_end - segment.audio_start;
  }

  if (!notCorrectDataValue && platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MAX_DURATION < slideshowLength) {
    notCorrectDataValue = EditorContinueError.MAX_DURATION;
  }

  return notCorrectDataValue;
}

export function getSlideshowSongsExpireTime(slideshowSegments: Segment[]): number {
  let slideshowSongWillExpire = 0;

  for (const segment of slideshowSegments) {
    if (segment.audio && !segment.is_published) {
      if (segment.audio?.expired) {
        slideshowSongWillExpire = new Date().getTime();
      } else if (segment.audio?.expires_at) {
        if (slideshowSongWillExpire > 0) {
          slideshowSongWillExpire = Math.min(
            slideshowSongWillExpire,
            segment.audio?.expires_at + new Date().getTimezoneOffset() * 60000
          );
        } else {
          slideshowSongWillExpire = segment.audio?.expires_at + new Date().getTimezoneOffset() * 60000;
        }
      }
    }
  }

  return slideshowSongWillExpire;
}

export function getActiveTemplatesForSegment(
  audioStart: number,
  audioEnd: number,
  audioLength: number,
  templates: IPSSTemplate[]
): IPSSTemplate[] {
  if (!templates.length) {
    return [];
  }

  const fn = matchingImagesCount.bind(null, audioStart, audioEnd, audioLength);

  return templates.reduce((acc: IPSSTemplate[], template: IPSSTemplate): IPSSTemplate[] => {
    template.matching_images_calculated = fn(template);

    acc.push(template);

    return acc;
  }, []);
}

export function getUploadFileUrl(file, signature): string {
  const folder = sha1('' + new Date().getTime()).substring(0, 30); // length of folder name no more than 30 symbols
  const ext = file.name.slice(file.name.lastIndexOf('.') + 1);
  const name = file.name.slice(0, file.name.lastIndexOf('.'));
  const filename = sha1(name).substring(0, 20); // length of file name no more than 20 symbols
  return signature.bucket_object_prefix + '/' + folder + '/' + filename + '.' + ext.toLowerCase();
}

export function getValueIfExist<T extends object>(obj: T, prop: keyof T, fallback?: T[keyof T]): T[keyof T] {
  return prop in obj ? obj[prop] : fallback;
}

export function getSlideshowValueIfExist<T = DeepPartial<Slideshow>>(
  slideshow: T
): (prop: keyof T, fallback?: T[keyof T]) => Nullable<T[keyof T]> {
  return getValueIfExist.bind(null, slideshow);
}

export const matchingImagesCount = (
  audioStart: number,
  audioEnd: number,
  audioLength?: number,
  template?: IPSSTemplate
): number => {
  if (!audioLength) {
    return 0;
  }

  if (!template?.is_custom) {
    let transitionData = template.transitions ?? [];

    if (audioStart > 0) {
      const audioStartFrame = audioStart;
      let sliceTransitionsIndex = 0;

      for (let i = 0; i <= transitionData.length; i++) {
        const transitionStart = transitionData[i].median - audioStartFrame;

        if (transitionStart >= 102) {
          sliceTransitionsIndex = i;
          break;
        }
      }

      transitionData = transitionData.slice(sliceTransitionsIndex);
    }

    if (audioEnd !== audioLength) {
      const audioEndFrame = audioEnd;
      let sliceTransitionsIndex = 0;

      for (let i = transitionData.length - 1; i--; i >= 0) {
        const transitionEnd = audioEndFrame - transitionData[i].median;

        if (transitionEnd >= 150) {
          sliceTransitionsIndex = i + 1;
          break;
        }
      }
      transitionData = transitionData.slice(0, sliceTransitionsIndex);
    }

    if (transitionData.length) {
      return transitionData.length + 1;
    }

    return 0;
  }

  return template.matching_images;
};

export const convertAudioToPSSAudio = (audio: IAudio): IPSSAudio => {
  const {templates} = audio;
  const pssAudio: IPSSAudio = {...audio, activeTemplates: []};

  const activeTemplates = orderBy(
    filter(templates, template => {
      if (template.is_custom) {
        return false;
      } else {
        const lastTemplate = findLast(sortBy(audio.templates, 'version'), t => {
          return t.is_custom === false && t.tempo_type === template.tempo_type;
        });
        return lastTemplate && lastTemplate.id === template.id;
      }
    }),
    'matching_images'
  );

  pssAudio.activeTemplates = activeTemplates.length ? activeTemplates : [];

  return pssAudio;
};
