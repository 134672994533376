import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'mds-dialog-container',
  standalone: true,
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsDialogContainerComponent {
  @Input() maxWidth?: number | string;
  @Input() paddingY?: number | string;
  @Input() paddingX?: number | string;
}
