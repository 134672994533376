<div data-qa="tabs" class="c-tabs c-tabs-base" [ngClass]="{'c-tabs--full-height': fullHeight}">
  <div class="c-tabs__header" [ngClass]="{'c-tabs__header--centered': centered}">
    <ng-container *ngFor="let tab of tabs; let i = index; trackBy: trackByIndex">
      <div
        data-qa="tab-title"
        class="c-tabs__title"
        (click)="setActiveTab(i)"
        (keydown.enter)="setActiveTab(i)"
        role="button"
        tabIndex="0"
        [ngClass]="{
          'is-active': tab.active,
          'is-disabled': tab.disabled,
          'is-full-width': tab.fullWidth,
          'is-ltr': selectedIndex && !tab.active && selectedIndex > i
        }"
      >
        <span class="c-tabs__title-inner" [attr.data-qa]="tab.dataQa ? 'tab-title-' + tab.dataQa : ''">
          <ng-template [ngIf]="tab.titleContent">
            <ng-template [cdkPortalOutlet]="tab.titleContent"></ng-template>
          </ng-template>
          <ng-template [ngIf]="!tab.titleContent">{{ tab.title }}</ng-template>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="c-tabs__body">
    <ng-container *ngFor="let tab of tabs; let i = index; trackBy: trackByIndex">
      <div
        [attr.data-qa]="tab.dataQa ? 'tab-content-' + tab.dataQa : ''"
        class="c-tabs__content"
        [@fadeInOut]="tab.active"
        (@fadeInOut.done)="onTabFadeStateChange(i, $event)"
      >
        <ng-template mdsTabOutlet [tab]="tab"></ng-template>
      </div>
    </ng-container>
  </div>
</div>
