<mds-dialog-container
  maxWidth="609"
  paddingY="12"
  [attr.aria-label]="'MUSIC_UPLOAD_PAGE.MUSIC_UPLOAD_LABEL' | translate"
>
  <p *ngIf="infoPhrase" class="info-phrase loading_dots">
    {{ infoPhrase | translate }}
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </p>
  <mds-dialog-content>
    <div *ngIf="progress$" class="upload-progress">
      <div class="upload-progress-bar" role="progressbar" [style.width.%]="progress$ | async"></div>
    </div>
    <div *ngIf="label" class="music-file-name textEllipsis">{{ 'GLOBAL.UPLOADING_LABEL' | translate }} {{ label }}</div>
  </mds-dialog-content>
</mds-dialog-container>
