<div class="songs-by-category">
  <px-audio-browser-view [isPlayerShown]="!!audioPlayerController.playingAudio" [sidesMarginIncreased]="true">
    <px-button *ngIf="!isLoading" class="songs-by-category__shift-right" type="fabGray" (click$)="startShiftingRight()">
      <mds-icon class="songs-by-category__shift-icon" icon="pxChevronLeft" />
    </px-button>
    <px-button
      *ngIf="isShiftLeftShown && !isLoading"
      class="songs-by-category__shift-left"
      type="fabGray"
      (click$)="startShiftLeft()"
    >
      <mds-icon class="songs-by-category__shift-icon" icon="pxChevronLeft" />
    </px-button>

    <div
      *ngIf="!isLoading; else skeletonTmpl"
      class="songs-by-category__list"
      [@shifting]="shiftingState"
      (@shifting.done)="shiftingDone($event)"
    >
      <px-top-songs-category
        *ngFor="let categoryId of topSongsCategories; let i = index"
        [ngStyle]="{order: categoriesShiftedOrder[i]}"
        [id]="categoryId"
        [fadedAudiosIds]="fadedAudios"
        [coverUrl]="categoriesConfig[categoryId]?.thumbnailImgUrl"
        [audios]="audiosByCategoryState[categoryId]?.audios ?? []"
        [favoritesCount]="audiosByCategoryState[categoryId]?.favorites?.length ?? 0"
        [isAudioLoading]="audioPlayerController.isAudioLoading"
        [isAudioPlaying]="audioPlayerController.isAudioPlaying"
        [playingAudioId]="audioPlayerController.playingAudio?.id"
        (playAudio$)="audioPlayerController.playAudio($event)"
        (pause$)="audioPlayerController.pause()"
        (scrollEnd$)="onCategoryScrollEnd(categoryId)"
        (favoriteChange$)="toggleFavorite($event)"
        (select$)="selectAudio($event, AudioSelectionSource.TOP_SONGS_TAB_ROW)"
        (thumbnailClicked$)="onCategoryThumbnailClick(categoryId)"
      />
    </div>

    <ng-template #skeletonTmpl>
      <px-top-songs-skeleton
        [categoriesCount]="topSongsCategories.length"
        [tracksCount]="tracksPerPage"
      />
    </ng-template>

    <px-audio-player
      audio-player
      *ngIf="audioPlayerController.playingAudio"
      class="songs-by-category__player"
      (seekAudio$)="audioPlayerController.seek($event)"
      (playAudio$)="audioPlayerController.playAudio(audioPlayerController.playingAudio)"
      (pauseAudio$)="audioPlayerController.pause()"
      (nextAudio$)="audioPlayerController.playNext()"
      (prevAudio$)="audioPlayerController.playPrev()"
      (selectAudio$)="selectAudio(audioPlayerController.playingAudio, AudioSelectionSource.TOP_SONGS_TAB_PLAYER)"
      (toggleFavoriteAudio$)="toggleFavorite(audioPlayerController.playingAudio)"
      [audio]="audioPlayerController.playingAudio"
      [titleTruncateLength]="35"
      [isPlaying]="audioPlayerController.isAudioPlaying"
      [isLoading]="audioPlayerController.isAudioLoading"
      [progress]="audioPlayerController.playingProgress"
      [playPositionInfo]="audioPlayerController.playPositionInfo"
    />
  </px-audio-browser-view>
</div>
