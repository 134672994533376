import {Pipe, PipeTransform} from '@angular/core';
import {pluck} from 'ramda';

@Pipe({
  name: 'pluck',
  pure: true,
})
export class PluckPipe implements PipeTransform {
  transform<T, P extends keyof T>(value: T[], property: P): T[P][] {
    return pluck(property, value);
  }
}
