import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateCenter',
})
export class TruncateCenterPipe implements PipeTransform {
  private isEven(value: number | string): boolean {
    return !(Number(value) % 2);
  }

  transform(value: string | null, targetLength: number, center = '...'): string {
    if (!value || value.length <= targetLength) {
      return value ?? '';
    }

    let right: string;
    let targetLengthHalf: number;
    const stringMiddle = value.length / 2;

    if (this.isEven(center.length)) {
      targetLengthHalf = (targetLength - center.length) / 2;

      right = value.substring(stringMiddle).substring(value.substring(stringMiddle).length - targetLengthHalf);
    } else {
      targetLengthHalf = Math.floor((targetLength - center.length) / 2);

      if (this.isEven(targetLength)) {
        right = value.substring(stringMiddle).substring(value.substring(stringMiddle).length - (targetLengthHalf + 1));
      } else {
        right = value.substring(stringMiddle).substring(value.substring(stringMiddle).length - targetLengthHalf);
      }
    }

    const left = value.substring(0, targetLengthHalf);
    return left.concat(center).concat(right);
  }
}
