import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MdsWaveformComponent} from '@mds/components/waveform';
import {TranslateModule} from '@ngx-translate/core';
import {AutofocusModule} from '@pui/cdk/autofocus';
import {MdsCheckboxComponent} from '@pui/components/checkbox';
import {MdsChipComponent, MdsChipListComponent} from '@pui/components/chip';
import {MdsDatatableModule} from '@pui/components/datatable';
import {MDS_DIALOG_PROVIDER} from '@pui/components/dialog';
import {MdsDropdownModule} from '@pui/components/dropdown';
import {PuiHintComponent} from '@pui/components/hint';
import {MdsIconComponent} from '@pui/components/icon-core';
import {LoadingIndicatorsModule} from '@pui/components/loading-indicators';
import {SkeletonModule} from '@pui/components/skeleton';
import {MdsTabsModule} from '@pui/components/tabs';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import {AudioDomainModule, UPLOADING_FINISHED_DELAY} from '@px/audio-domain';
import {CdkDataTransformModule} from '@px/cdk-data-transform';
import {
  AudioFile,
  FILE_FACTORY_CONFIG,
  FeatureFileUploadModule,
  IFileFactoryConfig,
} from '@px/shared-data-access-file-upload';
import {AudioPlayerModule} from '@px/shared/audio-player';
import {BrowserService} from '@px/shared/browser';
import {PlatformEnvironment} from '@px/shared/env';
import {FileDragSelectorModule} from '@px/shared/file-drag-selector';
import {UiButtonModule} from '@px/ui-components-px-button';
import {UiHintModule} from '@px/ui/components/shared/hint';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AudioBrowserTabbedComponent} from './components/audio-browser-tabbed/audio-browser-tabbed.component';
import {AudioBrowserViewComponent} from './components/audio-browser-view/audio-browser-view.component';
import {AudioCategoryThumbnailComponent} from './components/audio-category-cover/audio-category-thumbnail.component';
import {AudioDropzoneComponent} from './components/audio-dropzone/audio-dropzone.component';
import {AudioEmptyComponent} from './components/audio-empty/audio-empty.component';
import {AudioFavoritesComponent} from './components/audio-favorites/audio-favorites.component';
import {AudioFiltersComponent} from './components/audio-filters/audio-filters.component';
import {AudioListItemComponent} from './components/audio-list-item/audio-list-item.component';
import {AudioLyricsComponent} from './components/audio-lyrics/audio-lyrics.component';
import {AudioPlayerControlComponent} from './components/audio-player-control/audio-player-control.component';
import {AudioPlayerComponent} from './components/audio-player/audio-player.component';
import {AudioSearchBarComponent} from './components/audio-search-bar/audio-search-bar.component';
import {AudioSearchComponent} from './components/audio-search/audio-search.component';
import {AudioTableComponent} from './components/audio-table/audio-table.component';
import {AudioTempoInfoComponent} from './components/audio-tempo-info/audio-tempo-info.component';
import {AudioTitleComponent} from './components/audio-title/audio-title.component';
import {AudioUploadsComponent} from './components/audio-uploads/audio-uploads.component';
import {TopSongsCategoryComponent} from './components/category-top-songs/top-songs-category.component';
import {TopSongsSkeletonComponent} from './components/top-songs-skeleton/top-songs-skeleton.component';
import {TopSongsComponent} from './components/top-songs/top-songs.component';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {AudioCategoryPipe} from './pipes/audio-category.pipe';
import {AudioEnergyPipe} from './pipes/audio-energy.pipe';
import {FilterFormatPipe} from './pipes/filter-format.pipe';
import {FilterTruncatePipe} from './pipes/filter-truncate.pipe';
import {FilterTruncatedPipe} from './pipes/filter-truncated.pipe';
import {SlidePerSecondFormatPipe} from './pipes/slide-per-second-format.pipe';
import {TempoPipe} from './pipes/tempo.pipe';
import {AudioBrowserStateService} from './services/audio-browser-state.service';

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    TranslateModule,
    FormsModule,
    NgxDatatableModule,
    AudioPlayerModule,
    AudioDomainModule,
    MatDialogModule,
    MatButtonModule,
    FileDragSelectorModule,
    UiHintModule,
    AutofocusModule,
    ReactiveFormsModule,
    FeatureFileUploadModule,
    CdkDataTransformModule,
    MdsTabsModule,
    LoadingIndicatorsModule,
    SkeletonModule,
    PuiTooltipDirective,
    MdsDropdownModule,
    MdsChipListComponent,
    MdsChipComponent,
    MdsWaveformComponent,
    MdsDatatableModule,
    MdsCheckboxComponent,
    MdsIconComponent,
    PuiHintComponent,
  ],
  declarations: [
    AudioLyricsComponent,
    AudioDropzoneComponent,
    AudioPlayerComponent,
    AudioEmptyComponent,
    AudioSearchBarComponent,
    AudioTitleComponent,
    AudioTempoInfoComponent,
    SlidePerSecondFormatPipe,
    FilterFormatPipe,
    FilterTruncatePipe,
    FilterTruncatedPipe,
    AudioFiltersComponent,
    AudioPlayerControlComponent,
    ClickStopPropagationDirective,
    AudioBrowserTabbedComponent,
    TopSongsComponent,
    TopSongsCategoryComponent,
    AudioCategoryThumbnailComponent,
    AudioListItemComponent,
    AudioTableComponent,
    AudioSearchComponent,
    AudioBrowserViewComponent,
    AudioFavoritesComponent,
    AudioUploadsComponent,
    TopSongsSkeletonComponent,
    AudioCategoryPipe,
    AudioEnergyPipe,
    TempoPipe,
  ],
  exports: [AudioCategoryPipe, AudioEnergyPipe, TempoPipe],
  providers: [
    MDS_DIALOG_PROVIDER,
    AudioBrowserStateService,
    SlidePerSecondFormatPipe,
    FilterFormatPipe,
    FilterTruncatePipe,
    FilterTruncatedPipe,
    DatePipe,
    AudioCategoryPipe,
    AudioEnergyPipe,
    TempoPipe,
    BrowserService,
    {
      provide: FILE_FACTORY_CONFIG,
      useFactory: (platformEnv: PlatformEnvironment): IFileFactoryConfig => {
        return {
          fileExtensionMap: new Map([[AudioFile, platformEnv.AVAILABLE_FILES_EXTENSIONS.audio ?? []]]),
        };
      },
      deps: [PlatformEnvironment],
    },
    {
      provide: UPLOADING_FINISHED_DELAY,
      useValue: 700,
    },
  ],
})
export class AudioBrowserFeatureModule {}
