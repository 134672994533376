import {Injectable} from '@angular/core';
import {
  SessionCreationErrors,
  SessionCreationForbidden,
  UnknownSessionCreationError,
} from '../entities/session-creation-errors';
import {ApolloError} from '@apollo/client';
import {HttpErrorResponse} from '@angular/common/http';
import {ISessionCreationErrorTransformer} from '../entities/session-creation-error-transformer';

@Injectable()
export class SessionCreationErrorTransformerService implements ISessionCreationErrorTransformer {
  private isForbiddenGQLError(e: unknown): boolean {
    return (
      e instanceof ApolloError && !!e.graphQLErrors.length && e.graphQLErrors[0].extensions['code'] === 'FORBIDDEN'
    );
  }

  private isForbiddenNetworkError(e: unknown): boolean {
    return (
      e instanceof ApolloError &&
      e.networkError instanceof HttpErrorResponse &&
      [401, 403].includes(e.networkError.status)
    );
  }

  private isForbidden(e: unknown): boolean {
    return this.isForbiddenGQLError(e) || this.isForbiddenNetworkError(e);
  }

  transform(e: unknown): SessionCreationErrors {
    if (this.isForbidden(e)) {
      return new SessionCreationForbidden();
    }
    return new UnknownSessionCreationError();
  }
}
