import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OptionView} from '../../intefaces/filter-select-item';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl} from '@angular/forms';
import {LocalStorageService} from '@px/shared-data-access-local-storage';

@UntilDestroy()
@Component({
  selector: 'px-audio-search-bar',
  templateUrl: './audio-search-bar.component.html',
  styleUrls: ['./audio-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioSearchBarComponent implements OnInit {
  private static readonly INPUT_CHANGE_DEBOUNCE_MS = 500;

  private readonly translate = inject(TranslateService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly localStorage = inject(LocalStorageService);

  private readonly FILTER_HINT_KEY = 'new-music';
  private readonly INPUT_PLACEHOLDER = this.translate.instant('Search songs');
  private readonly INPUT_PLACEHOLDER_FOCUSED = this.translate.instant(
    'Search by: image quantity, song title, or artist name'
  );

  formControl = new UntypedFormControl('', {updateOn: 'change'});
  inputPlaceholder: string = this.INPUT_PLACEHOLDER;

  isFilterHintShown = !!this.localStorage.getSerialized<boolean>(this.FILTER_HINT_KEY);

  @Input() set searchQuery(value: string) {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }
  @Input() options: OptionView[] = [];
  @Input() showFilterHint = false;
  @Input() searchActionTooltip = this.translate.instant('Search');
  @Input() clearActionTooltip = this.translate.instant('Clear');

  @Input() set isFocused(value: boolean | undefined) {
    if (value === undefined) {
      return;
    } else if (value) {
      (this.inputRef?.nativeElement as HTMLInputElement).focus();
    } else {
      (this.inputRef?.nativeElement as HTMLInputElement).blur();
    }
  }

  @Output() searchQuery$ = new EventEmitter<string>();
  @Output() removeOption$ = new EventEmitter<string>();
  @Output() searchActionClick$ = new EventEmitter<void>();

  @ViewChild('input') inputRef?: ElementRef;

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(AudioSearchBarComponent.INPUT_CHANGE_DEBOUNCE_MS),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(this.searchQuery$);
  }

  togglePlaceholder(focused: boolean): void {
    this.inputPlaceholder = focused ? this.INPUT_PLACEHOLDER_FOCUSED : this.INPUT_PLACEHOLDER;
    this.cdr.detectChanges();
  }

  removeOption(key: string): void {
    this.removeOption$.emit(key);
  }

  saveHintAsSeen(): void {
    this.localStorage.setSerialized(this.FILTER_HINT_KEY, true);
  }
}
