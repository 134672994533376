import {AnimationEvent, animate, state, style, transition, trigger} from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  booleanAttribute,
} from '@angular/core';
import {utilTrackByIndex} from '@pui/cdk/track-by';
import {Subject, takeUntil} from 'rxjs';
import {MdsTabComponent} from '../tab/tab.component';

export class MdsTabChange {
  index?: number;
  tab?: MdsTabComponent;
}

const DEFAULT_DURATION = 300;

@Component({
  selector: 'mds-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('false', style({opacity: '0', visibility: 'hidden', position: 'absolute'})),
      state('true', style({opacity: '1', visibility: 'visible', position: 'relative'})),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease')),
    ]),
  ],
})
export class MdsTabsComponent implements AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private activeTabIndexInternal: number | undefined;

  @ContentChildren(MdsTabComponent) tabs?: QueryList<MdsTabComponent>;

  @Output() activeTabChange = new EventEmitter<MdsTabChange>();
  @Output() tabsFadeStateChange$ = new EventEmitter<Record<number, boolean>>();

  trackByIndex = utilTrackByIndex;

  prevSelectedIndex?: number;
  selectedIndex?: number;

  @Input({transform: booleanAttribute}) centered = true;
  @Input({transform: booleanAttribute}) fullHeight = true;

  @Input()
  get activeTabIndex(): number | undefined {
    return this.activeTabIndexInternal;
  }

  set activeTabIndex(value: number | undefined) {
    if (Number.isFinite(Number(value)) && value !== this.activeTabIndexInternal && typeof value !== 'undefined') {
      this.activeTabIndexInternal = value;
      this.setActiveTab(value);
    }
  }

  @HostBinding('style.height')
  get height(): string {
    return this.fullHeight ? '100%' : 'auto';
  }

  private getTabChangeEvent(index: number, tab: MdsTabComponent): MdsTabChange {
    const event = new MdsTabChange();
    event.index = index;
    event.tab = tab;

    return event;
  }

  ngAfterContentInit(): void {
    const firstActiveTabIndex = this.tabs?.toArray().findIndex(tab => !tab.disabled);
    if (this.activeTabIndex !== undefined && this.activeTabIndex > 0) {
      this.setActiveTab(this.activeTabIndex);
    } else {
      if (firstActiveTabIndex !== undefined) {
        this.setActiveTab(firstActiveTabIndex);
      }
    }
    this.tabs?.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const hasActiveTab = this.tabs?.find(tab => tab.active);

      if (!hasActiveTab && this.selectedIndex !== undefined) {
        const closestTabIndex = this.getClosestTabIndex(this.selectedIndex);

        if (closestTabIndex !== -1) {
          this.setActiveTab(closestTabIndex);
        }
      }
    });
  }

  setActiveTab(index: number): void {
    const activeTab = this.tabs?.toArray()[index];
    if (!activeTab || (activeTab && activeTab.disabled)) {
      return;
    }

    if (typeof this.selectedIndex !== 'undefined') {
      this.prevSelectedIndex = this.selectedIndex;
    }

    this.tabs?.forEach(tab => (tab.active = tab === activeTab));
    this.selectedIndex = index;

    const tabChangeEvent = this.getTabChangeEvent(index, activeTab);
    this.activeTabChange.emit(tabChangeEvent);
  }

  getClosestTabIndex(index: number): number {
    const tabs = this.tabs?.toArray();
    const tabsLength = tabs?.length;
    if (!tabsLength) {
      return -1;
    }

    for (let i = 1; i <= tabsLength; i += 1) {
      const prevIndex = index - i;
      const nextIndex = index + i;
      if (tabs[prevIndex] && !tabs[prevIndex].disabled) {
        return prevIndex;
      }
      if (tabs[nextIndex] && !tabs[nextIndex].disabled) {
        return nextIndex;
      }
    }

    return -1;
  }

  onTabFadeStateChange(index: number, event: AnimationEvent): void {
    if (event.toState !== 'void') {
      this.tabsFadeStateChange$.emit({[index]: event.toState as unknown as boolean}); //Angular bug. event.toState is a boolean but type says that it's a string.
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
