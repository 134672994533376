import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'px-audio-category-thumbnail',
  templateUrl: './audio-category-thumbnail.component.html',
  styleUrls: ['./audio-category-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioCategoryThumbnailComponent {
  @Input() title?: string;
  @Input() imgUrl?: string;
  @Input() subtitle?: string;
}
