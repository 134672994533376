import {Pipe, PipeTransform} from '@angular/core';
import {OptionView} from '../intefaces/filter-select-item';
import {AudioEnergyPipe} from '../pipes/audio-energy.pipe';
import {AudioCategoryPipe} from '../pipes/audio-category.pipe';
import {FilterNames} from '@px/audio-domain';

@Pipe({
  name: 'filterTruncate',
})
export class FilterTruncatePipe implements PipeTransform {
  constructor(
    private readonly audioEnergy: AudioEnergyPipe,
    private readonly audioCategory: AudioCategoryPipe
  ) {}

  transform(item: OptionView, deleteCount = 0): string {
    if (!Array.isArray(item.value)) {
      return '';
    }

    let suffix = '';
    if (item.value.length > 2 && deleteCount) {
      suffix = '...';
    }

    return item.value
      .slice(0, deleteCount === 0 ? item.value.length : deleteCount)
      .map(value => {
        switch (item.key) {
          case FilterNames.CATEGORY:
            return this.audioCategory.transform(value.value);

          case FilterNames.ENERGY:
            return this.audioEnergy.transform(value.value);

          default:
            return value.label ?? '';
        }
      })
      .join(', ')
      .concat(suffix);
  }
}
