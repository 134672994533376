import {AudioEnergy} from '../enums/audio-energy.enum';

/** @deprecated used only for legacy compatibility */
export const AUDIO_ENERGY_TO_ID = new Map<AudioEnergy, number>([
  [AudioEnergy.HIGH, 4],
  [AudioEnergy.LOW, 0],
  [AudioEnergy.LOW_MEDIUM, 1],
  [AudioEnergy.MEDIUM, 2],
  [AudioEnergy.MEDIUM_HIGH, 3],
  [AudioEnergy.HIGH, 4],
]);

/** @deprecated used only for legacy compatibility */
export const ID_TO_AUDIO_ENERGY = new Map(
  [...AUDIO_ENERGY_TO_ID.entries()].map(entry => entry.reverse() as [number, AudioEnergy])
);
