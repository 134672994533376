import {NgClass, NgIf} from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MdsIconComponent} from '@pui/components/icon-core';
import {MdsCheckboxColor, MdsCheckboxSize} from './checkbox.types';

@Component({
  selector: 'mds-checkbox',
  standalone: true,
  imports: [NgIf, NgClass, FormsModule, MdsIconComponent],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsCheckboxComponent implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);

  hostClass: string[] = [];

  @Output() changes$ = new EventEmitter<boolean>();

  @Input() inlined = false;
  @Input() color: MdsCheckboxColor = 'base';
  @Input() size: MdsCheckboxSize = 'base';
  @Input() isIntermediate = false;
  @Input({transform: booleanAttribute}) disabled = false;
  @Input({transform: booleanAttribute}) readOnly = false;
  @Input({transform: booleanAttribute}) checked = false;

  ngOnInit(): void {
    this.initHostClass();
  }

  initHostClass(): void {
    switch (this.color) {
      case 'primary':
        this.hostClass.push('checkbox--primary');
        break;

      case 'secondary':
        this.hostClass.push('checkbox--secondary');
        break;

      case 'base':
      default:
        break;
    }

    this.cdr.detectChanges();
  }

  change(value: boolean): void {
    this.changes$.emit(value);
  }
}
