<div>
  <div mat-dialog-title *ngIf="dialogData.title">{{ dialogData.title }}</div>
  <div mat-dialog-content>
    <div class="content" *ngIf="dialogData.content">
      <i class="pss-icons_36-warning-error" aria-hidden="true"></i><span [innerHTML]="dialogData.content"></span>
    </div>
    <div class="help-text" *ngIf="dialogData.helpText">
      <i class="pss-icons_36-warning-error" *ngIf="!dialogData.content" aria-hidden="true"></i
      ><span [innerHTML]="dialogData.helpText"></span>
    </div>
  </div>
  <div mat-dialog-actions layout-align="end center">
    <button
      class="px-btn-paddings"
      mat-button
      color="primary"
      (click)="cancel()"
      [attr.aria-label]="'GLOBAL.CANCEL_LABEL' | translate"
      [disabled]="sendingData"
      *ngIf="!dialogData?.hideCancelBtn"
    >
      {{ dialogData.cancelBtn || ('GLOBAL.CANCEL_LABEL' | translate) }}</button
    ><button
      class="px-btn-paddings"
      *ngIf="dialogData.confirmBtn"
      cdkFocusInitial
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [attr.aria-label]="'GLOBAL.CONFIRM_LABEL' | translate"
      [disabled]="sendingData"
      [innerHTML]="dialogData.confirmBtn"
    ></button
    ><a
      class="px-btn-paddings"
      *ngIf="dialogData.confirmA"
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [attr.aria-label]="'GLOBAL.UPGRADE_LABEL' | translate"
      [href]="dialogData.upgradeUrl"
      target="_blank"
      [innerHTML]="dialogData.confirmA"
    ></a>
  </div>
</div>
