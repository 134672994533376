<div class="c-data-field c-data-field--audio-title">
  <div class="c-data-field__header">
    <div [puiTooltip]="title | truncated: soundTruncateLength" class="c-data-field__title">
      {{ title | truncate: soundTruncateLength || ('Unknown' | translate) }}
    </div>
    <div class="c-data-field__actions">
      <ng-container *ngIf="expiredInfo">
        <div class="c-data-field__action c-data-field__action--icon">
          <mds-icon [puiTooltip]="expiredInfo" icon="pxInfo" />
        </div>
      </ng-container>
    </div>
  </div>
  <div [puiTooltip]="artist | truncated: soundTruncateLength" class="c-data-field__subtitle">
    {{ artist | truncate: soundTruncateLength || ('Unknown' | translate) }}
  </div>
</div>
