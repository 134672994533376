import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IApiResponse, PlatformEnvironment} from '@px/shared/env';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {plainToClass, plainToInstance} from 'class-transformer';
import {Audio} from '../entities/audio';
import {IAudioResponse} from '../entities/interfaces/audio-response';
import {IAudioParams, IAudioQueryApi, IFilterApi} from '../entities/interfaces/audio-query';

import {OrderDirection} from '../entities/enums/order-direction.enum';
import {AudioChange} from '../entities/audio-change';
import {IAudioFacadeService} from '../entities/interfaces/audio-facade.interface';
import {IPagination} from '../entities/interfaces/pagination';
import {IAudioOptions} from '../entities/interfaces/audio-options';
import {AudioBeatmatchingTemplate} from '../entities/audio-beatmatching-template';
import {FileUploaderService, IStreamUploadResult, PxFile} from '@px/shared-data-access-file-upload';
import {IAudioUploadingResponse} from '../entities/interfaces/audio-uploading-response';
import {AUDIO_PIC_HOST} from '../entities/tokens/audio-pic-host.token';

/** @deprecated  use AudioGQLFacadeService*/
@Injectable()
export class AudioPicFacadeService implements IAudioFacadeService {
  private readonly FORM_FILENAME = 'audio';

  private static adapter(params: IAudioParams): IAudioQueryApi {
    const filter: IFilterApi = Object.entries(params.filter ?? {}).reduce(
      (acc: IFilterApi, [key, value]: [string, number | number[] | string | string[] | boolean]): IFilterApi => {
        acc[key] = Array.isArray(value)
          ? value.join(',')
          : typeof value === 'boolean'
            ? Number(value).toString()
            : value.toString();

        return acc;
      },
      {}
    );

    const query: IAudioQueryApi = {
      q: params.searchString,
      p: params.page,
      pp: params.size,
      filter,
    };

    if (params.sort) {
      query.s = params.sort.dir === OrderDirection.DESC ? `-${params.sort.prop}` : `${params.sort.prop}`;
    }

    if (params.exclude?.length) {
      query.e = params.exclude;
    }

    if (params.recent_segments_count !== undefined) {
      query.recent_segments_count = params.recent_segments_count;
    }

    return query;
  }

  constructor(
    private readonly http: HttpClient,
    protected readonly platformEnvironment: PlatformEnvironment,
    private readonly fileUploader: FileUploaderService,
    @Inject(AUDIO_PIC_HOST) private readonly audioPICHost: string
  ) {}

  isInsideBMTargetRange(): boolean {
    return true; //NOTE this service is obsolete so this is not matter and only for interface implementation
  }

  find(params: IAudioParams = {}): Observable<[Audio[], IPagination]> {
    const url = new URL('audio/search/', this.platformEnvironment.API_URL);

    return this.http.post<IApiResponse<IAudioResponse>>(url.toString(), AudioPicFacadeService.adapter(params)).pipe(
      map(response => {
        return [plainToInstance(Audio, response.data.audios_data), response.data.paginator];
      })
    );
  }

  getFilters(): Observable<IAudioOptions> {
    const url = new URL('audio/search/', this.platformEnvironment.API_URL);

    return this.http.options<IApiResponse<IAudioOptions>>(url.toString()).pipe(map(response => response.data));
  }

  getTemplatesByAudioId(id: number): Observable<AudioBeatmatchingTemplate[]> {
    const url = new URL(`audio-templates/${id}/`, this.platformEnvironment.API_URL);
    return this.http
      .get<IApiResponse<AudioBeatmatchingTemplate[]>>(url.toString())
      .pipe(map(response => plainToClass(AudioBeatmatchingTemplate, response.data)));
  }

  markAsFavorite(id: number): Observable<void> {
    const url = new URL(`favorite/${id}/`, this.platformEnvironment.API_URL);

    return this.http.post<void>(url.toString(), null);
  }

  removeFromFavorites(id: number): Observable<void> {
    const url = new URL(`favorite/${id}/`, this.platformEnvironment.API_URL);

    return this.http.delete<void>(url.toString());
  }

  upload(files: PxFile[]): Observable<IStreamUploadResult<Audio>> {
    const url = new URL('audio/', this.audioPICHost);

    return this.fileUploader
      .bulkUpload<IAudioUploadingResponse>(files, url.toString(), {
        formFilePropertyKey: 'audio',
        maxParallelUploads: 2,
      })
      .pipe(
        map(uploadProcess => {
          return {
            progress: uploadProcess.progress,
            success: uploadProcess.success.map(s => {
              return {
                file: s.file,
                response: plainToClass(Audio, s.response?.data.audio),
              };
            }),
            failed: uploadProcess.failed,
            done: uploadProcess.done,
          };
        })
      );
  }

  delete(id: number): Observable<void> {
    const url = new URL(`audio/${id}/`, this.platformEnvironment.API_URL);

    return this.http.delete<IApiResponse>(url.toString()).pipe(map(response => response.data));
  }

  edit(audios: AudioChange[]): Observable<void> {
    const url = new URL('audio-folder/', this.platformEnvironment.API_URL);

    return this.http.put<IApiResponse>(url.toString(), {audios}).pipe(map(response => response.data));
  }
}
