import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService as AuthBase, IApiResponse as ApiResponse} from '@px/shared/api';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthBase {
  private BASE_URL = this.platform.API_URL;

  saveLastTransition(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.BASE_URL}save-last-chosen-transition/`, data);
  }
}
