import {Directive, InjectionToken, TemplateRef} from '@angular/core';

export const MDS_TAB_CONTENT = new InjectionToken<MdsTabContentDirective>('MdsTabContentDirective');

@Directive({
  selector: '[mdsTabContent]',
  providers: [{provide: MDS_TAB_CONTENT, useExisting: MdsTabContentDirective}],
})
export class MdsTabContentDirective {
  constructor(readonly template: TemplateRef<unknown>) {}
}
