<div
  (files)="onFiles($event)"
  [config]="fileDragSelectorConfig"
  (dragover)="dragOver()"
  (dragleave)="dragLeave()"
  (drop)="dragLeave()"
  [ngClass]="{'is-dragged': isDragged}"
  class="c-audio-dropzone"
  pxFileDragSelector
>
  <div class="c-audio-dropzone__inner">
    <div class="c-audio-dropzone__header">
      <div class="c-audio-dropzone__icon">
        <mds-icon icon="pxPlus" />
      </div>
    </div>
    <div class="c-audio-dropzone__title">
      {{ 'Drop music files here or click to browse' | translate }}
    </div>
    <div class="c-audio-dropzone__subtitle">{{ 'Accepted formats' | translate }}: MP3, AAC, M4A</div>
  </div>
</div>
