import {Injectable} from '@angular/core';
import {featuresProperties, userProperties} from '../enums/user-properties';
import {superProperties} from '../enums/super-properties';
import dayjs from 'dayjs';
import {ISlideshowMetaData, IUser} from '@px/shared/api';
import {Events, SampleContentActions} from '../enums/events';
import {
  DeleteSegmentSource,
  DownloadVideoSource,
  DownloadVideoType,
  NavigateToManagerSource,
  PlayModalActivation,
  SlideshowType,
} from '../enums/payloads';
import {TrackableFeatures} from '../enums/trackable-features';
import {TrackingProperties, TrackingService, UserProps} from '@px/tracking/feature-tracking';
import {AspectRatio} from '@px/util/aspect-ratio';
import {AutoCroppingMode} from '../enums/auto-cropping-mode';
import {IAutoCroppingPreferences} from '../entities/auto-cropping-preferences';
import {CropActionType} from '../enums/crop-action-type';
import {IPssTrackingSlideshow} from '../entities/slideshow.interface';

@Injectable()
export class PssTrackingService {
  constructor(private readonly trackingService: TrackingService) {}

  private static getUserProps(user: IUser): UserProps {
    return {
      name: user.full_name,
      email: user.email,
    };
  }

  private getAutoCroppingMode(autoCroppingPreferences?: IAutoCroppingPreferences): AutoCroppingMode | null {
    const {vertical, horizontal} = autoCroppingPreferences || {};

    if (horizontal && vertical) {
      return AutoCroppingMode.FULL;
    }

    if (horizontal) {
      return AutoCroppingMode.HORIZONTAL;
    }

    if (vertical) {
      return AutoCroppingMode.VERTICAL;
    }

    return null;
  }

  async connect(user: IUser, featureFlags: Record<TrackableFeatures, boolean>): Promise<void> {
    if (user.no_tracking) {
      return;
    }

    await this.trackingService.init();
    this.trackingService.identify(user.user_id, user.user_px_id);

    const userProps: TrackingProperties = {
      ...PssTrackingService.getUserProps(user),
      [userProperties.PXID]: user.user_px_id,
      [userProperties.PSS_USER_ID]: user.user_id,
    };

    this.trackingService.setUserProperties(userProps);

    this.accountAge(user);
    this.userPlan(user);
    this.historicalSlideshowCount(user);
    this.publishCount(user);
    this.slideshowCount(user);
    this.setFeatureFlags(featureFlags);
  }

  setFeatureFlags(featureFlags: Record<TrackableFeatures, boolean>): void {
    const featurePropsValues = Object.entries(featureFlags).reduce((acc, [key, val]) => {
      const prop = featuresProperties[key as unknown as TrackableFeatures];

      if (prop) {
        acc[prop] = val;
      }

      return acc;
    }, {} as TrackingProperties);

    this.trackingService.setSuperProperties(featurePropsValues);
  }

  userPlan(user: Pick<IUser, 'user_plan_name'>): void {
    this.trackingService.setUserProperties({
      [userProperties.CURRENT_SUBSCRIPTION_PLAN_PSS]: user.user_plan_name,
    });

    this.trackingService.setSuperProperties({
      [superProperties.CURRENT_SUBSCRIPTION_PLAN]: user.user_plan_name,
    });
  }

  historicalSlideshowCount(
    user: Pick<IUser, 'slide_show_count' | 'total_sample_content_media_collections_present'>
  ): void {
    const slideshowCount = user.slide_show_count - user.total_sample_content_media_collections_present;

    this.trackingService.setUserProperties({
      [userProperties.NUMBER_OF_CREATED_SLIDESHOWS]: Math.max(slideshowCount, 0),
    });
  }

  publishCount(
    user: Pick<
      IUser,
      'published_media_collections_present' | 'total_published_sample_content_media_collections_present'
    >
  ): void {
    const publishedSlideshows =
      user.published_media_collections_present - user.total_published_sample_content_media_collections_present;

    this.trackingService.setUserProperties({
      [userProperties.NUMBER_OF_PUBLISHED_SLIDESHOWS]: Math.max(publishedSlideshows, 0),
    });

    this.trackingService.setSuperProperties({
      [superProperties.NUMBER_OF_PUBLISHED_SLIDESHOWS]: Math.max(publishedSlideshows, 0),
    });
  }

  slideshowCount(
    user: Pick<IUser, 'total_media_collections_present' | 'total_sample_content_media_collections_present'>
  ): void {
    const totalSlideshows = user.total_media_collections_present - user.total_sample_content_media_collections_present;

    this.trackingService.setUserProperties({[userProperties.NUMBER_OF_SLIDESHOWS]: Math.max(totalSlideshows, 0)});
    this.trackingService.setSuperProperties({[superProperties.NUMBER_OF_SLIDESHOWS]: Math.max(totalSlideshows, 0)});
  }

  accountAge(user: IUser): void {
    this.trackingService.setSuperProperties({
      [superProperties.ACCOUNT_AGE]: dayjs().diff(dayjs.unix(user.first_seen), 'days'),
    });
  }

  bmTargetHit(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.BM_TARGET_HIT, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  clickRedistributeButton(isSample: boolean): void {
    this.trackingService.track(Events.CLICK_REDISTRIBUTE_BUTTON, {
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  clickUpgradeButton(): void {
    this.trackingService.track(Events.CLICK_UPGRADE_BUTTON);
  }

  clickUpgradeNowLink(): void {
    this.trackingService.track(Events.CLICK_UPGRADE_NOW_LINK);
  }

  clickThumbnailPlayButton(value: PlayModalActivation, isSample: boolean): void {
    this.trackingService.track(Events.CLICK_THUMBNAIL_PLAY_BUTTON, {
      Activation: value,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  clickTimelinePlayButton(isSample: boolean): void {
    this.trackingService.track(Events.CLICK_TIMELINE_PLAY_BUTTON, {
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  createNewSlideshow(
    slideshowUID: string,
    aspectRatio: AspectRatio,
    autoCroppingPreferences?: IAutoCroppingPreferences
  ): void {
    this.trackingService.track(Events.CREATE_NEW_SLIDESHOW, {
      'Slideshow UID': slideshowUID,
      'Slideshow Aspect Ratio': aspectRatio,
      'Autocropping mode': this.getAutoCroppingMode(autoCroppingPreferences),
    });
  }

  deleteSegment(payload: DeleteSegmentSource, isSample: boolean): void {
    this.trackingService.track(Events.DELETE_SEGMENT, {
      Source: payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  downloadVideo(source: DownloadVideoSource, type: DownloadVideoType, isSample: boolean, rendered?: boolean): void {
    this.trackingService.track(Events.DOWNLOAD_VIDEO, {
      Source: source,
      'Video file rendered': !!rendered,
      'Video type': type,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  duplicatePhoto(isSample: boolean): void {
    this.trackingService.track(Events.DUPLICATE_PHOTO, {
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  enableBmManually(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.ENABLE_BM_MANUALLY, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  login(): void {
    this.trackingService.track(Events.LOGIN);
  }

  navigateToSlideshowManager(source: NavigateToManagerSource): void {
    this.trackingService.track(Events.NAVIGATE_TO_SLIDESHOW_MANAGER, {Source: source});
  }

  publishSlideshow(
    slideshow: IPssTrackingSlideshow,
    payload?: TrackingProperties,
    autoCroppingPreferences?: IAutoCroppingPreferences,
    croppedPhotosCount = 0
  ): void {
    this.trackingService.track(Events.PUBLISH_SLIDESHOW, {
      ...payload,
      'Slideshow Type': slideshow.is_sample ? SlideshowType.SAMPLE : '',
      'Slideshow Aspect Ratio': slideshow.aspect_ratio,
      'Theme: Color Palette': slideshow.meta_data?.style_options.colorPalette || null,
      'Theme: Cover': slideshow.meta_data?.style_options.themeName || null,
      'Theme: Player Size': slideshow.meta_data?.style_options.playerSize || null,
      'Theme: Typography': slideshow.meta_data?.style_options.typography || null,
      'Autocropping mode': this.getAutoCroppingMode(autoCroppingPreferences),
      'Total cropped images': croppedPhotosCount,
    });
  }

  publishSong(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.PUBLISH_SONG, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  reachThemeViewer(slideshowId: string, isSample: boolean): void {
    this.trackingService.track(Events.REACH_THEME_VIEWER, {
      'Slideshow UID': slideshowId,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  startNewSlideshow(): void {
    this.trackingService.track(Events.START_NEW_SLIDESHOW);
  }

  updateSlideshow(slideshow: IPssTrackingSlideshow, payload?: TrackingProperties, croppedPhotosCount = 0): void {
    this.trackingService.track(Events.UPDATE_SLIDESHOW, {
      ...payload,
      'Slideshow Type': slideshow.is_sample ? SlideshowType.SAMPLE : '',
      'Theme: Color Palette': slideshow.meta_data?.style_options.colorPalette || null,
      'Theme: Cover': slideshow.meta_data?.style_options.themeName || null,
      'Theme: Player Size': slideshow.meta_data?.style_options.playerSize || null,
      'Theme: Typography': slideshow.meta_data?.style_options.typography || null,
      'Total cropped images': croppedPhotosCount,
    });
  }

  updateSong(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.UPDATE_SONG, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  skipMusicSelection(isSample: boolean): void {
    this.trackingService.track(Events.SKIP_MUSIC_SELECTION, {
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  addSongToSegment(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.ADD_SONG_TO_SEGMENT, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  imageUpload(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.IMAGE_UPLOAD, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  imageUploadingError(isSample: boolean, payload?: TrackingProperties): void {
    this.trackingService.track(Events.IMAGE_UPLOADING_ERROR, {
      ...payload,
      'Slideshow Type': isSample ? SlideshowType.SAMPLE : '',
    });
  }

  setSurveyScore(score: number): void {
    this.trackingService.setUserProperties({'NPS score': score});
  }

  billingCheckoutOpen(productName: string, billingType: string): void {
    this.trackingService.track(Events.BILLING_CHECKOUT_OPEN, {
      'Selected subscription plan': productName,
      'Billing type': billingType,
    });
  }

  billingCheckoutSuccess(productName: string, billingType: string): void {
    this.trackingService.track(Events.BILLING_CHECKOUT_SUCCESS, {
      'Selected subscription plan': productName,
      'Billing type': billingType,
    });
  }

  billingCheckoutFailed(reason?: string): void {
    this.trackingService.track(Events.BILLING_CHECKOUT_FAILED, {Reason: reason});
  }

  openPublished(slideshowId: string, isSample: boolean): void {
    if (isSample) {
      this.trackingService.track(Events.SAMPLE_CONTENT_ACTION, {
        'Slideshow UID': slideshowId,
        'Action Type': SampleContentActions.OPEN_PUBLISHED,
      });
    }
  }

  editPublishedSlideshow(slideshowId: string, isSample: boolean): void {
    if (isSample) {
      this.trackingService.track(Events.SAMPLE_CONTENT_ACTION, {
        'Slideshow UID': slideshowId,
        'Action Type': SampleContentActions.EDIT_PUBLISHED,
      });
    }
  }

  editNonPublishedSlideshow(slideshowId: string, isSample: boolean): void {
    if (isSample) {
      this.trackingService.track(Events.SAMPLE_CONTENT_ACTION, {
        'Slideshow UID': slideshowId,
        'Action Type': SampleContentActions.EDIT_NON_PUBLISHED,
      });
    }
  }

  deleteSlideshow(slideshowId: string, isSample: boolean): void {
    if (isSample) {
      this.trackingService.track(Events.SAMPLE_CONTENT_ACTION, {
        'Slideshow UID': slideshowId,
        'Action Type': SampleContentActions.DELETE,
      });
    }
  }

  duplicateSlideshow(slideshowId: string, isSample: boolean): void {
    if (isSample) {
      this.trackingService.track(Events.SAMPLE_CONTENT_ACTION, {
        'Slideshow UID': slideshowId,
        'Action Type': SampleContentActions.DUPLICATE,
      });
    }
  }

  cropAction(type: CropActionType, imageId?: number): void {
    const action = imageId ? {'Action Type': type, 'Image ID': imageId} : {'Action Type': type};
    this.trackingService.track(Events.CROP_ACTION, action);
  }

  destroy(): void {
    this.trackingService.destroy();
  }
}
