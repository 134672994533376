import {Platform} from '@angular/cdk/platform';
import {NgIf} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  booleanAttribute,
  inject,
} from '@angular/core';

@Component({
  selector: 'mds-waveform',
  standalone: true,
  imports: [NgIf],
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsWaveformComponent implements AfterViewInit {
  private readonly platform = inject(Platform);

  private progressInstance = 0;
  private pointsInstance: number[] = [];
  private isWaveInit = false;
  private isProgressInit = false;

  @ViewChild('canvasEl') canvasEl?: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasProgressEl') canvasProgressEl?: ElementRef<HTMLCanvasElement>;

  @Output() seek$ = new EventEmitter<number>();

  @Input({transform: booleanAttribute}) hasProgress = false;
  @Input() color = '#c7c7c7';
  @Input() set points(value: number[]) {
    if (value && this.pointsInstance !== value) {
      this.pointsInstance = value;
      this.draw();
    }
  }

  @Input() set progress(value: number) {
    this.progressInstance = value ?? 0;

    if (value >= 0 && value <= 100 && this.hasProgress && this.canvasProgressEl && this.canvasEl) {
      this.drawProgress(this.canvasProgressEl.nativeElement, this.canvasEl.nativeElement, this.progressInstance);
    }
  }

  @HostListener('click', ['$event']) onClick($event: MouseEvent): void {
    if (!this.canvasEl) {
      return;
    }

    this.seek$.emit($event.offsetX / this.canvasEl.nativeElement.offsetWidth);
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => this.draw(), 0);
  }

  private draw(): void {
    if (this.canvasEl) {
      this.drawWave(this.canvasEl.nativeElement);
    }

    if (this.hasProgress) {
      if (this.canvasEl && this.canvasProgressEl) {
        this.drawProgress(this.canvasProgressEl.nativeElement, this.canvasEl.nativeElement, this.progressInstance);
      }
    }
  }

  private drawProgress(canvas: HTMLCanvasElement, sourceCanvas: HTMLCanvasElement, progress = 0): void {
    if (progress > 100) {
      progress = 100;
    } else if (progress < 0) {
      progress = 0;
    }

    if (!this.isProgressInit) {
      this.isProgressInit = true;
      const canvasWidth = sourceCanvas.width;
      const canvasHeight = sourceCanvas.height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
    }
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    const target = new Image();
    target.src = sourceCanvas.toDataURL();

    const positionProgress = -sourceCanvas.width + (sourceCanvas.width * progress) / 100;
    if (this.platform.SAFARI) {
      if (target.width <= 0 && target.height <= 0) {
        return;
      }
    } else if (sourceCanvas.width <= 0 && sourceCanvas.height <= 0) {
      return;
    }
    context.drawImage(
      this.platform.SAFARI ? target : sourceCanvas,
      positionProgress,
      0,
      sourceCanvas.width,
      sourceCanvas.height,
      this.platform.SAFARI ? 0 : positionProgress,
      0,
      sourceCanvas.width,
      sourceCanvas.height
    );
  }

  private drawWave(canvas: HTMLCanvasElement, color = this.color): void {
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    if (!this.isWaveInit) {
      this.isWaveInit = true;
      const canvasWidth = canvas.clientWidth;
      const canvasHeight = canvas.clientHeight;
      canvas.width = canvasWidth * window.devicePixelRatio;
      canvas.height = canvasHeight * window.devicePixelRatio;
    }

    const middleY = Math.floor(canvas.height / 2);
    context.beginPath();

    let x = 0;
    context.moveTo(x, middleY - this.pointsInstance[0] * middleY);
    for (x = 1; x < canvas.width; x++) {
      context.lineTo(
        x,
        middleY - this.pointsInstance[Math.round((x / canvas.width) * this.pointsInstance.length)] * middleY
      );
    }
    for (x = canvas.width; x > 0; x--) {
      context.lineTo(
        x,
        middleY + this.pointsInstance[Math.round((x / canvas.width) * this.pointsInstance.length)] * middleY
      );
    }

    context.closePath();
    context.fillStyle = color;
    context.fill();
  }
}
