<ng-container *ngIf="upTempo || downTempo; else bmUnknown">
  <ng-container *ngIf="showFullInfo; else shortInfo">
    <div class="c-data-field c-data-field--tempo">
      <ng-container *ngIf="upTempo">
        <div class="c-data-field__row">
          <span>{{ upTempo }}</span>
          <span> {{ upTempo | slidePerSecondFormat : length }}</span>
          <span class="c-data-field__b"> {{ tempoType.UPTEMPO | tempo }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="downTempo">
        <div class="c-data-field__row">
          <span>{{ downTempo }}</span>
          <span> {{ downTempo | slidePerSecondFormat : length }}</span>
          <span class="c-data-field__b"> {{ tempoType.SLOW | tempo }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #shortInfo>
    <div class="c-data-field c-data-field--string">
      {{ upTempo }}{{ upTempo && downTempo ? ' / ' : '' }}{{ downTempo }} {{ 'Images' | translate | lowercase }}
    </div>
  </ng-template>
</ng-container>

<ng-template #bmUnknown>
  <div class="c-data-field c-data-field--string">{{ 'N/A' | translate }}</div>
</ng-template>
