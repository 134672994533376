<div #audioFilters class="c-audio-filters" [style.--c-audio-filters-max-height]="audioFiltersMaxHeight">
  <div class="c-audio-filters__header">
    <div class="c-audio-filters__title">{{ 'Filter by' | translate }}</div>
    <div class="c-audio-filters__actions">
      <ng-container *ngIf="selectedOptions.length > 0">
        <div (click)="clearAllOptions()" class="c-audio-filters__action">
          {{ 'Clear all' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="c-audio-filters__body">
    <ng-container *ngFor="let option of options">
      <div
        [ngClass]="{'is-opened': isOpen(option.key)}"
        [hidden]="option.key === 'is_new'"
        class="c-audio-filters__section"
      >
        <div class="c-audio-filters__section-header" (click)="expansionItemHandler($event, option.key)">
          <div class="c-audio-filters__section-title">
            <ng-container *ngIf="isList(option.values); else boolTemplate">
              <mds-checkbox
                color="secondary"
                [checked]="isListEnabled(option)"
                [isIntermediate]="isOptionEnabled(option.key)"
                (changes$)="changeListState($event, option)"
              >
                {{ option.label | translate }}
              </mds-checkbox>
            </ng-container>

            <ng-template #boolTemplate>
              <mds-checkbox
                color="secondary"
                [checked]="isEnabledBool(option.key)"
                (changes$)="handleOptionValueCheckEvent($event, option.key)"
              >
                {{ option.label | translate }}
              </mds-checkbox>
            </ng-template>
          </div>

          <div class="c-audio-filters__section-actions">
            <div
              *ngIf="isList(option.values)"
              class="c-audio-filters__section-action c-audio-filters__section-action--icon"
              (click)="toggleOpenItemState($event, option.key)"
            >
              <mds-icon class="icon" [class.icon-rotate]="!isOpen(option.key)" icon="pxArrowUp" />
            </div>
          </div>
        </div>
        <ng-container *ngIf="isList(option.values)">
          <div class="c-audio-filters__section-body">
            <ng-container *ngFor="let value of option.values">
              <div class="c-audio-filters__item">
                <mds-checkbox
                  color="secondary"
                  (changes$)="handleOptionValueCheckEvent($event, option.key, value.key)"
                  [checked]="isOptionValueEnabled(option, value)"
                >
                  <ng-container [ngSwitch]="option.key">
                    <span *ngSwitchCase="FilterNames.ENERGY">
                      {{ value.key | audioEnergy }}
                    </span>
                    <span *ngSwitchCase="FilterNames.CATEGORY">
                      {{ value.key | audioCategory }}
                    </span>
                    <span *ngSwitchDefault>
                      {{ value.key }}
                    </span>
                  </ng-container>
                </mds-checkbox>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
