import {instanceToPlain} from 'class-transformer';
import {IAudioBeatmatchingTemplate} from './interfaces/audio-beatmatching-template.interface';
import {TempoTypeId} from './enums/tempo-types.enum';

export class AudioBeatmatchingTemplate implements IAudioBeatmatchingTemplate {
  is_custom = false;
  name = '';
  tempo_type = '';
  id = -1;
  matching_images = 0;
  version = 1;
  configs_path = '';
  tempo_id = TempoTypeId.SLOW;
  transitions = [];

  get isSlow(): boolean {
    return this.tempo_id === TempoTypeId.SLOW;
  }

  get isUptempo(): boolean {
    return this.tempo_id === TempoTypeId.UPTEMPO;
  }

  getPlain(): IAudioBeatmatchingTemplate {
    return instanceToPlain(this) as IAudioBeatmatchingTemplate;
  }
}
