import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslationService} from 'apps/pss/src/app/shared/services/translations.service';
import {GoogleAnalyticsService} from 'apps/pss/src/app/services/auth/google-analytics.service';
import {PSSPlatformEnvironment} from '../../platform-environment';
import {ConnectionStatusFacadeService} from '@px/connection-issue/domain';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {GlobalState} from '../../store/global/global.state';
import {ConnectionOffline, ConnectionOnline} from '../../store/global/global.actions';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private readonly platform: PSSPlatformEnvironment,
    private readonly connectionStatusFacadeService: ConnectionStatusFacadeService,
    private store$: Store<GlobalState>
  ) {
    if (platform.GA_ENABLED) {
      googleAnalyticsService.init(platform.GA_TRACKING_ID);
    }

    translate.init();
  }

  ngOnInit(): void {
    this.connectionStatusFacadeService.initialize();
    this.connectionStatusFacadeService.hasConnection$.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      if (status) {
        this.store$.dispatch(new ConnectionOnline());
        return;
      }

      this.store$.dispatch(new ConnectionOffline());
    });
  }
}
