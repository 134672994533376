import {Observable} from 'rxjs';

export const audioUploadingPhrases = [
  'Marrying algo and rhythm',
  'Analyzing beat matrices and fabricating templates',
  'Calculating BPM and snapping to the grid',
];

export interface IAudioUploadingProgressBarDialogData {
  progress$: Observable<number>;
  label: string | null;
}
