import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth.effects';
import {authReducers} from './store/auth.reducer';

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducers), EffectsModule.forFeature([AuthEffects])],
})
export class AuthModule {}
