import {ChangeDetectorRef, Optional, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ILabel, OptionView} from '../intefaces/filter-select-item';
import {FilterTruncatePipe} from './filter-truncate.pipe';

@UntilDestroy()
@Pipe({
  name: 'filterFormat',
  pure: false,
})
export class FilterFormatPipe implements PipeTransform {
  private readonly deleteCount = 2;

  constructor(
    private readonly translate: TranslateService,
    private readonly filterTruncatePipe: FilterTruncatePipe,
    @Optional() private readonly cdr?: ChangeDetectorRef
  ) {
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => this.cdr?.detectChanges());
  }

  private static isList(item?: ILabel[] | boolean): item is ILabel[] {
    return Array.isArray(item);
  }

  transform(item: OptionView): string {
    return `${this.translate.instant(item.label)}${
      FilterFormatPipe.isList(item.value) ? `: ${this.filterTruncatePipe.transform(item, this.deleteCount)}` : ''
    }`;
  }
}
