import {IAudioPlayerService} from '../interfaces/audio-player-service.interface';
import {Injectable, OnDestroy} from '@angular/core';
import {Howl, Howler} from 'howler';
import {IPlayable} from '../interfaces/playable.interface';
import {animationFrameScheduler, interval, merge, Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class AudioPlayerService implements IAudioPlayerService, OnDestroy {
  private readonly PROGRESS_UPDATE_PERIOD_MS = 500;
  private readonly PROGRESS_DIVIDER = 1000;
  private changes$ = new Subject<void>();
  private activeId: number | undefined;
  private queue: IPlayable[] = [];

  private howl?: Howl;

  get hasChanges$(): Observable<void> {
    return this.changes$.asObservable();
  }

  add(items: IPlayable[]): void {
    this.queue = items;
  }

  play(playable: IPlayable): void {
    if (this.howl && this.activeId === playable.id) {
      this.howl.play();
      return;
    }

    this.activeId = playable.id;
    this.howl?.stop().unload();

    this.howl = new Howl({
      html5: true,
      src: [playable.audio_mp3, playable.audio_ogg],
      format: ['mp3', 'ogg'],
      preload: true,
      onseek: (): void => this.changes$.next(),
      onplay: (): void => this.changes$.next(),
      onpause: (): void => this.changes$.next(),
      onvolume: (): void => this.changes$.next(),
      onstop: (): void => this.changes$.next(),
      onend: (): void => this.changes$.next(),
      onload: (): void => this.changes$.next(),
      onloaderror: (id: number, err: unknown): void => {
        console.error(err);
        this.changes$.next();
      },
      onplayerror: (id: number, err: unknown): void => {
        console.error(err);
        this.changes$.next();
      },
      onunlock: (): void => this.changes$.next(),
    });

    this.howl.play();
  }

  playPrev(): IPlayable | null {
    const prev = Math.max(this.queue.findIndex(({id}) => id === this.activeId) - 1, 0);
    const playable = this.queue[prev];

    if (playable) {
      this.stop();
      this.play(playable);
      return playable;
    }

    return null;
  }

  playNext(): IPlayable | null {
    const next = Math.min(this.queue.findIndex(({id}) => id === this.activeId) + 1, this.queue.length - 1);
    const playable = this.queue[next];

    if (playable) {
      this.stop();
      this.play(playable);
      return playable;
    }

    return null;
  }

  pause(): void {
    if (this.howl?.state() === 'loading') {
      this.howl.unload();
      this.howl = undefined;
    }

    this.howl?.pause();
  }

  unload(): void {
    this.howl?.unload();
  }

  stop(): void {
    this.howl?.stop();
  }

  setVolume(value = 1): void {
    if (value > 1) {
      value = 1;
    } else if (value < 0) {
      value = 0;
    }
    Howler.volume(value);
  }

  isPlaying(id: number): boolean {
    return (
      this.activeId === id &&
      ((!!this.howl?.playing() && this.howl?.state() === 'loaded') ||
        (!this.howl?.playing() && this.howl?.state() === 'loading'))
    );
  }

  isLoading(id: number): boolean {
    return this.activeId === id && !this.howl?.playing() && this.howl?.state() === 'loading';
  }

  seek(): number {
    return this.howl?.seek() as number;
  }

  setSeek(seek: number): void {
    const duration = this.duration();
    this.howl?.seek(duration * seek);
  }

  duration(): number {
    return this.howl?.duration() as number;
  }

  calculateProgress(): number {
    const seek = this.seek();
    const duration = this.duration();

    const rawProgress = (seek / duration) * 100;
    return Math.min(Math.max(Math.round(rawProgress * this.PROGRESS_DIVIDER) / this.PROGRESS_DIVIDER || 0, 0), 100);
  }

  getProgress(id: number): Observable<number> {
    return merge(
      this.changes$,
      interval(this.PROGRESS_UPDATE_PERIOD_MS, animationFrameScheduler).pipe(filter(() => this.isPlaying(id)))
    ).pipe(map(() => this.calculateProgress()));
  }

  progress(): number {
    const seek = this.seek();
    const duration = this.duration();

    return (seek / duration) * 100 || 0;
  }

  dispose(): void {
    this.queue = [];
    this.activeId = undefined;
    this.unload();
    this.stop();
    this.howl = undefined;
    Howler.unload();
  }

  ngOnDestroy(): void {
    this.dispose();
    this.changes$.complete();
  }
}
