import {Directive, TemplateRef} from '@angular/core';
import {MdsDataTableComponent} from '../components/datatable/datatable.component';

@Directive({
  selector: '[mdsDataTableEmptyRegister]',
})
export class MdsDataTableEmptyRegisterDirective<T, D> {
  constructor(
    private readonly templateRef: TemplateRef<void>,
    private readonly mdsDataTableComponent: MdsDataTableComponent<T, D>
  ) {
    this.mdsDataTableComponent.emptyTemplate = templateRef;
  }
}
