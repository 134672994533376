import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output} from '@angular/core';
import {ErrorType, IFileDragSelectorConfig, IFileHandle, IValidateError} from '@px/shared/file-drag-selector';
import {PlatformEnvironment} from '@px/shared/env';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UploadingAlertDialogComponent} from '../uploading-alert-dialog/uploading-alert-dialog.component';
import {AudioEditDialogComponent} from '../audio-edit-dialog/audio-edit-dialog.component';
import {AudioUploadingProgressBarComponent} from '../audio-uploading-progress-bar/audio-uploading-progress-bar.component';
import {AudioAgreementComponent} from '../audio-agreement/audio-agreement.component';
import {head} from 'ramda';
import {AudioBrowserStateService} from '../../services/audio-browser-state.service';
import {filter, map, shareReplay, tap} from 'rxjs/operators';
import {AudioDropzoneState} from './audio-dropzone-state';
import {AudioFile} from '@px/shared-data-access-file-upload';
import {Audio, AudioChange} from '@px/audio-domain';
import {IMdsDialogService, MDS_DIALOG_SERVICE, MdsDialogRef} from '@pui/components/dialog';
import {IAudioUploadingProgressBarDialogData} from '../audio-uploading-progress-bar/types/audio-uploading-progress-bar.models';

@Component({
  selector: 'px-audio-dropzone',
  templateUrl: './audio-dropzone.component.html',
  styleUrls: ['./audio-dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioDropzoneComponent implements OnDestroy {
  isDragged = false;

  private alertDialogRef?: MatDialogRef<UploadingAlertDialogComponent>;
  private editDialogRef?: MatDialogRef<AudioEditDialogComponent, AudioChange[] | void>;
  private progressDialogRef?: MatDialogRef<AudioUploadingProgressBarComponent, void>;
  private progressDialogRefMds?: MdsDialogRef<AudioUploadingProgressBarComponent, void>;
  private agreementDialogRef?: MatDialogRef<AudioAgreementComponent, boolean>;

  readonly fileDragSelectorConfig: IFileDragSelectorConfig = {
    availableFileType: AudioFile,
    maxUploadFileSize: this.platform.COMMON_SETTINGS.MAX_UPLOAD_FILE_SIZE, //TODO replace with something like AUDIO_BROWSER_SETTINGS provider
  };

  @Output() songs$ = new EventEmitter<Audio[]>();
  @Output() state$ = new EventEmitter<AudioDropzoneState>();

  constructor(
    private readonly platform: PlatformEnvironment, //TODO get rid of it
    private readonly matDialog: MatDialog,
    @Inject(MDS_DIALOG_SERVICE) private uiDialogService: IMdsDialogService,
    private readonly audioBrowserStateService: AudioBrowserStateService //TODO get rid of it
  ) {}

  async onFiles(files: IFileHandle[]): Promise<void> {
    const toUpload = files.filter(item => item.isValid).map(f => f.file);
    const invalidFiles = files.filter(item => !item.isValid);

    this.state$.next('files');

    if (invalidFiles.length) {
      this.state$.next('validation');
      await this.openAlert(invalidFiles.map(({file, error}) => ({file: file.name, type: error as ErrorType})));
    }

    if (!toUpload.length) {
      return;
    }

    const isAgree = await this.openAgreement();

    if (!isAgree) {
      return;
    }

    const isOneFile = toUpload.length === 1;
    const label = isOneFile ? head(toUpload)?.name || null : '';

    const uploading$ = this.audioBrowserStateService.addFiles(toUpload).pipe(shareReplay(1));
    const progress$ = uploading$.pipe(
      filter(value => value.progress !== null),
      map(value => Math.min(value.progress * 100, 100))
    );

    const progressBarDialogRef = this.openProgress({label, progress$});

    let audios: Audio[] = [];
    let errors: IValidateError[] = [];

    this.state$.next('uploading');

    const succeedAudios = await uploading$
      .pipe(
        filter(value => value.done),
        tap(value => {
          errors = value.failed.map(f => ({file: f.file.name, type: ErrorType.UNKNOWN}));
        }),
        map(value => value.success.map(s => s.response).filter(a => !!a) as Audio[])
      )
      .toPromise();

    if (succeedAudios) {
      audios.push(...succeedAudios);
    }

    progressBarDialogRef.close();

    if (errors.length) {
      await this.openAlert(errors);
    }

    const toEdit = audios.filter(item => item.hasNoTitleOrArtist());

    if (!toEdit.length) {
      this.state$.next('selected');
      this.songs$.emit(audios);
      return;
    }

    const changes = await this.openEdit(toEdit);

    if (changes) {
      await this.audioBrowserStateService.edit(changes).toPromise();

      audios = audios.reduce((acc: Audio[], item: AudioChange) => {
        const audio = this.audioBrowserStateService.getAudioById(item.id);

        if (audio) {
          acc.push(audio);
        }

        return acc;
      }, []);
    }

    this.state$.next('selected');
    this.songs$.emit(audios);
  }

  async openAlert(errors: IValidateError[]): Promise<void> {
    const {UploadingAlertDialogComponent} = await import('../uploading-alert-dialog/uploading-alert-dialog.component');
    this.alertDialogRef = this.matDialog.open(UploadingAlertDialogComponent, {
      data: errors,
      panelClass: ['px-dialog', 'px-upload-alert-dialog'],
    });

    await this.alertDialogRef.afterClosed().toPromise();
  }

  async openEdit(audio: Audio[]): Promise<AudioChange[] | void> {
    const {AudioEditDialogComponent} = await import('../audio-edit-dialog/audio-edit-dialog.component');
    this.editDialogRef = this.matDialog.open(AudioEditDialogComponent, {
      data: audio,
      panelClass: ['px-dialog', 'px-edit-tracks-dialog'],
    });

    return this.editDialogRef.afterClosed().toPromise();
  }

  openProgress(data: IAudioUploadingProgressBarDialogData): MdsDialogRef<AudioUploadingProgressBarComponent, void> {
    this.progressDialogRefMds = this.uiDialogService.open(AudioUploadingProgressBarComponent, {
      panelClass: ['px-dialog', 'music-uploader-dialog'],
      backdropClass: 'transparent-backdrop',
      backdropClose: false,
      data,
    });

    return this.progressDialogRefMds;
  }

  async openAgreement(): Promise<boolean | undefined> {
    const {AudioAgreementComponent} = await import('../audio-agreement/audio-agreement.component');

    this.agreementDialogRef = this.matDialog.open(AudioAgreementComponent, {
      panelClass: ['px-dialog', 'copyright-warn-dialog'],
      backdropClass: 'transparent-backdrop',
      disableClose: true,
    });

    return this.agreementDialogRef.afterClosed().toPromise();
  }

  dragOver(): void {
    this.isDragged = true;
  }

  dragLeave(): void {
    this.isDragged = false;
  }

  ngOnDestroy(): void {
    this.alertDialogRef?.close();
    this.editDialogRef?.close();
    this.progressDialogRef?.close();
    this.agreementDialogRef?.close();
  }
}
