import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Audio} from '@px/audio-domain';

@Component({
  selector: 'px-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioPlayerComponent {
  @Input() audio?: Audio;
  @Input() titleTruncateLength = 35;
  @Input() isPlaying = false;
  @Input() isLoading = false;
  @Input() playPositionInfo = '00:00';
  @Input() progress = 0;

  @Output() prevAudio$ = new EventEmitter<void>();
  @Output() nextAudio$ = new EventEmitter<void>();
  @Output() playAudio$ = new EventEmitter<void>();
  @Output() pauseAudio$ = new EventEmitter<void>();
  @Output() selectAudio$ = new EventEmitter<void>();
  @Output() toggleFavoriteAudio$ = new EventEmitter<void>();
  @Output() seekAudio$ = new EventEmitter<number>();

  constructor(private readonly translate: TranslateService) {}

  get tooltipFavorite(): string {
    return this.translate.instant(!this.audio?.is_favorite ? 'Add to favorites' : 'Remove from favorites');
  }

  get audioProgress(): string {
    return this.playPositionInfo + '&nbsp;/&nbsp;' + this.audio?.durationFormat;
  }

  get prevTooltip(): string {
    return this.translate.instant('Play previous song') + '\n' + this.translate.instant('Shift + Left arrow key');
  }

  get nextTooltip(): string {
    return this.translate.instant('Play next song') + '\n' + this.translate.instant('Shift + Right arrow key');
  }

  prev(): void {
    this.prevAudio$.emit();
  }

  next(): void {
    this.nextAudio$.emit();
  }

  play(): void {
    this.playAudio$.emit();
  }

  pause(): void {
    this.pauseAudio$.emit();
  }

  toggleFavorite(): void {
    this.toggleFavoriteAudio$.emit();
  }

  select(): void {
    this.selectAudio$.emit();
  }

  onSeek(value: number): void {
    this.seekAudio$.emit(value);
  }
}
