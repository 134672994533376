import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[pxDigitsOnly]',
})
export class DigitsOnlyDirective {
  constructor(private readonly ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.ngControl.control?.patchValue(value.replace(/[^0-9]+/, ''));
  }
}
