import {ElementRef, EventEmitter, TemplateRef} from '@angular/core';
import {UI_HINT_POSITION} from '../consts/ui-hint.constants';

export interface IHintComponent {
  templateRef?: TemplateRef<ElementRef>;
  close$: EventEmitter<void>;
  position: keyof typeof UI_HINT_POSITION;
  key?: string;
  transformOrigin: string;
  triggerElementRef?: ElementRef;
}
