import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MdsDialogContainerComponent, MdsDialogContentComponent} from '@mds/components/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {IMdsDialogConfig, MDS_DIALOG_DATA} from '@pui/components/dialog';
import {AudioUploadingProgressPhrasesService} from './services/audio-uploading-progress-phrases.service';
import {IAudioUploadingProgressBarDialogData} from './types/audio-uploading-progress-bar.models';

@Component({
  selector: 'px-audio-uploading-progress-bar',
  standalone: true,
  imports: [CommonModule, TranslateModule, MdsDialogContainerComponent, MdsDialogContentComponent],
  providers: [AudioUploadingProgressPhrasesService],
  templateUrl: './audio-uploading-progress-bar.component.html',
  styleUrls: ['./audio-uploading-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioUploadingProgressBarComponent implements OnInit {
  private readonly audioUploadingProgressService = inject(AudioUploadingProgressPhrasesService);
  readonly dialogData = inject<IMdsDialogConfig<IAudioUploadingProgressBarDialogData>>(MDS_DIALOG_DATA);

  progress$ = this.dialogData.data?.progress$;

  label = this.dialogData.data?.label;
  infoPhrase = this.audioUploadingProgressService.getRandomPhraseAndRemove();

  ngOnInit(): void {
    this.audioUploadingProgressService.initialize();
  }
}
