<div class="c-dialog c-dialog--audio-browser">
  <div class="c-dialog__inner">
    <div class="c-dialog__header">
      <div class="c-dialog__actions">
        <div class="c-dialog__action">
          <px-button (click)="close()" hasIconLeft type="default">
            <mds-icon icon="pxChevronLeft" />
            {{ dialogConfig?.data?.closeButtonLabel || ('Back to Images' | translate) }}
          </px-button>
        </div>
      </div>
      <div class="c-dialog__title">{{ dialogConfig?.title || ('Select Music' | translate) }}</div>
      <div class="c-dialog__actions c-dialog__actions--right">
        <div class="c-dialog__action">
          <ng-container *ngIf="!isUploadsDropzoneOpened; else dropdownClosed">
            <px-button (click)="toggleDropzone()" hasIconLeft type="default">
              <mds-icon icon="pxAdd" />
              {{ 'Upload Music' | translate }}
            </px-button>
          </ng-container>
          <ng-template #dropdownClosed>
            <px-button (click)="toggleDropzone()" hasIconLeft type="default">
              <mds-icon icon="pxArrowUp" />
              {{ 'Upload Music' | translate }}
            </px-button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="c-dialog__body">
      <mds-tabs
        class="c-dialog__tabs"
        [fullHeight]="true"
        [activeTabIndex]="activeTab"
        (activeTabChange)="onTabChange($event)"
        (tabsFadeStateChange$)="onTabsFadeStateChange($event)"
      >
        <mds-tab [title]="'Top Songs' | translate">
          <div class="c-dialog__tab-content">
            <px-top-songs
              [categoriesConfig]="audiosCategoriesConfig"
              [fadedAudios]="disabledAudiosIds"
              [isPlayerActive]="activeTab === AudioBrowserTab.TOP_SONGS"
              [tracksPerPage]="15"
              [audioChangesEvent$]="audioChangesEventForTab$.get(AudioBrowserTab.TOP_SONGS)"
              [uploadedImagesCount]="dialogConfig.data?.uploadedImagesCount$ | async"
              (selectAudio$)="selectAudio($event)"
              (categoryClicked$)="switchToSearchByCategory($event)"
              (audioFavoriteChanged$)="onAudioFavoritesChanged($event)"
            />
          </div>
        </mds-tab>
        <mds-tab [title]="'My Uploads' | translate">
          <div class="c-dialog__tab-content">
            <px-audio-uploads
              [isDropzoneOpened]="isUploadsDropzoneOpened"
              [isPlayerActive]="activeTab === AudioBrowserTab.MY_UPLOADS"
              [disabledAudiosIds]="disabledAudiosIds"
              [recentSegmentsCount]="recentSegmentsCount"
              [audioChangesEvent$]="audioChangesEventForTab$.get(AudioBrowserTab.MY_UPLOADS)"
              [isBMTargetsColumnShown]="true"
              (selectAudio$)="selectAudio($event)"
              (audioChanged$)="onAudioChanged($event)"
              (audioDeleted$)="onAudioDeleted($event)"
              (audioFavoriteChanged$)="onAudioFavoritesChanged($event)"
            />
          </div>
        </mds-tab>
        <mds-tab [title]="'My Favorites' | translate">
          <div class="c-dialog__tab-content">
            <px-audio-favorites
              [isPlayerActive]="activeTab === AudioBrowserTab.MY_FAVORITES"
              [disabledAudiosIds]="disabledAudiosIds"
              [audioChangesEvent$]="audioChangesEventForTab$.get(AudioBrowserTab.MY_FAVORITES)"
              [recentSegmentsCount]="recentSegmentsCount"
              (selectAudio$)="selectAudio($event)"
              (audioChanged$)="onAudioChanged($event)"
              (audioDeleted$)="onAudioDeleted($event)"
              (audioFavoriteChanged$)="onAudioFavoritesChanged($event)"
            />
          </div>
        </mds-tab>
        <mds-tab [title]="'Search' | translate">
          <div class="c-dialog__tab-content">
            <px-audio-search
              [isSearchBarFocused]="isSearchBarFocused"
              [showFilterHint]="activeTab === AudioBrowserTab.SEARCH"
              [disabledAudiosIds]="disabledAudiosIds"
              [isPlayerActive]="activeTab === AudioBrowserTab.SEARCH"
              [audiosPerPage]="30"
              [orderedAvailableFilters]="orderedAvailableFilters"
              [filterParams]="filterParams"
              [initialSortProp]="dialogConfig.data?.initialSortProp"
              [initialSearchString]="(dialogConfig.data?.initialSearchString$ | async) ?? undefined"
              [filter]="searchTableFilter"
              [audioChangesEvent$]="audioChangesEventForTab$.get(AudioBrowserTab.SEARCH)"
              [recentSegmentsCount]="recentSegmentsCount"
              (selectAudio$)="selectAudio($event)"
              (audioChanged$)="onAudioChanged($event)"
              (audioDeleted$)="onAudioDeleted($event)"
              (audioFavoriteChanged$)="onAudioFavoritesChanged($event)"
              (sortChanged$)="searchTableSort$.next($event)"
              (filterChanged$)="emitChangeFilter($event)"
            />
          </div>
        </mds-tab>
      </mds-tabs>
    </div>
  </div>
</div>
