import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'px-top-songs-skeleton',
  templateUrl: './top-songs-skeleton.component.html',
  styleUrls: ['./top-songs-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSongsSkeletonComponent {
  @Input()
  set categoriesCount(value: number) {
    this.categorySkeletonsArr = Array(value);
  }

  @Input()
  set tracksCount(value: number) {
    this.trackSkeletonsArr = Array(value);
  }

  categorySkeletonsArr: void[] = [];
  trackSkeletonsArr: void[] = [];
}
