<div
  class="dialog-container"
  [style.width.px]="maxWidth"
  [style.padding-top.px]="paddingY"
  [style.padding-bottom.px]="paddingY"
  [style.padding-left.px]="paddingX"
  [style.padding-right.px]="paddingX"
>
  <div class="dialog-container__close">
    <ng-content select="mds-dialog-close"></ng-content>
  </div>
  <div class="dialog-container__content">
    <ng-content></ng-content>
  </div>
</div>
