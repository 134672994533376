import {IPlayable} from './playable.interface';
import {Observable} from 'rxjs';

export interface IAudioPlayerService {
  hasChanges$: Observable<void>;

  add(items: IPlayable[]): void;

  play(playable: IPlayable): void;

  pause(): void;

  stop(): void;

  setVolume(value: number): void;

  isPlaying(id: number): boolean;

  isLoading(id: number): boolean;

  seek(): number;

  setSeek(seek: number): void;

  duration(): number;

  getProgress(id: number): Observable<number>;

  progress(): number;

  playNext(): IPlayable | null;

  playPrev(): IPlayable | null;

  dispose(): void;
}
