import {NgModule} from '@angular/core';
import {AudioPicFacadeService} from './application-services/audio-pic-facade.service';
import {AudioGqlFacadeService} from './application-services/audio-gql-facade.service';
import {AudioGqlDataService} from './infrastructure/audio-gql-data.service';
import {AudioGQLAdapterService} from './infrastructure/audio-gql-adapter.service';
import {AudioS3CredentialsService} from './infrastructure/audio-s3-credentials.service';
import {AudioAssetBackendCreationService} from './infrastructure/audio-asset-backend-creation.service';
import {AudioS3CredentialsGqlDataService} from './infrastructure/audio-s3-credentials-gql-data.service';
import {FeatureFileUploadModule} from '@px/shared-data-access-file-upload';
import {AudioAssetCreatorService} from './infrastructure/audio-asset-creator.service';

@NgModule({
  imports: [FeatureFileUploadModule],
  providers: [
    AudioPicFacadeService,
    AudioGqlFacadeService,
    AudioGqlDataService,
    AudioGQLAdapterService,
    AudioS3CredentialsService,
    AudioAssetBackendCreationService,
    AudioAssetCreatorService,
    AudioS3CredentialsGqlDataService,
  ],
})
export class AudioDomainModule {}
