import {FocusMonitor} from '@angular/cdk/a11y';
import {DOCUMENT} from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

const focusableElements = 'input, button, select, textarea, a[href], [tabindex]';

@Directive({
  selector: '[mdsAutofocus]',
  standalone: true,
})
export class PuiAutofocusDirective implements AfterViewInit, OnChanges, AfterViewChecked {
  private readonly elementRef = inject(ElementRef);
  private readonly focusMonitor = inject(FocusMonitor);
  private readonly document = inject(DOCUMENT);

  private focusedOnce = false;

  @Input() focusState = false;
  @Input() focusElRef: ElementRef<HTMLElement> | undefined;

  ngAfterViewInit(): void {
    this.focus(this.elementRef);
  }

  ngAfterViewChecked(): void {
    if (this.document.activeElement === this.elementRef.nativeElement) return;
    if (this.focusedOnce) return;
    this.focus(this.elementRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['focusState'] && changes['focusState'].currentValue) {
      let el = this.elementRef;
      if (this.focusElRef) {
        el = this.focusElRef;
      }
      this.focus(el);
    }
  }

  focus(el: ElementRef): void {
    if (!el) return;
    if (!this.focusState) return;

    this.focusedOnce = true;

    const isFocusable = this.elementRef.nativeElement.matches(focusableElements);
    const hasFocusableChildren = this.elementRef.nativeElement.querySelector(focusableElements);

    if (isFocusable) {
      this.focusMonitor.focusVia(el, 'program');

      return;
    }

    if (hasFocusableChildren) {
      const firstFocusableEl = el.nativeElement.querySelector(focusableElements);
      if (firstFocusableEl) {
        this.focusMonitor.focusVia(firstFocusableEl, 'program');
      }

      return;
    }

    el.nativeElement.tabindex = -1;
    this.focusMonitor.focusVia(el, 'program');
  }
}
