import {EMPTY, Observable} from 'rxjs';

export const SHARED_HOTKEYS_SERVICE_MOCK = {
  play(): Observable<KeyboardEvent> {
    return EMPTY;
  },
  like(): Observable<KeyboardEvent> {
    return EMPTY;
  },
  arrowLeft(): Observable<KeyboardEvent> {
    return EMPTY;
  },
  arrowRight(): Observable<KeyboardEvent> {
    return EMPTY;
  },
  shiftArrowRight(): Observable<KeyboardEvent> {
    return EMPTY;
  },
  shiftArrowLeft(): Observable<KeyboardEvent> {
    return EMPTY;
  },
};
