import {Inject, Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {IAudioBrowserState} from '../intefaces/audio-browser-state';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {prepend, prop, uniqBy} from 'ramda';
import {AudioFile, IStreamUploadResult} from '@px/shared-data-access-file-upload';
import {Audio, AUDIO_FACADE, AudioChange, IAudioFacadeService} from '@px/audio-domain';

@Injectable()
export class AudioBrowserStateService extends ComponentStore<IAudioBrowserState> {
  private static readonly DEFAULT_SIZE = 30;
  private static readonly DEFAULT_PAGE = 1;

  constructor(@Inject(AUDIO_FACADE) private readonly audioService: IAudioFacadeService) {
    super({
      audio: [],
      optionDefinition: [],
      page: AudioBrowserStateService.DEFAULT_PAGE,
      totalPages: AudioBrowserStateService.DEFAULT_PAGE,
      isLoading: false,
      totalCount: 0,
      size: AudioBrowserStateService.DEFAULT_SIZE,
    });
  }

  getAudioById(id?: number): Audio | undefined {
    return this.get().audio.find(item => item.id === id);
  }

  addFiles(files: AudioFile[]): Observable<IStreamUploadResult<Audio>> {
    return this.uploadFiles(files).pipe(
      tap(result => {
        if (result.done && result.success.length) {
          this.patchState((state: IAudioBrowserState) => ({
            audio: uniqBy(
              prop('id'),
              result.success.reduce((acc, cur) => {
                if (cur.response) {
                  return prepend(cur.response, acc);
                }
                return acc;
              }, state.audio)
            ) as unknown[] as Audio[],
          }));
        }
      })
    );
  }

  private uploadFiles(files: AudioFile[]): Observable<IStreamUploadResult<Audio>> {
    return this.audioService.upload(files);
  }

  edit(changes: AudioChange[]): Observable<void> {
    return this.audioService.edit(changes).pipe(
      tap(() => {
        this.patchState((state: IAudioBrowserState) => {
          const audio = changes.reduce(
            (acc, item) => {
              const idx = acc.findIndex(({id}) => id === item.id);

              const value = acc[idx]?.updateInfo(item);

              if (value && idx !== -1) {
                acc[idx] = value;
              }

              return acc;
            },
            [...state.audio]
          );

          return {audio};
        });
      })
    );
  }
}
