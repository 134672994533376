import {AudiosOrderBy} from '../enums/audios-order-by';
import {RandomSortProp} from '../random-sort-prop';

export const AUDIO_FIELD_TO_ORDER_BY = new Map<string, AudiosOrderBy>([
  ['artist', AudiosOrderBy.ARTIST],
  ['song_title', AudiosOrderBy.TITLE],
  ['length', AudiosOrderBy.LENGTH],
  ['lyrics', AudiosOrderBy.HAS_LYRICS],
  ['category', AudiosOrderBy.CATEGORY],
  ['energy', AudiosOrderBy.ENERGY],
  ['bm_target', AudiosOrderBy.TEMPO],
  ['is_favorite', AudiosOrderBy.IS_FAVORITE],
  [RandomSortProp.VALUE, AudiosOrderBy.RANDOM],
]);
