import {ChangeDetectionStrategy, Component, Inject, NgModule, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Audio, AudioChange} from '@px/audio-domain';

@Component({
  selector: 'px-audio-edit-dialog',
  templateUrl: './audio-edit-dialog.component.html',
  styleUrls: ['./audio-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AudioEditDialogComponent {
  savingData = false;
  editTracksForm = this.audios.reduce((acc, audio) => {
    acc.addControl(`title_${audio.id}`, new UntypedFormControl(audio.song_title, [Validators.maxLength(255)]));
    acc.addControl(`artist_${audio.id}`, new UntypedFormControl(audio.artist, [Validators.maxLength(255)]));

    return acc;
  }, new UntypedFormGroup({}));

  constructor(
    private readonly dialogRef: MatDialogRef<AudioEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly audios: Audio[]
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  changedField(): void {
    if (!this.getChanges().length) {
      this.editTracksForm?.markAsPristine();
    }
  }

  save(): void {
    if (this.editTracksForm?.valid) {
      this.savingData = true;

      this.dialogRef.close(this.getChanges());
    }
  }

  getChanges(): AudioChange[] {
    const audios: AudioChange[] = [];

    for (const audio of this.audios) {
      const changes: Partial<Audio> = {};

      if (this.editTracksForm?.value[`title_${audio.id}`] !== audio.song_title) {
        changes.song_title = this.editTracksForm?.value[`title_${audio.id}`]?.trim();
      }

      if (this.editTracksForm?.value[`artist_${audio.id}`] !== audio.artist) {
        changes.artist = this.editTracksForm?.value[`artist_${audio.id}`]?.trim();
      }

      if (Object.keys(changes).length) {
        changes.id = audio.id;
        audios.push(changes as AudioChange);
      }
    }

    return audios;
  }
}

@NgModule({
  declarations: [AudioEditDialogComponent],
  imports: [TranslateModule, MatButtonModule, MatDialogModule, ReactiveFormsModule, CommonModule],
  exports: [AudioEditDialogComponent],
})
export class AudioEditDialogModule {}
