export enum AudioTableColumns {
  SONG_TITLE,
  CATEGORY,
  ENERGY,
  LENGTH,
  BM_TARGET,
  STATUS,
  LYRICS,
  PLAYER_CONTROLS,
  WAVE_FORM,
  IS_FAVORITE,
}
