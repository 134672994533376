import {TransitionType, TransitionTypeId} from '../enums/transition-type.enum';

/** @deprecated used only for legacy compatibility */
export const TRANSITION_TYPE_TO_ID = new Map<TransitionType, TransitionTypeId>([
  [TransitionType.AUTO, TransitionTypeId.AUTO],
  [TransitionType.CROSS_DISSOLVE, TransitionTypeId.CROSS_FADE],
  [TransitionType.DIP_TO_BACKGROUND, TransitionTypeId.DIP_TO_BACKGROUND],
  [TransitionType.HARD_CUT, TransitionTypeId.HARD_CUT],
  [TransitionType.WIPE_DOWN, TransitionTypeId.WIPE_DOWN],
  [TransitionType.WIPE_LEFT, TransitionTypeId.WIPE_LEFT],
  [TransitionType.WIPE_RIGHT, TransitionTypeId.WIPE_RIGHT],
  [TransitionType.WIPE_UP, TransitionTypeId.WIPE_UP],
]);

/** @deprecated used only for legacy compatibility */
export const TRANSITION_TYPE_ID_TO_TYPE = new Map<TransitionTypeId, TransitionType>([
  [TransitionTypeId.AUTO, TransitionType.AUTO],
  [TransitionTypeId.CROSS_FADE, TransitionType.CROSS_DISSOLVE],
  [TransitionTypeId.DIP_TO_BACKGROUND, TransitionType.DIP_TO_BACKGROUND],
  [TransitionTypeId.HARD_CUT, TransitionType.HARD_CUT],
  [TransitionTypeId.WIPE_DOWN, TransitionType.WIPE_DOWN],
  [TransitionTypeId.WIPE_LEFT, TransitionType.WIPE_LEFT],
  [TransitionTypeId.WIPE_RIGHT, TransitionType.WIPE_RIGHT],
  [TransitionTypeId.WIPE_UP, TransitionType.WIPE_UP],
]);

/** @deprecated used only for legacy compatibility */
export const ID_TO_TRANSITION_TYPE = new Map(
  [...TRANSITION_TYPE_TO_ID.entries()].map(entry => entry.reverse() as [TransitionTypeId, TransitionType])
);
