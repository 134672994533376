import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {ConnectedPosition, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  UI_HINT_POSITION,
  UI_HINT_POSITION_OFFSET_X,
  UI_HINT_POSITION_OFFSET_Y,
  UI_HINT_STORAGE_PREFIX,
} from '../consts/ui-hint.constants';
import {IHintComponent} from '../interfaces/ui-hint.interfaces';
import {filter, fromEvent, Observable} from 'rxjs';
import mergeWith from 'lodash/mergeWith';
import {WINDOW_TOKEN} from '@px/cdk/window';

/**
 * @deprecated Use MdsHintTriggerForDirective instead
 */
@UntilDestroy()
@Directive({
  selector: '[pxHintTriggerFor]',
})
export class UiHintTriggerForDirective implements OnDestroy, AfterViewInit, OnChanges {
  @Input('pxHintTriggerFor') hintComponent?: IHintComponent;
  @Input() ready?: boolean;
  @Input() pxHintDisabled?: boolean = false;
  @Input() pxHintPosition: keyof typeof UI_HINT_POSITION = UI_HINT_POSITION.right;
  @Input() pxHintShowDelay?: number;
  @Input() pxHintHasBackdrop? = false;

  @Output() close$ = new EventEmitter<void>();

  private isOpened = false;
  private overlayRef?: OverlayRef;
  private showTimeoutId?: number;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    @Inject(WINDOW_TOKEN) private readonly window: Window
  ) {}

  ngAfterViewInit(): void {
    if (this.ready !== undefined) {
      return;
    }

    this.show();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ready']?.currentValue) {
      this.show();
    }
  }

  show(): void {
    if (this.hintComponent?.key) {
      if (localStorage.getItem(UI_HINT_STORAGE_PREFIX + this.hintComponent?.key) !== null) {
        this.destroy();

        return;
      }

      localStorage.setItem(UI_HINT_STORAGE_PREFIX + this.hintComponent?.key, 'true');
      this.open();

      return;
    }

    if (this.pxHintShowDelay) {
      this.showTimeoutId = this.window.setTimeout(() => {
        this.open();
      }, this.pxHintShowDelay);
      return;
    }

    this.open();
  }

  open(): void {
    if (this.pxHintDisabled) {
      return;
    }
    this.clearTimeout();

    this.isOpened = true;

    if (this.pxHintPosition && this.hintComponent) {
      this.hintComponent.position = this.pxHintPosition;
    }

    this.overlayRef = this.overlay.create({
      hasBackdrop: this.pxHintHasBackdrop,
      backdropClass: this.pxHintHasBackdrop ? 'cdk-overlay-transparent-backdrop' : undefined,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withLockedPosition()
        .withPositions(this.getPositions()),
    });

    if (this.hintComponent?.templateRef) {
      this.hintComponent.transformOrigin = this.getTransformOrigin();
      const templatePortal = new TemplatePortal(this.hintComponent.templateRef, this.viewContainerRef);
      this.overlayRef.attach(templatePortal);
      this.overlayRef.updatePosition();
    }

    this.hintClosingActions()
      .pipe(untilDestroyed(this, 'destroy'))
      .subscribe(() => this.destroy());
  }

  getTransformOrigin(): string {
    let transformOrigin = '';

    switch (this.hintComponent?.position) {
      case UI_HINT_POSITION.left:
        transformOrigin = 'right center';
        break;

      case UI_HINT_POSITION.right:
        transformOrigin = 'left center';
        break;

      case UI_HINT_POSITION.bottom:
        transformOrigin = 'top center';
        break;

      case UI_HINT_POSITION.top:
        transformOrigin = 'bottom center';
        break;

      default:
        transformOrigin = 'center';
        break;
    }

    return transformOrigin;
  }

  getPositions(): ConnectedPosition[] {
    const positions: ConnectedPosition[] = [];

    const left: ConnectedPosition = {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -UI_HINT_POSITION_OFFSET_X,
    };

    const right: ConnectedPosition = {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: UI_HINT_POSITION_OFFSET_X,
    };

    const bottom: ConnectedPosition = {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: UI_HINT_POSITION_OFFSET_X,
    };

    const top: ConnectedPosition = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -UI_HINT_POSITION_OFFSET_X,
    };

    const definedPositions: {[key: string]: ConnectedPosition} = {
      [UI_HINT_POSITION.left]: left,

      [UI_HINT_POSITION.leftTop]: {
        ...left,
        originY: 'bottom',
        offsetY: UI_HINT_POSITION_OFFSET_Y,
      },

      [UI_HINT_POSITION.leftBottom]: {
        ...left,
        originY: 'top',
        offsetY: -UI_HINT_POSITION_OFFSET_Y,
      },

      [UI_HINT_POSITION.right]: right,

      [UI_HINT_POSITION.rightTop]: {
        ...right,
        originY: 'bottom',
        offsetY: UI_HINT_POSITION_OFFSET_Y,
      },

      [UI_HINT_POSITION.rightBottom]: {
        ...right,
        originY: 'top',
        offsetY: -UI_HINT_POSITION_OFFSET_Y,
      },

      [UI_HINT_POSITION.top]: top,

      [UI_HINT_POSITION.topLeft]: {
        ...top,
        originX: 'start',
        overlayX: 'start',
      },

      [UI_HINT_POSITION.topRight]: {
        ...top,
        originX: 'end',
        overlayX: 'end',
      },

      [UI_HINT_POSITION.bottom]: bottom,

      [UI_HINT_POSITION.bottomLeft]: {
        ...bottom,
        originX: 'start',
        overlayX: 'start',
      },

      [UI_HINT_POSITION.bottomRight]: {
        ...bottom,
        originX: 'end',
        overlayX: 'end',
      },
    };

    switch (this.hintComponent?.position) {
      case UI_HINT_POSITION.left:
        positions.push(definedPositions[UI_HINT_POSITION.left]);
        break;

      case UI_HINT_POSITION.leftTop:
        positions.push(definedPositions[UI_HINT_POSITION.leftTop]);
        break;

      case UI_HINT_POSITION.leftBottom:
        positions.push(definedPositions[UI_HINT_POSITION.leftBottom]);
        break;

      case UI_HINT_POSITION.right:
        positions.push(definedPositions[UI_HINT_POSITION.right]);
        break;

      case UI_HINT_POSITION.rightTop:
        positions.push(definedPositions[UI_HINT_POSITION.rightTop]);
        break;

      case UI_HINT_POSITION.rightBottom:
        positions.push(definedPositions[UI_HINT_POSITION.rightBottom]);
        break;

      case UI_HINT_POSITION.bottom:
        positions.push(definedPositions[UI_HINT_POSITION.bottom]);
        break;

      case UI_HINT_POSITION.bottomLeft:
        positions.push(definedPositions[UI_HINT_POSITION.bottomLeft]);
        break;

      case UI_HINT_POSITION.bottomRight:
        positions.push(definedPositions[UI_HINT_POSITION.bottomRight]);
        break;

      case UI_HINT_POSITION.top:
        positions.push(definedPositions[UI_HINT_POSITION.top]);
        break;

      case UI_HINT_POSITION.topLeft:
        positions.push(definedPositions[UI_HINT_POSITION.topLeft]);
        break;

      case UI_HINT_POSITION.topRight:
        positions.push(definedPositions[UI_HINT_POSITION.topRight]);
        break;

      default:
        positions.push(
          definedPositions[UI_HINT_POSITION.top],
          definedPositions[UI_HINT_POSITION.right],
          definedPositions[UI_HINT_POSITION.bottom],
          definedPositions[UI_HINT_POSITION.left]
        );
        break;
    }

    return positions;
  }

  clearTimeout(): void {
    if (this.showTimeoutId) {
      this.window.clearTimeout(this.showTimeoutId);
    }
  }

  destroy(): void {
    this.clearTimeout();
    if (!this.overlayRef || !this.isOpened) {
      return;
    }

    this.isOpened = false;
    this.overlayRef.detach();
    this.close$.emit();
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private hintClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef?.backdropClick();
    const windowClickOutside$ = fromEvent<MouseEvent>(this.window, 'click').pipe(
      untilDestroyed(this),
      filter((event: MouseEvent) => {
        const clickTarget = event.target as HTMLElement;
        const isClickInside = this.overlayRef?.hostElement?.contains(clickTarget);
        return !isClickInside;
      })
    );
    const clickOutside$ = this.pxHintHasBackdrop ? backdropClick$ : windowClickOutside$;
    const detachment$ = this.overlayRef?.detachments();
    const dropdownClose$ = this.hintComponent?.close$;

    return mergeWith(clickOutside$, detachment$, dropdownClose$);
  }
}
