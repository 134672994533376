import {inject, Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {IS3UploadPolicyForAudioToProcessGql} from '../entities/gql-schema-types/s3-upload-policy-for-audio-to-process-gql';
import {filter, map, Observable} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class AudioS3CredentialsGqlDataService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly credentialsMutation = gql<
    {createS3UploadPolicyForAudioFileToProcess: IS3UploadPolicyForAudioToProcessGql},
    {input: {filename: string}}
  >`
    mutation CreateS3UploadPolicyForAudioFileToProcess($input: CreateS3UploadPolicyForAudioFileToProcessInput!) {
      createS3UploadPolicyForAudioFileToProcess(input: $input) {
        awsPublicKey
        expiresAt
        policy
        s3Key
        signature
        storageClass
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  getCredentials(filename: string): Observable<IS3UploadPolicyForAudioToProcessGql> {
    return this.apollo
      .mutate({
        mutation: this.credentialsMutation,
        variables: {input: {filename}},
      })
      .pipe(
        filter(response => !!response.data),
        map(({data}) => {
          const s3UploadPolicyForAudioFileToProcess =
            data?.createS3UploadPolicyForAudioFileToProcess as IS3UploadPolicyForAudioToProcessGql;
          return {
            ...s3UploadPolicyForAudioFileToProcess,
            storageClass: s3UploadPolicyForAudioFileToProcess.storageClass ?? this.platform.STORAGE_CLASS,
          };
        })
      );
  }
}
