<div
  class="audio-list-item"
  *ngIf="audio"
  [ngClass]="{
    'audio-list-item--active': isActive,
    'audio-list-item--favorite': audio.is_favorite,
    'audio-list-item--faded': isFaded
  }"
>
  <span class="audio-list-item__index-number">{{ indexNumber }}</span>
  <px-audio-player-control
    class="audio-list-item__play"
    size="lg"
    [isPlaying]="isPlaying"
    [isLoading]="isLoading"
    (play$)="play()"
    (pause$)="pause()"
    pxClickStopPropagation
  />
  <span
    #title
    class="audio-list-item__title"
    [puiTooltip]="isTextTrimmed(title, audio.song_title) ? audio.song_title : undefined"
  >
    {{ audio.song_title }}
  </span>
  <px-button
    *ngIf="audio.lyrics"
    class="audio-list-item__lyrics"
    type="fabNaked"
    size="xs"
    [puiTooltip]="'View lyrics' | translate"
    (click$)="openAudioLyric()"
    pxClickStopPropagation
  >
    <mds-icon icon="pxMic" />
  </px-button>
  <span
    #subtitle
    class="audio-list-item__subtitle"
    [puiTooltip]="isTextTrimmed(subtitle, audio.artist) ? audio.artist : undefined"
  >
    {{ audio.artist }}
  </span>
  <px-button
    class="audio-list-item__fav"
    type="fabNaked"
    size="xs"
    [active]="audio.is_favorite"
    [puiTooltip]="!audio.is_favorite ? ('Add to favorites' | translate) : ('Remove from favorites' | translate)"
    (click)="onFavoriteClick()"
    pxClickStopPropagation
  >
    <mds-icon *ngIf="!audio.is_favorite" icon="pxFavoriteBorder" />
    <mds-icon *ngIf="audio.is_favorite" icon="pxFavorite" />
  </px-button>
  <px-button
    class="audio-list-item__add"
    type="fabNakedPrimary"
    size="xs"
    [puiTooltip]="'Add song to segment' | translate"
    pxClickStopPropagation
    (click$)="select()"
  >
    <mds-icon icon="pxAdd" />
  </px-button>
  <span class="audio-list-item__length">
    {{ audio.durationFormat }}
  </span>
</div>
