<div
  mat-dialog-title
  layout="row"
  layout-align="space-between center"
  [attr.aria-label]="'MUSIC_UPLOAD_PAGE.EDIT_TRACKS_DIALOG_LABEL' | translate"
>
  <div [translate]="'MUSIC_UPLOAD_PAGE.EDIT_TRACKS_INFO_LABEL'"></div>
  <span flex="flex"></span>

  <button mat-icon-button (click)="closeDialog()" [attr.aria-label]="'GLOBAL.CLOSE_LABEL' | translate">
    <i class="pss-icons_11-close"></i>
  </button>
</div>

<form class="edit-tracks-form" novalidate="novalidate" name="editTracksForm" role="form" [formGroup]="editTracksForm">
  <mat-dialog-content>
    <div layout="row">
      <span class="dialog-text" ngSwitch="type">
        <span *ngSwitchCase="0">
          {{ 'MUSIC_UPLOAD_PAGE.FILE_UPLOAD_ERROR_PART1' | translate }}
          {{
            audios.length > 1
              ? ('MUSIC_UPLOAD_PAGE.FILES_LOWER_LABEL' | translate) + ' are'
              : ('MUSIC_UPLOAD_PAGE.FILE_LOWER_LABEL' | translate) + ' is'
          }}
          {{ 'MUSIC_UPLOAD_PAGE.MESSING_META_DATA_MESSAGE' | translate }}.</span
        >
        <span *ngSwitchDefault>{{ 'MUSIC_UPLOAD_PAGE.UPDATE_METADATA_MESSAGE' | translate }}.</span>
      </span>
    </div>
    <div class="tracks-list">
      <div class="list-head" layout="row" layout-align="space-between stretch">
        <div class="head-field">{{ 'MUSIC_UPLOAD_PAGE.FILENAME_LABEL' | translate }}</div>
        <div class="head-field">{{ 'MUSIC_UPLOAD_PAGE.TRACK_TITLE_LABEL' | translate }}</div>
        <div class="head-field">{{ 'MUSIC_UPLOAD_PAGE.ARTIST_NAME_LABEL' | translate }}</div>
      </div>
      <div class="list-body">
        <div class="list-row" layout="row" layout-align="space-between stretch" *ngFor="let audio of audios">
          <div class="list-field">{{ audio.original_file_name }}</div>
          <div class="list-field editable">
            <input
              type="text"
              placeholder="{{ 'MUSIC_UPLOAD_PAGE.TRACK_TITLE_LABEL' | translate }}"
              formControlName="title_{{ audio.id }}"
              name="title_{{ audio.id }}"
              (input)="changedField()"
              maxlength="255"
              [ngClass]="{'ng-invalid': !audio.song_title}"
            />
          </div>
          <div class="list-field editable">
            <input
              type="text"
              placeholder="{{ 'MUSIC_UPLOAD_PAGE.ARTIST_NAME_LABEL' | translate }}"
              formControlName="artist_{{ audio.id }}"
              (input)="changedField()"
              name="artist_{{ audio.id }}"
              maxlength="255"
              [ngClass]="{'ng-invalid': !audio.artist}"
            />
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions layout="row" layout-align="end center">
    <button
      class="px-btn-paddings"
      mat-button
      color="primary"
      (click)="closeDialog()"
      tabindex="1"
      [disabled]="savingData"
      type="button"
      [attr.aria-label]="'GLOBAL.CANCEL_LABEL' | translate"
    >
      <span>{{ 'GLOBAL.CANCEL_LABEL' | translate }}</span>
    </button>

    <button
      class="px-btn-paddings"
      mat-button
      mat-raised-button
      color="primary"
      (click)="save()"
      tabindex="0"
      [attr.aria-label]="'GLOBAL.SAVE_LABEL' | translate"
      [disabled]="editTracksForm.invalid || !editTracksForm.dirty || savingData"
    >
      <span>{{ 'GLOBAL.SAVE_LABEL' | translate }}</span>
    </button>
  </mat-dialog-actions>
</form>
