import {Pipe, PipeTransform} from '@angular/core';
import {Dayjs} from 'dayjs';

@Pipe({
  name: 'fullDateFormat',
})
export class FullDateFormatPipe implements PipeTransform {
  private readonly FULL_DATE_STRING_FORMAT = 'dddd, MMMM D, YYYY [at] h:mm A';

  transform(value?: Dayjs): string {
    return value?.format(this.FULL_DATE_STRING_FORMAT) ?? '';
  }
}
