import {inject, Injectable} from '@angular/core';
import {Audio} from '../entities/audio';
import {AudioBeatmatchingTemplate} from '../entities/audio-beatmatching-template';
import {IAudioFacadeService} from '../entities/interfaces/audio-facade.interface';
import {IAudioOptions} from '../entities/interfaces/audio-options';
import {catchError, delay, filter, identity, merge, mergeMap, Observable, of, switchMap, takeWhile, tap} from 'rxjs';
import {AudioChange} from '../entities/audio-change';
import {IAudioParams} from '../entities/interfaces/audio-query';
import {IPagination} from '../entities/interfaces/pagination';
import {AudioGqlDataService} from '../infrastructure/audio-gql-data.service';
import {map} from 'rxjs/operators';
import {AudioGQLAdapterService} from '../infrastructure/audio-gql-adapter.service';
import {plainToClass} from 'class-transformer';
import {AudioFile, IStreamUploadResult} from '@px/shared-data-access-file-upload';
import {AssetBackendCreationError, AssetsCreationStage, IAssetCreationSuccess} from '@px/shared/asset-creation';
import {AudioAssetCreatorService} from '../infrastructure/audio-asset-creator.service';
import {UPLOADING_FINISHED_DELAY} from '../entities/tokens/uploading-finished-delay.token';
import {AudiosOrderBy} from '../entities/enums/audios-order-by';
import {Apollo} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OrderDirection} from '../entities/enums/order-direction.enum';
import {IAudioQueryCategories, IAudiosQueryArguments} from '../entities/gql-schema-types/audios-query-arguments';
import {AudioCategory} from '../entities/enums/audio-categoriy.enum';
import {LocalStorageService} from '@px/shared-data-access-local-storage';
import {AudioBrowserTab} from '../entities/enums/audio-browser-tab.enum';
import isEqual from 'lodash/isEqual';

@UntilDestroy()
@Injectable()
export class AudioGqlFacadeService implements IAudioFacadeService {
  private readonly audioGQLDataService = inject(AudioGqlDataService);
  private readonly audioGQLAdapter = inject(AudioGQLAdapterService);
  private readonly assetCreationService = inject(AudioAssetCreatorService);
  private readonly uploadingFinishedDelay = inject(UPLOADING_FINISHED_DELAY, {optional: true});
  private readonly apollo = inject(Apollo);
  private readonly localStorage = inject(LocalStorageService);
  private readonly LAST_ACTIVE_TAB_LS_KEY = 'audio_browser_last_active_tab';

  //NOTE: Mapping until collection MS migration
  private readonly picIdToUUID = new Map<number, string>();

  private audiosQueryArguments: IAudioQueryCategories = {
    TOP_SONGS: [
      {
        isFavorite: true,
        order: [
          {
            orderBy: AudiosOrderBy.TITLE,
            orderDirection: OrderDirection.ASC,
          },
        ],
      },
      {
        categories: [AudioCategory.BEATS],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.CALM_MELODIES],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.EMOTIONAL],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.EPIC_BM],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.HAPPY_FOLK],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.LOVE_SONGS],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
      {
        categories: [AudioCategory.PARTY_TIME],
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 15,
          offset: 0,
        },
      },
    ],
    MY_UPLOADS: [
      {
        categories: [AudioCategory.USER_AUDIO],
        order: [
          {
            orderBy: AudiosOrderBy.TITLE,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 200,
          offset: 0,
        },
      },
    ],
    FAVORITES: [
      {
        isFavorite: true,
        order: [
          {
            orderBy: AudiosOrderBy.TITLE,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 200,
          offset: 0,
        },
      },
    ],
    SEARCH: [
      {
        order: [
          {
            orderBy: AudiosOrderBy.RANDOM,
            orderDirection: OrderDirection.ASC,
          },
        ],
        pagination: {
          first: 30,
          offset: 0,
        },
      },
    ],
  };

  private get lastActiveTabIndex(): AudioBrowserTab {
    return this.localStorage.getItem(this.LAST_ACTIVE_TAB_LS_KEY) as AudioBrowserTab;
  }

  private ifPicIdAddToMap(picId: number | null, id: string): void {
    if (picId) {
      this.picIdToUUID.set(picId, id);
    }
  }

  isInsideBMTargetRange(n: number): boolean {
    return this.audioGQLAdapter.isInsideBMTargetRange(n);
  }

  getTemplatesByAudioId(picId: number): Observable<AudioBeatmatchingTemplate[]> {
    const id = this.picIdToUUID.get(picId);
    if (id) {
      return this.audioGQLDataService.getAudioTemplates(id).pipe(
        map(templates => {
          const converted: AudioBeatmatchingTemplate[] = [];

          for (const t of templates) {
            try {
              converted.push(plainToClass(AudioBeatmatchingTemplate, this.audioGQLAdapter.templateGQLToTemplate(t)));
            } catch (e) {
              console.error(e);
            }
          }
          return converted;
        }),
        catchError(e => {
          console.error(e);
          return of([]);
        })
      );
    }
    return of([]);
  }
  getFilters(): Observable<IAudioOptions> {
    return of(this.audioGQLAdapter.getFilters());
  }
  removeFromFavorites(picId: number): Observable<void> {
    const id = this.picIdToUUID.get(picId);
    if (id) {
      return this.audioGQLDataService.updateAudio({id, isFavorite: false}).pipe(map(() => undefined));
    }
    return of(undefined);
  }
  markAsFavorite(picId: number): Observable<void> {
    const id = this.picIdToUUID.get(picId);
    if (id) {
      return this.audioGQLDataService.updateAudio({id, isFavorite: true}).pipe(map(() => undefined));
    }
    return of(undefined);
  }
  delete(picId: number): Observable<void> {
    const id = this.picIdToUUID.get(picId);
    if (id) {
      return this.audioGQLDataService.deleteAudio(id);
    }
    return of(undefined);
  }
  upload(pxFiles: AudioFile[]): Observable<IStreamUploadResult<Audio>> {
    this.assetCreationService.addFilesToCreationQueue(pxFiles);
    return this.assetCreationService.creationProcess$.pipe(
      takeWhile(process => process.stage !== AssetsCreationStage.FINISHED, true),
      mergeMap(process =>
        of(process).pipe(
          process.stage === AssetsCreationStage.FINISHED && this.uploadingFinishedDelay
            ? delay(this.uploadingFinishedDelay)
            : identity
        )
      ),
      map(({progress, succeed, failed, uploadedSize, totalSize, stage}) => {
        const succeedAdapted: IAssetCreationSuccess<Audio>[] = [];

        for (const {asset, pxFile, startTime, endTime} of succeed) {
          try {
            succeedAdapted.push({
              asset: this.audioGQLAdapter.audioGQLToAudio(asset),
              pxFile,
              creationParameters: null,
              creationContext: null,
              startTime,
              endTime,
            });
            this.ifPicIdAddToMap(asset.picId, asset.id);
          } catch (error) {
            failed = failed.concat({
              pxFile,
              error: new AssetBackendCreationError(error),
              creationParameters: null,
              creationContext: null,
            });
          }
        }

        return {
          progress,
          totalSize,
          uploadedSize,
          failed,
          succeed: succeedAdapted,
          stage,
        };
      }),
      map(process => {
        return {
          progress: process.progress,
          success: process.succeed.map(s => {
            return {
              file: s.pxFile,
              response: s.asset,
            };
          }),
          failed: process.failed.map(f => {
            return {
              file: f.pxFile,
              error: f.error,
            };
          }),
          done: process.stage === AssetsCreationStage.FINISHED,
        };
      })
    );
  }
  edit(audios: AudioChange[]): Observable<void> {
    try {
      const updateInput = this.audioGQLAdapter.audioChangesToAudioUpdatable(
        audios,
        audios.map(a => this.picIdToUUID.get(a.id)).filter(uuid => !!uuid) as string[]
      );
      return this.audioGQLDataService.updateAudio(updateInput).pipe(map(() => undefined));
    } catch (e) {
      console.error(e);
      return of(undefined);
    }
  }
  find(params: IAudioParams): Observable<[Audio[], IPagination]> {
    const audiosQueryArguments = JSON.parse(
      JSON.stringify(this.audioGQLAdapter.audioParamsToQueryArguments(params))
    ) as IAudiosQueryArguments;

    this.updateAudiosQueryArguments(audiosQueryArguments, params.queryCategory);

    return this.audioGQLDataService.getAudios(audiosQueryArguments).pipe(
      tap(({nodes}) => {
        nodes.forEach(a => {
          this.ifPicIdAddToMap(a.picId, a.id);
        });
      }),
      map(pagination => [
        this.audioGQLAdapter.audiosGQLToAudios(pagination.nodes),
        this.audioGQLAdapter.pageInfoToPagination(pagination.pageInfo),
      ])
    );
  }

  prefetchAudios(): Observable<void> {
    switch (this.lastActiveTabIndex) {
      case AudioBrowserTab.TOP_SONGS:
        return this.prefetchAudiosByQueries(this.audiosQueryArguments.TOP_SONGS).pipe(
          tap(() => (this.audiosQueryArguments.TOP_SONGS = []))
        );
      case AudioBrowserTab.MY_UPLOADS:
        return this.prefetchAudiosByQueries(this.audiosQueryArguments.MY_UPLOADS);
      case AudioBrowserTab.FAVORITES:
        return this.prefetchAudiosByQueries(this.audiosQueryArguments.FAVORITES);
      case AudioBrowserTab.SEARCH:
        return this.prefetchAudiosByQueries(this.audiosQueryArguments.SEARCH);
      default:
        return of(undefined);
    }
  }

  refreshAudiosCache(): Observable<void> {
    return this.dropAudiosCache(null).pipe(
      filter(isCacheDropped => isCacheDropped),
      switchMap(() => this.prefetchAudios()),
      map(() => undefined)
    );
  }

  refreshSearchAudiosCache(): void {
    this.dropAudiosCache(this.audiosQueryArguments.SEARCH)
      .pipe(
        untilDestroyed(this),
        filter(isCacheDropped => isCacheDropped),
        switchMap(() => this.prefetchAudiosByQueries(this.audiosQueryArguments.SEARCH))
      )
      .subscribe();
  }

  dropAudiosCache(queries: IAudiosQueryArguments[] | null): Observable<boolean> {
    const cache = this.apollo.client.cache as InMemoryCache;
    const queryIds = cache.identify({__typename: 'Query'});

    if (!queryIds) {
      return of(false);
    }

    const dataIds = (cache.extract().ROOT_QUERY as Record<string, never>) || {};
    const audiosFields = Object.keys(dataIds).filter(str => str.includes('audios'));
    const filteredAudiosFields = !queries?.length
      ? audiosFields
      : audiosFields.filter(str => {
          const startIndex = str.indexOf('{');
          const endIndex = str.lastIndexOf('}') + 1;
          const jsonString = str.substring(startIndex, endIndex) ?? '{}';
          const queryArgument = JSON.parse(jsonString);
          return queries.some(q => isEqual(q as {[x: string]: never}, queryArgument));
        });

    if (!filteredAudiosFields?.length) {
      return of(false);
    }

    for (const audioFieldName of filteredAudiosFields) {
      cache.evict({fieldName: audioFieldName});
      cache.gc();
    }

    return of(true);
  }

  private prefetchAudiosByQueries(queries: IAudiosQueryArguments[]): Observable<void> {
    const requests$ = queries.map(vars => this.audioGQLDataService.getAudios(vars));
    return merge(...requests$).pipe(map(() => undefined));
  }

  private updateAudiosQueryArguments(
    queryArguments: IAudiosQueryArguments,
    queryCategory?: keyof IAudioQueryCategories
  ): void {
    if (queryCategory) {
      const uniqueObjects = new Set(this.audiosQueryArguments[queryCategory].map(obj => JSON.stringify(obj)));
      if (!uniqueObjects.has(JSON.stringify(queryArguments))) {
        this.audiosQueryArguments[queryCategory].push(queryArguments);
      }
    }
  }
}
