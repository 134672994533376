<div>
  <div mat-dialog-content>
    <div class="content">
      <span>{{ 'Delete [song]' | translate : {song: songTitle} }}</span>
    </div>
    <div class="help-text">
      <span>
        {{ 'MUSIC_UPLOAD_PAGE.DELETE_SONG_MESSAGE' | translate }}
      </span>
    </div>
  </div>
  <div mat-dialog-actions layout-align="end center">
    <button
      class="px-btn-paddings"
      mat-button
      color="primary"
      (click)="cancel()"
      [attr.aria-label]="'GLOBAL.CANCEL_LABEL' | translate"
    >
      {{ 'GLOBAL.NO_LABEL' | translate }}
    </button>
    <button
      class="px-btn-paddings"
      cdkFocusInitial
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [attr.aria-label]="'GLOBAL.CONFIRM_LABEL' | translate"
    >
      {{ 'MUSIC_UPLOAD_PAGE.DELETE_SONG_CONFIRMATION' | translate }}
    </button>
  </div>
</div>
