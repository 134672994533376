import {ChangeDetectionStrategy, Component, Inject, NgModule, ViewEncapsulation} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';

@Component({
  templateUrl: './audio-delete-dialog.component.html',
  styleUrls: ['./audio-delete-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioDeleteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) readonly songTitle: string,
    readonly dialogRef: MatDialogRef<AudioDeleteDialogComponent>
  ) {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}

@NgModule({
  declarations: [AudioDeleteDialogComponent],
  imports: [TranslateModule, MatButtonModule, MatDialogModule],
  exports: [AudioDeleteDialogComponent],
})
export class AudioDeleteDialogModule {}
