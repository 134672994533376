export interface IPhotoUploadingWarningsData {
  isLowResolution?: boolean;
  isColorProfileConverted?: boolean;
  isColorProfileMissed?: boolean;
  hasOnlyLowResolutionWarning?: boolean;
  hasOnlyColorProfileWarning?: boolean;
  hasAllWarnings?: boolean;
}

export type PhotoUploadingWarningMessages = {
  [key in keyof IPhotoUploadingWarningsData]: string;
};
