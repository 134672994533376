<ng-container *ngIf="isPlaying; else playBtnTemplateRef">
  <px-button
    [class.c-circle]="isLoading"
    (click)="pause()"
    [puiTooltip]="'Pause' | translate"
    [size]="size"
    type="fabNaked"
    data-qa="stop song button"
  >
    <mds-icon icon="pxPause" />
  </px-button>
</ng-container>

<ng-template #playBtnTemplateRef>
  <px-button
    (click)="play()"
    [puiTooltip]="'Preview song' | translate"
    data-qa="play song button"
    [size]="size"
    type="fabNaked"
  >
    <mds-icon icon="pxPlayArrow" />
  </px-button>
</ng-template>
