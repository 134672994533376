<mds-skeleton isAnimated>
  <div class="top-songs-skeleton">
    <div class="top-songs-skeleton__category" *ngFor="let c of categorySkeletonsArr">
      <div class="top-songs-skeleton__thumbnail">
        <mds-skeleton-bone width="310px" height="310px" />
      </div>
      <div class="top-songs-skeleton__track-list">
        <div class="track-skeleton" *ngFor="let t of trackSkeletonsArr">
          <mds-skeleton-bone
            class="track-skeleton__circle"
            width="30px"
            height="30px"
            borderRadius="rounded"
          />
          <mds-skeleton-bone
            class="track-skeleton__row-up"
            width="117px"
            height="11px"
            borderRadius="rounded"
          />
          <mds-skeleton-bone
            class="track-skeleton__row-down"
            width="158px"
            height="11px"
            borderRadius="rounded"
          />
        </div>
      </div>
    </div>
  </div>
</mds-skeleton>
