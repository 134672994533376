import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'px-audio-empty',
  templateUrl: './audio-empty.component.html',
  styleUrls: ['./audio-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioEmptyComponent {
  @Input() mainText?: string;
  @Input() secondaryText?: string;
  @Input() actionBtnText?: string;
  @Input() withAction?: boolean;
  @Output() actionClick$ = new EventEmitter<void>();

  actionClick(): void {
    this.actionClick$.emit();
  }
}
