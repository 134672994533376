import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MDS_DATATABLE_TYPE} from '../../types/datatable.types';

@Component({
  selector: 'mds-datatable-container',
  templateUrl: './datatable-container.component.html',
  styleUrls: ['./datatable-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MdsDatatableContainerComponent implements OnInit {
  @Input() type: MDS_DATATABLE_TYPE = 'base';

  hostClass: string[] = [];

  private initHostClass(): void {
    switch (this.type) {
      case 'base':
        this.hostClass.push('c-datatable--d');
        break;
      case 'ngx':
        this.hostClass.push('c-datatable--ngx');
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.initHostClass();
  }
}
