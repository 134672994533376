import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import {MdsTabComponent} from './components/tab/tab.component';
import {MdsTabsComponent} from './components/tabs/tabs.component';
import {MdsTabContentDirective} from './directives/tab-content.directive';
import {MdsTabOutletDirective} from './directives/tab-outlet.directive';
import {MdsTabTitleDirective} from './directives/tab-title.directive';

@NgModule({
  imports: [CommonModule, PortalModule, PuiTooltipDirective],
  declarations: [
    MdsTabsComponent,
    MdsTabComponent,
    MdsTabContentDirective,
    MdsTabTitleDirective,
    MdsTabOutletDirective,
  ],
  exports: [MdsTabsComponent, MdsTabComponent, MdsTabContentDirective, MdsTabTitleDirective, MdsTabOutletDirective],
})
export class MdsTabsModule {}
