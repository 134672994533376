import {PxFile} from '@px/shared-data-access-file-upload';
import {IFileHostingUploader, IFileHostingUploadProcess} from '@px/shared/data-access/file-hosting';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  identity,
  map,
  mergeMap,
  Observable,
  of,
  scheduled,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import {AssetBackendCreationError} from '../asset-backend-creation-error';
import {AssetsCreationStage} from '../enums/assets-creation-stage';
import {IAssetBackendCreationProvider} from '../interfaces/asset-backend-creation-provider.interface';
import {AssetBackendCreationResult} from '../interfaces/asset-backend-creation-result.interface';
import {IAssetCreationData} from '../interfaces/asset-creation-data';
import {IAssetCreationProcess} from '../interfaces/asset-creation-process';
import {IAssetsCreationProcess} from '../interfaces/assets-creation-process';

/**
 * AssetCreator class is responsible for creating assets from PxFiles.
 * T - type of asset that will be created.
 * V - type of file hosting parameters.
 * P - type of parameters that can be passed to the asset creation stage
 * U - type of hosting info.
 * UE - type of hosting upload error.
 * СE - type of asset creation error.
 * C - type of creation context. Any data that can be passed through creation process.
 */
export class AssetCreator<T, U, UE, CE, P = null, C = null, V = null> {
  private creationProcessInternal$ = new BehaviorSubject<IAssetsCreationProcess<T, UE, CE, P, C>>(
    this.createIdleAssetsCreationProcess()
  );
  private creationData$ = new Subject<IAssetCreationData<V, P, C>[]>();
  private activeAssetsCreationMap = new Map<PxFile, IAssetCreationProcess<T, V, U, P, C, UE, CE>>();
  private creationDataSub?: Subscription;

  /**
   * An observable that emits the current creation process.
   */
  creationProcess$: Observable<IAssetsCreationProcess<T, UE, CE, P, C>> = this.creationProcessInternal$.pipe(
    map(process => ({
      ...process,
      progress: Math.round((process.progress + Number.EPSILON) * 100) / 100,
    }))
  );

  activeCreationData: IAssetCreationData<V, P, C>[] = [];

  /**
   * the current creation process.
   */
  get creationProcess(): IAssetsCreationProcess<T, UE, CE, P, C> {
    return this.creationProcessInternal$.value;
  }

  /**
   * @param fileHostingUploader - what to use for uploading files to hosting.
   * @param assetBackendCreationService - what to use for creating assets.
   * @param maxQueueSize - maximum number of files that can be processed simultaneously.
   */
  constructor(
    private fileHostingUploader: IFileHostingUploader<U, V, UE>,
    private assetBackendCreationService: IAssetBackendCreationProvider<T, U, P, CE>,
    private readonly maxQueueSize = 5
  ) {
    this.subscribeToPxFiles();
  }

  private emitProcess(newProcess: IAssetsCreationProcess<T, UE, CE, P, C>): void {
    this.creationProcessInternal$.next(newProcess);

    if (this.isProcessFinished(newProcess)) {
      this.scheduleFinishing();
    }
  }

  private clampProgress(progress: number): number {
    return Math.min(Math.max(progress, 0), 1);
  }

  private scheduleFinishing(): void {
    asyncScheduler.schedule(() => {
      this.emitProcess({...this.creationProcess, stage: AssetsCreationStage.FINISHED});
      this.reset();
    });
  }

  private createIdleAssetsCreationProcess(): IAssetsCreationProcess<T, UE, CE, P, C> {
    return {
      stage: AssetsCreationStage.IDLE,
      totalSize: 0,
      uploadedSize: 0,
      failed: [],
      succeed: [],
      progress: 0,
    };
  }

  private createAssetCreationProcess(
    pxFIle: PxFile,
    creationParameters: P,
    creationContext: C,
    fileHostingParams: V
  ): IAssetCreationProcess<T, V, U, P, C, UE, CE> {
    return {
      asset: null,
      assetCreationError: null,
      fileHostingTotalSize: pxFIle.size,
      fileHostingUploadedSize: 0,
      fileHostingUploadingError: null,
      fileHostingUploadingProgress: 0,
      fileHostingInfo: null,
      fileHostingParams,
      cancel$: new Subject<void>(),
      creationParameters,
      creationContext,
      startTime: null,
      endTime: null,
    };
  }

  private subscribeToPxFiles(): void {
    this.creationDataSub = this.creationData$
      .pipe(
        tap(creationData => {
          this.handleAddingFilesToQueue(creationData);
        }),
        concatMap(creationData => scheduled(creationData, asyncScheduler)),
        mergeMap(({pxFile, fileHostingParams}) => {
          const cansel$ = this.activeAssetsCreationMap.get(pxFile)?.cancel$;

          return combineLatest([
            this.fileHostingUploader.upload(pxFile, fileHostingParams).pipe(cansel$ ? takeUntil(cansel$) : identity),
            of(pxFile),
          ]);
        }, this.maxQueueSize),
        tap(([fileHostingUploadProcess, pxFile]) => {
          this.handleFileHostingUploadProgress(pxFile, fileHostingUploadProcess);
        }),
        filter(
          ([hostingUploadProcess, pxFile]) => this.activeAssetsCreationMap.has(pxFile) && !!hostingUploadProcess.success
        ),
        mergeMap(([{hostingInfo}, pxFile]) => {
          const pxFileProcess = this.activeAssetsCreationMap.get(pxFile);

          // Checked  earlier in filter operator, so it will always be defined. If hostingUploadProcess.success than hostingInfo is always present. Just for type safety.
          if (!pxFileProcess || !hostingInfo) {
            throw new Error('File process not found');
          }

          const cansel$ = pxFileProcess.cancel$;
          const creationParameters = pxFileProcess.creationParameters;

          return combineLatest([
            this.assetBackendCreationService
              .create(hostingInfo, creationParameters)
              .pipe(cansel$ ? takeUntil(cansel$) : identity),
            of(pxFile),
          ]);
        }),
        tap(([result, pxFile]) => {
          this.handleAssetBackendCreationResult(pxFile, result);
        })
      )
      .subscribe();
  }

  private handleAddingFilesToQueue(creationData: IAssetCreationData<V, P, C>[]): void {
    const totalProcess = {...this.creationProcess};

    for (const d of creationData) {
      const {pxFile, creationParameters, creationContext, fileHostingParams} = d;
      const fileProcess = this.createAssetCreationProcess(
        pxFile,
        creationParameters,
        creationContext,
        fileHostingParams
      );
      const beforeQueueSize = this.activeAssetsCreationMap.size;
      this.activeAssetsCreationMap.set(pxFile, fileProcess);
      const afterQueueSize = this.activeAssetsCreationMap.size;
      totalProcess.totalSize += fileProcess.fileHostingTotalSize;

      if (totalProcess.progress > 0 && beforeQueueSize > 0) {
        totalProcess.progress = this.clampProgress(totalProcess.progress / (afterQueueSize / beforeQueueSize));
      }
    }

    this.updateActiveCreationData();

    if (totalProcess.stage === AssetsCreationStage.IDLE) {
      totalProcess.stage = AssetsCreationStage.PROCESSING;
    }
    this.emitProcess(totalProcess);
  }

  private handleFileHostingUploadProgress(pxFile: PxFile, process: IFileHostingUploadProcess<U, V, UE>): void {
    const fileProcess = this.activeAssetsCreationMap.get(pxFile);

    if (!fileProcess) {
      return;
    }
    const newFileProcess: IAssetCreationProcess<T, V, U, P, C, UE, CE> = {
      ...fileProcess,
      fileHostingUploadingProgress: process.progress,
      fileHostingTotalSize: process.total,
      fileHostingUploadedSize: process.uploaded,
      fileHostingUploadingError: process.error,
      fileHostingInfo: process.hostingInfo,
      startTime: process.startTime,
      endTime: process.endTime,
    };

    this.activeAssetsCreationMap.set(pxFile, newFileProcess);

    const totalProcess = {...this.creationProcess};

    const sizeDiff = newFileProcess.fileHostingUploadedSize - fileProcess.fileHostingUploadedSize;

    if (sizeDiff) {
      const progress = totalProcess.progress + (sizeDiff / totalProcess.totalSize) * 0.5;
      const uploadedSize = totalProcess.uploadedSize + sizeDiff;
      totalProcess.progress = this.clampProgress(progress);
      totalProcess.uploadedSize = Math.min(uploadedSize, totalProcess.totalSize);
    }

    if (newFileProcess.fileHostingUploadingError) {
      const sizeRemains = newFileProcess.fileHostingTotalSize - newFileProcess.fileHostingUploadedSize;
      const hostingUploadRemainsProgress = (sizeRemains / totalProcess.totalSize) * 0.5;
      const assetCreationRemainsProgress = (1 / this.activeAssetsCreationMap.size) * 0.5;
      const progress = totalProcess.progress + hostingUploadRemainsProgress + assetCreationRemainsProgress;
      totalProcess.progress = this.clampProgress(progress);
      totalProcess.failed = totalProcess.failed.concat({
        pxFile,
        error: newFileProcess.fileHostingUploadingError,
        creationParameters: newFileProcess.creationParameters,
        creationContext: newFileProcess.creationContext,
      });
    }

    this.emitProcess(totalProcess);
  }

  private isProcessFinished(totalProgress: IAssetsCreationProcess<T, UE, CE, P, C>): boolean {
    return (
      totalProgress.failed.length + totalProgress.succeed.length === this.activeAssetsCreationMap.size &&
      totalProgress.stage === AssetsCreationStage.PROCESSING
    );
  }

  private handleAssetBackendCreationResult(pxFile: PxFile, result: AssetBackendCreationResult<T, CE>): void {
    const fileProcess = this.activeAssetsCreationMap.get(pxFile);

    if (!fileProcess) {
      return;
    }

    const totalProcess = {...this.creationProcess};
    const progress = totalProcess.progress + (1 / this.activeAssetsCreationMap.size) * 0.5;
    totalProcess.progress = this.clampProgress(progress);

    if (result instanceof AssetBackendCreationError) {
      totalProcess.failed = totalProcess.failed.concat({
        pxFile,
        error: result,
        creationParameters: fileProcess.creationParameters,
        creationContext: fileProcess.creationContext,
      });
    } else {
      totalProcess.succeed = totalProcess.succeed.concat({
        pxFile,
        asset: result,
        creationParameters: fileProcess.creationParameters,
        creationContext: fileProcess.creationContext,
        startTime: fileProcess.startTime,
        endTime: fileProcess.endTime,
      });
    }

    this.emitProcess(totalProcess);
  }

  private updateActiveCreationData(): void {
    const data: IAssetCreationData<V, P, C>[] = [];

    for (const [pxFile, fileProcess] of this.activeAssetsCreationMap.entries()) {
      data.push({
        pxFile,
        fileHostingParams: fileProcess.fileHostingParams,
        creationParameters: fileProcess.creationParameters,
        creationContext: fileProcess.creationContext,
      });
    }

    this.activeCreationData = data;
  }

  addFilesToCreationQueue(creationData: IAssetCreationData<V, P, C>[]): void {
    this.creationData$.next(creationData);
  }

  discard(pxFiles: PxFile[]): void {
    const totalProcess = this.creationProcess;

    if (this.creationProcess.stage === AssetsCreationStage.IDLE || this.activeAssetsCreationMap.size === 0) {
      return;
    }

    for (const pxFile of pxFiles) {
      const fileProcess = this.activeAssetsCreationMap.get(pxFile);

      if (!fileProcess) {
        continue;
      }

      fileProcess.cancel$.next();
      fileProcess.cancel$.complete();

      const uploadedSize = totalProcess.uploadedSize - fileProcess.fileHostingUploadedSize;
      const progress = totalProcess.progress - (fileProcess.fileHostingUploadedSize / totalProcess.totalSize) * 0.5;
      const totalSize = totalProcess.totalSize - fileProcess.fileHostingTotalSize;

      totalProcess.uploadedSize = Math.max(uploadedSize, 0);
      totalProcess.progress = this.clampProgress(progress);
      totalProcess.totalSize = Math.max(totalSize, 0);
      totalProcess.failed = totalProcess.failed.filter(f => f.pxFile !== pxFile);
      totalProcess.succeed = totalProcess.succeed.filter(s => s.pxFile !== pxFile);

      this.activeAssetsCreationMap.delete(pxFile);
    }

    this.updateActiveCreationData();

    this.emitProcess(totalProcess);
  }

  /**
   * Reset the creation process. Clear state, cancel all active processes.
   */
  reset(): void {
    this.creationDataSub?.unsubscribe();

    //TODO cancel all active processes
    asyncScheduler.schedule(() => this.emitProcess(this.createIdleAssetsCreationProcess()));
    this.activeAssetsCreationMap.clear();
    this.updateActiveCreationData();
    this.subscribeToPxFiles();
  }
}
