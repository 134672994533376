import {inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {IS3Credentials, IS3CredentialsProvider} from '@px/shared/data-access/file-hosting';
import {AudioS3CredentialsGqlDataService} from './audio-s3-credentials-gql-data.service';
import {PxFile} from '@px/shared-data-access-file-upload';

@Injectable()
export class AudioS3CredentialsService implements IS3CredentialsProvider {
  private readonly audioS3CredentialsGQLData = inject(AudioS3CredentialsGqlDataService);

  getCredentials(file: PxFile): Observable<IS3Credentials> {
    return this.audioS3CredentialsGQLData.getCredentials(file.name).pipe(
      map(({awsPublicKey, expiresAt, s3Key, policy, signature, storageClass}) => {
        return {
          awsPublicKey,
          expiresAt,
          s3Key,
          policy,
          signature,
          storageClass,
        };
      })
    );
  }
}
